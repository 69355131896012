import {FC, SetStateAction, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {ColumnInstance, Row, useTable} from 'react-table'
import {KTCardBody, KTSVG} from '../../../../_metronic/helpers'
import {ListLoading} from '../../../../_metronic/helpers/components/loading/ListLoading'
import {ListPagination} from '../../../../_metronic/helpers/components/pagination/ListPagination'
import {ParcelCustomHeaderColumn} from '../../../modules/apps/user-management/users-list/table/columns/CustomHeaderColumn'
import {ParcelCustomRow} from '../../../modules/apps/user-management/users-list/table/columns/CustomRow'
import {parcelReturnColumns as parcelColumns} from '../../../modules/apps/user-management/users-list/table/columns/_columns'
import {
  ParcelQueryResponseData,
  ParcelUseQueryResponseLoading,
  ParcelUseQueryResponsePagination,
} from '../../../modules/wizards/core/ParcelReturnQueryResponseProvider'
import {useQueryRequest} from '../../../modules/wizards/core/QueryRequestProvider'
import {Parcel} from '../../../modules/wizards/core/_models'
import { getCurrentDateInput } from './ParcelTable'


type ChildComponentProps = {
  sendStatus: (data: string) => void;
  sendSearch: (data: string) => void;
  sendStartDate: (data: string) => void;
  sendEndDate: (data: string) => void;
};
interface Statut {
  name:string
  }
interface ArrayObjectSelectState {
  selectedStatut: Statut | null;
}
const status: Statut[] = [{name:'pending'},{name:'processing'},{name:'completed'},{name:'canceled'},{name:'refunded'},];

const TablesBodyParcelReturn : FC<ChildComponentProps> = ({sendStatus, sendSearch, sendStartDate, sendEndDate}) => {

  
  const [searchTerm, setSearchTerm] = useState<string>('')
  const parcels = ParcelQueryResponseData()
  const isLoading = ParcelUseQueryResponseLoading()
  const data = useMemo(() => parcels, [parcels])
  const columns = useMemo(() => parcelColumns, [])
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.getMonth();
  const [startDate, setStartDate] = useState(getCurrentDateInput(new Date(currentYear, currentMonth, 1)));
  const [endDate, setEndDate] = useState(getCurrentDateInput(new Date()));
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const handleStartDateChange = (event: { target: { value: SetStateAction<string> } }) => {
    setStartDate(event.target.value);
  };
  const handleEndDateChange = (event: { target: { value: SetStateAction<string> } }) => {
    setEndDate(event.target.value);
  };


  const [state, setState] = useState<ArrayObjectSelectState>({
    selectedStatut: null,
  });
  const intl = useIntl()

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }
  const handleSendData = () => {
    sendStatus(state.selectedStatut?.name!);
    sendSearch(searchTerm)
    sendStartDate(startDate)
    sendEndDate(endDate)
  };
  

  return (
    <KTCardBody className='py-4'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          className='form-control form-control-solid w-250px ps-14'
          onChange={handleSearchChange}
          value={searchTerm}
        />
        
        <input
        type="date"
        id="startDate"
        className='form-control form-control-solid w-250px ps-14 m-4'
        value={startDate}
        onChange={handleStartDateChange}
      />
      <input
        type="date"
        id="endDate"
        className='form-control form-control-solid w-250px ps-14 m-4'
        value={endDate}
        onChange={handleEndDateChange}
        />
         {/* <Select
        className=' w-250px m-4'
        placeholder="Choisir un statut..."
        value={state.selectedStatut}
        onChange={(option: Statut | null) => {
          setState({ selectedStatut: option });
        }}
        getOptionLabel={(statut: Statut) => statut.name}
        getOptionValue={(statut: Statut) => statut.name}
        options={status}
        isClearable={true}
        backspaceRemovesValue={true}
      /> */}
        <button type="button" className ="btn btn-primary" onClick={handleSendData}>Rechercher</button>
      </div>
      {/* end::Search */}
      <div className='table-responsive'>
        <table
          id='kt_table_parcels'
          className='table table-striped align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Parcel>) => (
                <ParcelCustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className=' fs-5 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows
                .map((row: Row<Parcel>, i) => {
                  prepareRow(row)
                  return <ParcelCustomRow row={row} key={`row-${i}-${row.id}`} />
                })
            ) : (
              <tr>
                <td colSpan={20}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({id: 'TABLE.NO_ITEMS_FOUND'})}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ListPagination
        useQueryResponseLoading={ParcelUseQueryResponseLoading}
        useQueryResponsePagination={ParcelUseQueryResponsePagination}
        useQueryRequest={useQueryRequest}
        item='parcels'
      />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export {TablesBodyParcelReturn, getCurrentDateInput}
