/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {HorizontalAdd} from '../../modules/wizards/components/HorizontalAdd'
import { HorizontalAddAgent } from '../../modules/wizards/components/HorizontalAddAgent'
import {ParcelListViewProvider} from '../../modules/wizards/core/ParcelListViewProvider'

const HorizontalAddWrapperAgent: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'BUTTON.ADD_PARCEL'})}</PageTitle>
      <ParcelListViewProvider>
        <HorizontalAddAgent />
      </ParcelListViewProvider>
    </>
  )
}

export {HorizontalAddWrapperAgent}
