import clsx from 'clsx'
import {FC, PropsWithChildren, useMemo} from 'react'
import {HeaderProps} from 'react-table'
import {initialQueryState} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../core/MovementQueryRequestProvider'
import {DeleveryColumn, MovementColumn, ReceptionColumn} from '../core/_models'

type DeleveryProps = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<DeleveryColumn>>
}

const DeleveryCustomHeader: FC<DeleveryProps> = ({className, title, tableProps}) => {
  const id = tableProps.column.id
  const {state, updateState} = useQueryRequest()

  const isSelectedForSorting = useMemo(() => {
    return state.sort && state.sort === id
  }, [state, id])
  const order: 'asc' | 'desc' | undefined = useMemo(() => state.order, [state])

  const sortColumn = () => {
    if (id === 'actions' || id === 'selection') {
      return
    }

    if (!isSelectedForSorting) {
      updateState({sort: id, order: 'asc', ...initialQueryState})
      return
    }

    if (isSelectedForSorting && order !== undefined) {
      if (order === 'asc') {
        updateState({sort: id, order: 'desc', ...initialQueryState})
        return
      }

      updateState({sort: undefined, order: undefined, ...initialQueryState})
    }
  }

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isSelectedForSorting && order !== undefined && `table-sort-${order}`
      )}
      style={{cursor: 'pointer'}}
      onClick={sortColumn}
    >
      {title}
    </th>
  )
}




type MovementProps = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<MovementColumn>>
}

const MovementCustomHeader: FC<MovementProps> = ({className, title, tableProps}) => {
  const id = tableProps.column.id
  const {state, updateState} = useQueryRequest()

  const isSelectedForSorting = useMemo(() => {
    return state.sort && state.sort === id
  }, [state, id])
  const order: 'asc' | 'desc' | undefined = useMemo(() => state.order, [state])

  const sortColumn = () => {
    if (id === 'actions' || id === 'selection') {
      return
    }

    if (!isSelectedForSorting) {
      updateState({sort: id, order: 'asc', ...initialQueryState})
      return
    }

    if (isSelectedForSorting && order !== undefined) {
      if (order === 'asc') {
        updateState({sort: id, order: 'desc', ...initialQueryState})
        return
      }

      updateState({sort: undefined, order: undefined, ...initialQueryState})
    }
  }

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isSelectedForSorting && order !== undefined && `table-sort-${order}`
      )}
      style={{cursor: 'pointer'}}
      onClick={sortColumn}
    >
      {title}
    </th>
  )
}

type ReceptionProps = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<ReceptionColumn>>
}

const ReceptionCustomHeader: FC<ReceptionProps> = ({className, title, tableProps}) => {
  const id = tableProps.column.id
  const {state, updateState} = useQueryRequest()

  const isSelectedForSorting = useMemo(() => {
    return state.sort && state.sort === id
  }, [state, id])
  const order: 'asc' | 'desc' | undefined = useMemo(() => state.order, [state])

  const sortColumn = () => {
    if (id === 'actions' || id === 'selection') {
      return
    }

    if (!isSelectedForSorting) {
      updateState({sort: id, order: 'asc', ...initialQueryState})
      return
    }

    if (isSelectedForSorting && order !== undefined) {
      if (order === 'asc') {
        updateState({sort: id, order: 'desc', ...initialQueryState})
        return
      }

      updateState({sort: undefined, order: undefined, ...initialQueryState})
    }
  }

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isSelectedForSorting && order !== undefined && `table-sort-${order}`
      )}
      style={{cursor: 'pointer'}}
      onClick={sortColumn}
    >
      {title}
    </th>
  )
}

export {DeleveryCustomHeader, MovementCustomHeader, ReceptionCustomHeader}
