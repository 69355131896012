import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {RelayPoint, RelayPointAllQueryResponse, RelayPointsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const RELAY_POINT_URL = `${API_URL}/relay`

const getRelayPoints = (): Promise<RelayPointAllQueryResponse> => {
  return axios
    .get(RELAY_POINT_URL)
    .then((response: AxiosResponse<RelayPointAllQueryResponse>) => response.data)
}

const getPaginationRelayPoints = (query?: string,  search?:string): Promise<RelayPointsQueryResponse> => {
  return axios
    .get(`${RELAY_POINT_URL}/page?${query}`, { params: {search: search} })
    .then((response: AxiosResponse<RelayPointsQueryResponse>) => response.data)
}


const getRelayPointsAgent = (): Promise<RelayPointAllQueryResponse> => {
  return axios
    .get(`${RELAY_POINT_URL}/src`)
    .then((response: AxiosResponse<RelayPointAllQueryResponse>) => response.data)
}

const getRelayPointDestination = (): Promise<RelayPoint> => {
  return (
    axios
      .get(`${RELAY_POINT_URL}/dest`)
      .then((response: AxiosResponse<RelayPoint>) => response.data)
  )
}


const getPaginationRelayPointDestination = (query?: string,  search?:string): Promise<RelayPointsQueryResponse> => {
  return axios
    .get(`${RELAY_POINT_URL}/dest?${query}`, { params: {search: search} })
    .then((response: AxiosResponse<RelayPointsQueryResponse>) => response.data)
}


const getRelayPointById = (id: ID): Promise<RelayPoint> => {
  return axios
    .get(`${RELAY_POINT_URL}/${id}`)
    .then((response: AxiosResponse<RelayPoint>) => response.data)
}

const createRelayPoint = (relayPoint: RelayPoint): Promise<RelayPoint | undefined | void> => {
  return axios
    .post(RELAY_POINT_URL, relayPoint)
    .then((response: AxiosResponse<Response<RelayPoint>>) => response.data)
    .then((response: Response<RelayPoint>) => response.data)
}

const updateRelayPoint = (relayPoint: RelayPoint): Promise<RelayPoint | undefined | void> => {
  return axios
    .post(`${RELAY_POINT_URL}/${relayPoint.id}/update`, relayPoint)
    .then((response: AxiosResponse<Response<RelayPoint>>) => response.data)
    .then((response: Response<RelayPoint>) => response.data)
}

const deleteRelayPoint = (id: ID): Promise<RelayPoint | undefined | void> => {
  return axios
    .post(`${RELAY_POINT_URL}/${id}/delete`)
    .then((response: AxiosResponse<Response<RelayPoint>>) => response.data)
    .then((response: Response<RelayPoint>) => response.data)
}

const deleteSelectedRelayPoints = (ids: ID[]): Promise<any> => {
  const requests = ids.map((id) => deleteRelayPoint(id))
  return axios.all(requests)
}

export {
  getRelayPoints,
  getRelayPointDestination,
  getPaginationRelayPointDestination,
  getPaginationRelayPoints,
  getRelayPointById,
  createRelayPoint,
  updateRelayPoint,
  deleteRelayPoint,
  deleteSelectedRelayPoints,
  getRelayPointsAgent,
}
