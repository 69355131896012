/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../_metronic/helpers'
import {getParcelsProvider} from './_requests'
import {Parcel} from './_models'
import {useQueryRequest} from './QueryRequestProvider'

const QueryResponseContext= createResponseContext<Parcel>(initialQueryResponse)

const ParcelQueryResponseProvider: FC<WithChildren> = ({children, Status, Search, StartDate, EndDate, Provider}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery) 
    }
    else{
      setQuery(' ')
    }
  }, [updatedQuery, Status, Search, StartDate, EndDate, Provider])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.PARCELS_LIST}-${query}`,
    () => {
      return getParcelsProvider(query, Status, Search, StartDate, EndDate, Provider)
    },
    
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}


const ParcelUseQueryResponse = () => useContext(QueryResponseContext)

const ParcelQueryResponseData = () => {
  const {response} = ParcelUseQueryResponse()
  if (!response) {
    return []
  }
  const parcels = [] as Array<Parcel>
  response &&
    response.data &&
    response.data.forEach((parcel: Parcel) => {
      if (Array.isArray(parcel.movements) && Array.isArray(parcel.receptions))
        {
      parcels.push({
        ...parcel,

        
        first_movement: parcel.movements.length > 0 && parcel.movements[parcel.movements.length - 1].type == 'd' ?
        ' livré ' + parcel.movements[parcel.movements.length - 1].created_at.substring(0,parcel.movements[parcel.movements.length - 1].created_at.lastIndexOf("T")) + ' à ' +  parcel.movements[parcel.movements.length - 1].relay_point_dest.name:
        parcel.movements.length > 0 && parcel.movements[parcel.movements.length - 1].type == 'it'?
        parcel.movements[parcel.movements.length - 1].created_at.substring(0,parcel.movements[parcel.movements.length - 1].created_at.lastIndexOf("T")) + ' de ' +  parcel.movements[parcel.movements.length - 1].relay_point_src.name + ' à ' + parcel.movements[parcel.movements.length - 1].relay_point_dest.name
         : '',
        last_movement: parcel.movements.length > 0 && parcel.movements[0].type == 'd' ?
         ' livré ' + parcel.movements[0].created_at.substring(0,parcel.movements[0].created_at.lastIndexOf("T")) + ' à ' +  parcel.movements[0].relay_point_src.name:
         parcel.movements.length > 0 && parcel.movements[0].type == 'it'?
         parcel.movements[0].created_at.substring(0,parcel.movements[0].created_at.lastIndexOf("T")) + ' de ' +  parcel.movements[0].relay_point_src.name + ' à ' + parcel.movements[0].relay_point_dest.name
          : '',
        first_reception: parcel.receptions.length > 0? parcel.receptions[parcel.receptions.length - 1].created_at.substring(0,parcel.receptions[parcel.receptions.length - 1].created_at.lastIndexOf("T"))  + " à " + parcel.receptions[parcel.receptions.length - 1].relay?.name : '',
        last_reception: parcel.receptions.length > 0? parcel.receptions[0].created_at.substring(0,parcel.receptions[0].created_at.lastIndexOf("T"))  + " à " + parcel.receptions[0].relay?.name : '',
        destination: parcel?.relay+"-  "+parcel.receiver?.firstname+" "+parcel.receiver?.lastname+" , "+parcel.receiver?.adresse,
        departure: parcel.sender?.firstname +" "+parcel.sender?.lastname+" , "+parcel.sender?.adresse,
      })
    }
    
    })

    { 
      parcels.map( parcel => {
        if(Array.isArray(parcel.movements) && parcel.movements.length > 0  ){
    }
      })
  }  
   
  return (parcels as Array<Parcel>) || []
}




const ParcelUseQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }
  const {response} = ParcelUseQueryResponse()
  if (!response) {
    return defaultPaginationState
  }
  const {current_page, links, per_page} = response
  return {current_page, links, per_page} as PaginationState
}

const ParcelUseQueryResponseLoading = (): boolean => {
  const {isLoading} = ParcelUseQueryResponse()
  return isLoading
}


export {

  ParcelQueryResponseProvider,
  ParcelUseQueryResponse,
  ParcelQueryResponseData,
  ParcelUseQueryResponsePagination,
  ParcelUseQueryResponseLoading,
}




