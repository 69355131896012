import { Outlet } from 'react-router-dom'
import { Unauthorized } from '../modules/errors/components/Unauthorized'


type RoleProps = {
    allowedRoles: string[]
    role: string
}

const RequireRole = (props: RoleProps) => {
  return (
    <div>
        {
        props.allowedRoles?.includes(props.role) ?
        <Outlet /> : <Unauthorized/>
        }
    </div>
  )
}

export {RequireRole}
