/* eslint-disable react-hooks/exhaustive-deps */
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {GetTranslate, isNotEmpty, KTCard, KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import {AddressBookQueryResponseProvider} from '../core/QueryResponseSavProvider'
import {AddressBookListViewProvider, AddressBookUseListView} from '../core/ListViewProvider'
import {AddressBookUseQueryResponse} from '../core/QueryResponseProvider'
import { SavAddressBook, SavAddressBookSchema, initialAddressBook, initialSavAddressBook} from '../core/_models'
import { createSavAddressBook, getAddressBookById, updateAddressBook} from '../core/_requests'
import {AxiosError} from 'axios'
import {AlertModal} from '../../../../../_metronic/layout/components/others/AlertModal'
import {AddressBookQueryRequestProvider} from '../core/QueryRequestProvider'
import {AddressBookListHeader} from './AddressBookListHeader'
import { ChildComponentProps } from '../../../../pages/Parcel/ParcelList/ParcelTableProvider'
import { AddressBookSavTable } from './AddressBookSavTable'

type FormProps = {
  isItemLoading: boolean
  item: SavAddressBook
}

const AddressBookForm: FC<FormProps> = ({isItemLoading}) => {
  const {setItemIdForUpdate, setMessageA, setShowA} = AddressBookUseListView()
  const {refetch} = AddressBookUseQueryResponse()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const intl = useIntl()
  const [address] = useState<SavAddressBook>(initialSavAddressBook)


  const formik = useFormik({
    initialValues: initialSavAddressBook,
    validationSchema: SavAddressBookSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
          await createSavAddressBook(values)
          setShowA(true)
          setMessageA(intl.formatMessage({id: 'ADDRESS_BOOK.CREATE_SUCCESS'}))
      } catch (error: any) {
        setShowA(true)
        setMessageA(error.response.data.message)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })


  useEffect(() => {
    formik.setValues(address)
  }, [])


  return (
    <div>
      <form
        id='kt_modal_add_address_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-body scroll-y m-4' id='addressbook'>
          <div className='row mb-1'>
            <div className='col-lg-4'>
              <div className='fv-row mb-0'>
                <label className='d-inline overflow-hidden col-lg-3 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'COLUMN.CATEGORY'})}
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-white'
                  {...formik.getFieldProps('category')}
                />
                <div className='text-danger mt-2'>
                  {formik?.touched?.category && formik.errors.category && GetTranslate(formik.errors.category)}
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='fv-row mb-0'>
                <label className='d-inline overflow-hidden col-lg-6 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'COLUMN.DESCRIPTION'})}
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-white'
                  {...formik.getFieldProps('desc')}
                />
                <div className='text-danger mt-2'>
                  {formik?.touched?.desc &&
                    formik.errors.desc &&
                    GetTranslate(formik.errors.desc)}
                </div>
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='fv-row mb-0'>
                <label className='d-inline overflow-hidden col-lg-6 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'COLUMN.PRICE'})}
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-white'
                  {...formik.getFieldProps('price')}
                />
                <div className='text-danger mt-2'>
                  {formik?.touched?.price &&
                    formik.errors.price &&
                    GetTranslate(formik.errors.price)}
                </div>
              </div>
            </div>
 
          </div>

        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-addresses-modal-action='cancel'
            disabled={formik.isSubmitting || isItemLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CANCEL'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-addresses-modal-action='submit'
            disabled={formik.isSubmitting || isItemLoading || !formik.isValid}
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SUBMIT'})}</span>
            {(formik.isSubmitting || isItemLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

const AddressBookFormWrapper: FC = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = AddressBookUseListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    data: address,
    isLoading,
    error,
  } = useQuery(
    `${QUERIES.ADDRESS_BOOK_LIST}-addresses-${itemIdForUpdate}`,
    () => {
      return getAddressBookById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (error: AxiosError) => {
        setItemIdForUpdate(undefined)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <AddressBookForm item={initialSavAddressBook} isItemLoading={isLoading} />
  }

  if (!isLoading && !error && address) {
    return <AddressBookForm item={address} isItemLoading={isLoading} />
  }

  return null
}

const AddressBookFormHeader: FC = () => {
  const {setItemIdForUpdate} = AddressBookUseListView()
  const intl = useIntl()
  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>{intl.formatMessage({id: 'PRINT.ADDRESS'})}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-addresses-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

const AddressBookModal: FC = () => {
  return (
    <div>
      <div
        className='modal fade show d-block'
        id='kt_modal_show_movement_details'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content'>
            <AddressBookFormHeader />
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <AddressBookFormWrapper />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </div>
  )
}

const AddressesList: FC<ChildComponentProps> = ({sendSearch, Search}) => {
  const {itemIdForUpdate, showA, messageA, setMessageA, setShowA} = AddressBookUseListView()
  return (
    <div>
      <KTCard>
        <AddressBookListHeader savHeader={true}/>
        <AddressBookSavTable
          sendSearch={sendSearch!}/>
      </KTCard>
      {itemIdForUpdate !== undefined && <AddressBookModal />}
      <AlertModal message={messageA} show={showA} setShow={setShowA} setMessage={setMessageA} />
    </div>
  )
}

const AddressBookSavWrapper = () => {
  const [Search, setSearch] = useState("")
  const processSearch = (data: string) => {
    setSearch(data)
  }
  return(
  <AddressBookQueryRequestProvider>
    <AddressBookQueryResponseProvider Search={Search}>
      <AddressBookListViewProvider>
        <AddressesList  sendSearch={processSearch}/>
      </AddressBookListViewProvider>
    </AddressBookQueryResponseProvider>
  </AddressBookQueryRequestProvider>
  )
}

export {AddressBookSavWrapper}
