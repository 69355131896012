import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
// import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { useIntl } from 'react-intl'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const intl = useIntl()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.email, values.password)
        saveAuth(auth)
        const { data } = await getUserByToken(auth.data?.token!)
        setCurrentUser(data.data.user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(intl.formatMessage({ id: 'AUTH.VALIDATION.INVALID_LOGIN' }))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>{intl.formatMessage({ id: "AUTH.LOGIN_TO" })}</h1>
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}</label>
          <input
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}</label>
              {/* end::Label */}
              {/* begin::Link */}
              {/* <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{ marginLeft: '5px' }}
              >
                {intl.formatMessage({ id: "AUTH.GENERAL.FORGOT_BUTTON" })}
              </Link> */}
              {/* end::Link */}
            </div>
          </div>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        <div className='text-danger mb-2 text-center fs-3'>
          {formik.status && <div>{formik.status}</div>}
        </div>

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>
              {intl.formatMessage({ id: "AUTH.LOGIN.BUTTON" })}
            </span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                {intl.formatMessage({ id: "GENERAL.PLEASE_WAIT" })}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}

// import { Link } from 'react-router-dom'
//       <Link
//                 to='/auth/forgot-password'
//                 className='link-primary fs-6 fw-bolder'
//                 style={{ marginLeft: '5px' }}
//               >
//                 {intl.formatMessage({ id: "AUTH.GENERAL.FORGOT_BUTTON" })}
// </Link>