import * as Yup from 'yup'
import {dimensionRegExp, emailRegExp, phoneRegExp} from '../../../../_data'
import {Parcel} from '../core/_models'

const first = Yup.object({
  quantity: Yup.number().required('VALIDATION.QUANTITY.REQUIRED'),
  weight: Yup.number().required('VALIDATION.WEIGHT.REQUIRED'),
  dimension: Yup.string()
    .required('VALIDATION.DIMENSION.REQUIRED')
    .matches(dimensionRegExp, 'VALIDATION.DIMENSION'),
  reference: Yup.string().nullable(),
  price: Yup.number().nullable(),
  content: Yup.string().nullable(),
  pickup_date: Yup.date().min(
    new Date(new Date().setDate(new Date().getDate())),
    'VALIDATION.PICK_UP_DATE'
  ),
  delivery_price: Yup.number()
   // .required('VALIDATION.DELIVERY_PRICE.REQUIRED')
    .min(0, 'VALIDATION.DELIVERY_PRICE'),
})

const second = Yup.object().shape({
  sender: Yup.object({
    firstname: Yup.string().required('VALIDATION.FIRST_NAME.REQUIRED'),
    lastname: Yup.string().required('VALIDATION.LAST_NAME.REQUIRED'),
    governorate: Yup.string().required('VALIDATION.GOVERNORATE.REQUIRED'),
    adresse: Yup.string().required('VALIDATION.ADDRESS.REQUIRED'),
    postal_code: Yup.string().required('VALIDATION.POSTAL_CODE.REQUIRED'),
    city: Yup.string().required('VALIDATION.CITY.REQUIRED'),
    supplement: Yup.string().min(0).max(255, 'VALIDATION.SUPPLEMENT.MAX_255').nullable(),
    phone_number: Yup.string()
      .matches(phoneRegExp, 'VALIDATION.PHONE')
      .min(8, 'VALIDATION.PHONE')
      .nullable(),
    mobile: Yup.string()
      .matches(phoneRegExp, 'VALIDATION.MOBILE')
      .min(8, 'VALIDATION.MOBILE')
      .required('VALIDATION.MOBILE.REQUIRED'),
    email: Yup.string()
      .email('VALIDATION.EMAIL')
      .matches(emailRegExp, 'VALIDATION.EMAIL')
      .min(8, 'VALIDATION.EMAIL')
      .nullable(),
  }),
})

const third = Yup.object().shape({
  receiver: Yup.object({
    firstname: Yup.string().required('VALIDATION.FIRST_NAME.REQUIRED'),
    lastname: Yup.string().required('VALIDATION.LAST_NAME.REQUIRED'),
    governorate: Yup.string().required('VALIDATION.GOVERNORATE.REQUIRED'),
    adresse: Yup.string().required('VALIDATION.ADDRESS.REQUIRED'),
    postal_code: Yup.string().required('VALIDATION.POSTAL_CODE.REQUIRED'),
    city: Yup.string().required('VALIDATION.CITY.REQUIRED'),
    supplement: Yup.string().min(0).max(255, 'VALIDATION.SUPPLEMENT.MAX_255').nullable(),
    phone_number: Yup.string()
      .matches(phoneRegExp, 'VALIDATION.PHONE')
      .min(8, 'VALIDATION.PHONE')
      .nullable(),
    mobile: Yup.string()
      .matches(phoneRegExp, 'VALIDATION.MOBILE')
      .min(8, 'VALIDATION.MOBILE')
      .required('VALIDATION.MOBILE.REQUIRED'),
    email: Yup.string()
      .email('VALIDATION.EMAIL')
      .matches(emailRegExp, 'VALIDATION.EMAIL')
      .min(8, 'VALIDATION.EMAIL')
      .nullable(),
  }),
})

const createParcelSchemas = [first, second, third]

const createParcelSchemaOneStep = Yup.object({
  quantity: Yup.number().required('VALIDATION.QUANTITY.REQUIRED'),
  weight: Yup.number().required('VALIDATION.WEIGHT.REQUIRED'),
    dimension: Yup.string()
  .transform((value, originalValue) => {
    // Remove spaces from the input string
    return typeof originalValue === 'string' ? originalValue.replace(/\s/g, '') : originalValue;
  })
  .required('VALIDATION.DIMENSION.REQUIRED')
  .matches(dimensionRegExp, 'VALIDATION.DIMENSION'),
  reference: Yup.string().nullable(),
  price: Yup.number().nullable(),
  content: Yup.string().nullable(),
  pickup_date: Yup.date().min(
    new Date(new Date().setDate(new Date().getDate())),
    'VALIDATION.PICK_UP_DATE'
  ),
  sender: Yup.object().shape({
    firstname: Yup.string().required('VALIDATION.FIRST_NAME.REQUIRED'),
    lastname: Yup.string().required('VALIDATION.LAST_NAME.REQUIRED'),
    governorate: Yup.string().required('VALIDATION.GOVERNORATE.REQUIRED'),
    adresse: Yup.string().required('VALIDATION.ADDRESS.REQUIRED'),
    postal_code: Yup.string().required('VALIDATION.POSTAL_CODE.REQUIRED'),
    city: Yup.string().required('VALIDATION.CITY.REQUIRED'),
    supplement: Yup.string().min(0).max(255, 'VALIDATION.SUPPLEMENT.MAX_255').nullable(),
    phone_number: Yup.string()
      .matches(phoneRegExp, 'VALIDATION.PHONE')
      .min(8, 'VALIDATION.PHONE')
      .nullable(),
    mobile: Yup.string()
      .matches(phoneRegExp, 'VALIDATION.MOBILE')
      .min(8, 'VALIDATION.MOBILE')
      .required('VALIDATION.MOBILE.REQUIRED'),
    email: Yup.string()
      .email('VALIDATION.EMAIL')
      .matches(emailRegExp, 'VALIDATION.EMAIL')
      .min(8, 'VALIDATION.EMAIL')
      .nullable(),
  }),
  receiver: Yup.object().shape({
    firstname: Yup.string().required('VALIDATION.FIRST_NAME.REQUIRED'),
    lastname: Yup.string().required('VALIDATION.LAST_NAME.REQUIRED'),
    governorate: Yup.string().required('VALIDATION.GOVERNORATE.REQUIRED'),
    adresse: Yup.string().required('VALIDATION.ADDRESS.REQUIRED'),
    postal_code: Yup.string().required('VALIDATION.POSTAL_CODE.REQUIRED'),
    city: Yup.string().required('VALIDATION.CITY.REQUIRED'),
    supplement: Yup.string().min(0).max(255, 'VALIDATION.SUPPLEMENT.MAX_255').nullable(),
    phone_number: Yup.string()
      .matches(phoneRegExp, 'VALIDATION.PHONE')
      .min(8, 'VALIDATION.PHONE')
      .nullable(),
    mobile: Yup.string()
      .matches(phoneRegExp, 'VALIDATION.MOBILE')
      .min(8, 'VALIDATION.MOBILE')
      .required('VALIDATION.MOBILE.REQUIRED'),
    email: Yup.string()
      .email('VALIDATION.EMAIL')
      .matches(emailRegExp, 'VALIDATION.EMAIL')
      .min(8, 'VALIDATION.EMAIL')
      .nullable(),
  }),
})

const inits: Parcel = {
  quantity: 1,
  weight: 1,
  dimension: '',
  reference: '',
  price: 0,
  content: '',
  tracking_number: '',
  pick_up_at: new Date().toISOString().substring(0, 10),
  status: 'pending',
  delivery_price: 0,
  sender: {
    firstname: '',
    lastname: '',
    governorate: '',
    adresse: '',
    postal_code: '',
    city: '',
    supplement: '',
    phone_number: '',
    mobile: '',
    email: '',
  },
  receiver: {
    firstname: '',
    lastname: '',
    governorate: '',
    adresse: '',
    postal_code: '',
    city: '',
    supplement: '',
    phone_number: '',
    mobile: '',
    email: '',
  },
}

export {createParcelSchemas, createParcelSchemaOneStep, inits}
