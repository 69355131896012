import {FC} from 'react'
import {ColumnInstance} from 'react-table'
import { AddressBookColumn } from '../core/_models'

type AdressBookProps = {
    column: ColumnInstance<AddressBookColumn>
}

const AddressBookCustomHeaderColumn: FC<AdressBookProps> = ({column}) => (
    <>
    {column.Header && typeof column.Header === 'string' ? (
        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
        column.render('Header')
    )}
    </>
)

export {AddressBookCustomHeaderColumn}