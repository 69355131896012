/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, SetStateAction, useState} from 'react'
import {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {ColumnInstance, Row, useTable} from 'react-table'
import {SimpleParcelListHeader} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListHeader'
import {ParcelCustomHeaderColumn} from '../../../../app/modules/apps/user-management/users-list/table/columns/CustomHeaderColumn'
import {ParcelCustomRow} from '../../../../app/modules/apps/user-management/users-list/table/columns/CustomRow'
import {simpleParcelColumns} from '../../../../app/modules/apps/user-management/users-list/table/columns/_columns'
import {ParcelListViewProvider} from '../../../../app/modules/wizards/core/ParcelListViewProvider'
import {
  ParcelQueryResponseData,
  ParcelQueryResponseAgent,
  ParcelUseQueryResponseLoading,
  ParcelUseQueryResponsePagination
} from '../../../../app/modules/wizards/core/ParcelQueryResponseAgent'
import Select from 'react-select'
import {
  ParcelQueryRequestProvider,
  useQueryRequest,
} from '../../../../app/modules/wizards/core/QueryRequestProvider'

import {Parcel} from '../../../../app/modules/wizards/core/_models'
import {KTCard, KTCardBody, KTSVG} from '../../../helpers'
import {ListLoading} from '../../../helpers/components/loading/ListLoading'
import {ListPagination} from '../../../helpers/components/pagination/ListPagination'
import { getCurrentDateInput } from '../../../../app/pages/Parcel/ParcelList/ParcelTable'

type Props = {
  className: string
}
type ChildComponentProps = {
  sendStatus: (data: string) => void;
  sendSearch: (data: string) => void;
  sendStartDate: (data: string) => void;
  sendEndDate: (data: string) => void;
};

interface Statut {
  name:string
  }
interface ArrayObjectSelectState {
  selectedStatut: Statut | null;
}
const status: Statut[] = [{name:'pending'},{name:'processing'},{name:'completed'},{name:'canceled'},{name:'refunded'},];

const DashboardTable: FC<ChildComponentProps> = ({sendStatus, sendSearch, sendStartDate, sendEndDate}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const parcels = ParcelQueryResponseData()
  const isLoading = ParcelUseQueryResponseLoading()
  const data = useMemo(() => parcels, [parcels])
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.getMonth();
  const [startDate, setStartDate] = useState(getCurrentDateInput(new Date(currentYear, currentMonth, 1)));
  const [endDate, setEndDate] = useState(getCurrentDateInput(new Date()));
  const columns = useMemo(() => simpleParcelColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const intl = useIntl()

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleStartDateChange = (event: { target: { value: SetStateAction<string> } }) => {
    setStartDate(event.target.value);
  };
  const handleEndDateChange = (event: { target: { value: SetStateAction<string> } }) => {
    setEndDate(event.target.value);
  };
  const [state, setState] = useState<ArrayObjectSelectState>({
    selectedStatut: null,
  });

  const handleSendData = () => {
    sendStatus(state.selectedStatut?.name!);
    sendSearch(searchTerm)
    sendStartDate(startDate)
    sendEndDate(endDate)
  };


  return (
    <KTCardBody className='p-4'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          className='form-control form-control-solid w-250px ps-14'
          onChange={handleSearchChange}
          value={searchTerm}
        />
         <input
        type="date"
        id="startDate"
        className='form-control form-control-solid w-250px ps-14 m-4'
        value={startDate}
        onChange={handleStartDateChange}
      />
      <input
        type="date"
        id="endDate"
        className='form-control form-control-solid w-250px ps-14 m-4'
        value={endDate}
        onChange={handleEndDateChange}
        />
         {/* <Select
        className=' w-250px m-4'
        placeholder="Choisir un statut..."
        value={state.selectedStatut}
        onChange={(option: Statut | null) => {
          setState({ selectedStatut: option });
        }}
        getOptionLabel={(statut: Statut) => statut.name}
        getOptionValue={(statut: Statut) => statut.name}
        options={status}
        isClearable={true}
        backspaceRemovesValue={true}
      /> */}
      <button type="button" className ="btn btn-primary" onClick={handleSendData}>Rechercher</button>
      </div>
      {/* end::Search */}
      <div className='table-responsive p-2'>
        <table
          id='kt_table_parcels'
          className='table table-striped align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Parcel>) => (
                <ParcelCustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className=' fs-5 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows
                .map((row: Row<Parcel>, i) => {
                  prepareRow(row)
                  return <ParcelCustomRow row={row} key={`row-${i}-${row.id}`} />
                })
            ) : (
              <tr>
                <td colSpan={20}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({id: 'TABLE.NO_ITEMS_FOUND'})}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ListPagination
        useQueryResponseLoading={ParcelUseQueryResponseLoading}
        useQueryResponsePagination={ParcelUseQueryResponsePagination}
        useQueryRequest={useQueryRequest}
        item='parcels'
      />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

const TablesWidgetNewParcel: FC<Props> = ({className}) => {
  
  const [Status, setStatus] = useState("")
  const [Search, setSearch] = useState("")
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const [startDate, setStartDate] = useState(getCurrentDateInput(new Date(currentYear, currentMonth, 1)))
  const [endDate, setEndDate] = useState(getCurrentDateInput(new Date()))
  const processReceivedData = (data: string) => {
    setStatus(data)
  }
  const processSearch = (data: string) => {
    setSearch(data)
  }
  const processStartDate = (data: string) => {
    setStartDate(data)
  }
  const processEndDate = (data: string) => {
    setEndDate(data)
  }
  return (
    <div>
      <ParcelQueryRequestProvider>
        <ParcelQueryResponseAgent Status={Status} Search={Search} StartDate={startDate} EndDate={endDate}>
          <ParcelListViewProvider>
            <KTCard>
              <SimpleParcelListHeader />
              <div className='separator separator-solid'></div>
              <DashboardTable sendStatus={processReceivedData} sendSearch={processSearch} sendStartDate={processStartDate} sendEndDate={processEndDate} />
            </KTCard>
          </ParcelListViewProvider>
        </ParcelQueryResponseAgent>
      </ParcelQueryRequestProvider>
    </div>
  )
}

export {TablesWidgetNewParcel}
