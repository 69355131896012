/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {SavAdd} from '../../modules/wizards/components/SavAdd'
import {
  ParcelListViewProvider,
} from '../../modules/wizards/core/ParcelListViewProvider'
import {Parcel} from '../../modules/wizards/core/_models'

type Props = {
  parcel?: Parcel
}

const SavAddWrapper: FC<Props> = ({parcel}) => {
  const intl = useIntl()
  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'BUTTON.ADD_PARCEL'})}</PageTitle>
      
      <ParcelListViewProvider>
        <SavAdd parcel={parcel} />
      </ParcelListViewProvider>
    </>
  )
}

export {SavAddWrapper}
