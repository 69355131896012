/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../_metronic/helpers'
import { getPaginationMovements, getPaginationReceptions, getMovements, getAllReceptions} from './_requests'
import {MovementResponse, Reception, ReceptionColumn, ReceptionResponse} from './_models'
import {useQueryRequest as movementUseQueryRequest} from './MovementQueryRequestProvider'
import {useQueryRequest as receptionUseQueryRequest} from './MovementQueryRequestProvider'


const MovementQueryResponseContext = createResponseContext<MovementResponse>(initialQueryResponse)
const ReceptionQueryResponseContext = createResponseContext<ReceptionResponse>(initialQueryResponse)

const MovementQueryResponseProvider: FC<WithChildren> = ({children, Type, Search, StartDate, EndDate}) => {
  const {state} = movementUseQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)     
    }
    else{
      setQuery(' ')
    }
  }, [updatedQuery, Type, Search, StartDate, EndDate])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.MOVEMENTS_LIST}-${query}`,
    () => {
      return getPaginationMovements(query, Type, Search, StartDate, EndDate)
   
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <MovementQueryResponseContext.Provider
      value={{isLoading: isFetching, refetch, response, query}}
    >
      {children}
    </MovementQueryResponseContext.Provider>
  )
}

const ReceptionQueryResponseProvider: FC<WithChildren> = ({children, Status, Search, StartDate, EndDate}) => {
  const {state} = receptionUseQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)     
    }
    else{
      setQuery(' ')
    }
  }, [updatedQuery, Status, Search, StartDate, EndDate])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.RECEPTIONS_LIST}-${query}`,
    () => {
      return getPaginationReceptions(query, Status, Search, StartDate, EndDate)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
    
  )

  return (
    <ReceptionQueryResponseContext.Provider
      value={{isLoading: isFetching, refetch, response, query}}
    >
      {children}
    </ReceptionQueryResponseContext.Provider>
  )
}

const MovementUseQueryResponse = () => useContext(MovementQueryResponseContext)
const ReceptionUseQueryResponse = () => useContext(ReceptionQueryResponseContext)


const MovementUseQueryResponseData = () => {
  const {response} = MovementUseQueryResponse()
  if (!response) {
    
    return []
  }
  return (response.data as Array<MovementResponse>) || []
}


const ReceptionUseQueryResponseData = () => {

  const {response} = ReceptionUseQueryResponse()
  if (!response) {
    return []
  }
  const data = [] as Array<ReceptionColumn>
 response.data?.map((item: ReceptionResponse) => {
    data.push({
      id: item.id,
      received_at: item.received_at.slice(0, 16).replace('T', ' '),
      parcels_count: item.parcels_count,
      rp_name: item.relay?.name,
      user:item.user?.lastname===null?item.user?.firstname: item.user?.firstname + ' ' + item.user?.lastname,
    })
  })
  return (data || [])
}

const MovementUseQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }
  const {response} = MovementUseQueryResponse()
  if (!response) {
    return defaultPaginationState
  }
  const {current_page, links, per_page} = response

  return {current_page, links, per_page} as PaginationState
}

const ReceptionUseQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }
  const {response} = ReceptionUseQueryResponse()
  if (!response) {
    return defaultPaginationState
  }
  const {current_page, links, per_page} = response

  return {current_page, links, per_page} as PaginationState
}

const MovementUseQueryResponseLoading = () => {
  const {isLoading} = MovementUseQueryResponse()
 
  return isLoading
}

const ReceptionUseQueryResponseLoading = () => {
  const {isLoading} = ReceptionUseQueryResponse()
  return isLoading
}

export {
  MovementQueryResponseProvider,
  MovementUseQueryResponse,
  MovementUseQueryResponseData,
  MovementUseQueryResponsePagination,
  MovementUseQueryResponseLoading,
  ReceptionQueryResponseProvider,
  ReceptionUseQueryResponse,
  ReceptionUseQueryResponseData,
  ReceptionUseQueryResponsePagination,
  ReceptionUseQueryResponseLoading,
}
