/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Parcel} from '../../wizards/core/_models'
import {getReceptionParcels} from '../../wizards/core/_requests'
import Select, {components} from 'react-select'
import {AlertModal} from '../../../../_metronic/layout/components/others/AlertModal'
import {useAuth} from '../../auth'
import {createReception} from './core/_requests'
import {Options, Reception} from './core/_models'
import {RelayPoint} from '../RelayPoints/core/_models'
import {getRelayPointById, getRelayPoints} from '../RelayPoints/core/_requests'
import { useNavigate } from 'react-router'

const ReceptionAdd = () => {
  const [parcels, setParcels] = useState<Parcel[]>([])
  const [realayPoints, setRealayPoints] = useState<RelayPoint[]>([])
  const [relayPoint, setRelayPoint] = useState<RelayPoint>({} as RelayPoint)
  const [options, setOptions] = useState<any>([])
  const [message, setMessage] = useState<string>('')
  const [show, setShow] = useState<boolean>(false)
  const [values, setValues] = useState<Reception>({} as Reception)
  const {currentUser} = useAuth()


  const intl = useIntl()





console.log(relayPoint?.id)
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }: any) => {
    const [isActive, setIsActive] = useState(false)
    const onMouseDown = () => setIsActive(true)
    const onMouseUp = () => setIsActive(false)
    const onMouseLeave = () => setIsActive(false)

    let bg = 'transparent'
    if (isFocused) bg = '#eee'
    if (isActive) bg = '#B2D4FF'

    const style = {
      alignItems: 'center',
      backgroundColor: bg,
      color: 'inherit',
      display: 'flex ',
    }

    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    }
    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type='checkbox' checked={isSelected} />
        {children}
      </components.Option>
    )
  }
  const navigate = useNavigate();
 
  const goTolist = () => {
      navigate('/reception/list')
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (values && values.parcel_id && values.parcel_id.length === 0) {
      setMessage(intl.formatMessage({id: 'MESSAGES.REQUIRED_FIELDS_MISSING'}))
      setShow(true)
      return 
    } else {
      await createReception(currentUser?.type === 'agent'?{...values, rp_id: currentUser?.rp_id}:{...values, rp_id: relayPoint?.id!})
        .then(() => {
          setShow(true)
          setMessage(intl.formatMessage({id: 'MESSAGES.RECEPTION_CONFIRMED'}))
          return goTolist()
        })
        .catch((error) => {
          setShow(true)
          setMessage(error.response.data.message)
        })
    }
  }

  const getAllParcels = async () => {
    const response = await getReceptionParcels()
    response ? setParcels(response as Parcel[]) : setParcels([])
  }

  const getAllRelayPoints = async () => {
    if (currentUser?.type === 'admin') {
      const response = await getRelayPoints()
      response ? setRealayPoints(response as RelayPoint[]) : setRealayPoints([])
    } else {
      const response = await getRelayPointById(currentUser?.rp_id!)
      response && setRelayPoint(response as RelayPoint)
    }
  }

  useEffect(() => {
    getAllParcels()
    getAllRelayPoints()
  }, [])


  useEffect(() => {
    const options: Options = []
    parcels?.map((parcel) =>
      options.push({
        value: parcel.id!,
        label:parcel?.relay===undefined?parcel.tracking_number +' - '+ parcel.sender?.firstname : parcel.tracking_number +' - '+ parcel.sender?.firstname+' - '+ parcel?.relay  ,
      })
    )
    setOptions(options)
  }, [parcels])




  
  return (
    <div className='card mb-5 mb-xl-10'>
      <AlertModal message={message} setMessage={setMessage} show={show} setShow={setShow} />
      <div className='card-header'>
        <h3 className='card-title'>{intl.formatMessage({id: 'MENU.CONFIRM_RECEPTION'})}</h3>
      </div>
      <div className='card-body'>
        <form onSubmit={handleSubmit} className='form'>
          <div className='row m-4'>
            <div className='col-lg-12'>
              <div className='form-group'>
                <label className='required fw-bold fs-6'>
                  {intl.formatMessage({id: 'MOVEMENT.SELECT_PARCEL'})}
                </label>
                <Select
                  className='form-control'
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: 0,
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                    }),
                    multiValueRemove: (provided) => ({
                      ...provided,
                      ':hover': {
                        backgroundColor: '#0D6EFD',
                        color: 'white',
                      },
                    }),
                  }}
                  defaultValue={[]}
                  options={options}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  name='parcel_id'
                  components={{
                    Option: InputOption,
                  }}
                  onChange={(e) => {
                    const parcelIds: number[] = []
                    e?.map((parcel: {value: number}) => parcelIds.push(parcel.value.valueOf()))
                    setValues({...values, parcel_id: parcelIds})
                  }}
                />
              </div>
              {values && (!values.parcel_id || !values.parcel_id.length) && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>
                      {intl.formatMessage({id: 'MESSAGES.REQUIRED_FIELDS_MISSING'})}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row m-4'>
            <div className='col-lg-12'>
              <div className='form-group'>
                <label className='required fw-bold fs-6'>
                  {intl.formatMessage({id: 'MOVEMENT.SELECT_ADDRESS'})}
                </label>
                {currentUser?.type !== 'admin' ? (
                  <input type='text' className='form-control' value={relayPoint.name} disabled />
                ) : (
                  <select
                    className='form-select'
                    name='rp_id'
                    onChange={(e) => setValues({...values, rp_id: +e.target.value})}
                  >
                    {realayPoints?.map((relayPoint) => (
                      <option key={relayPoint.id} value={relayPoint.id}>
                        {relayPoint.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </div>
          {/* div at end of form  */}
          <div className='d-flex justify-content-end m-8'>
            <button type='submit' className='btn btn-primary '>
              {intl.formatMessage({id: 'GENERAL.SUBMIT'})}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ReceptionAdd
