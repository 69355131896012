import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {VerticalAdd} from '../../../../../app/modules/wizards/components/VerticalAdd'
import {useListView} from '../../../../../app/modules/wizards/core/ParcelListViewProvider'
import {Parcel, parcelInits} from '../../../../../app/modules/wizards/core/_models'
import {getParcelById} from '../../../../../app/modules/wizards/core/_requests'
import {isNotEmpty, KTSVG, QUERIES} from '../../../../helpers'
import { useAuth } from '../../../../../app/modules/auth'

const ParcelEditModalHeader = () => {
  const {setItemIdForUpdate} = useListView()
  const intl = useIntl()
  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>{intl.formatMessage({id: 'PARCEL.UPDATE'})}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-parcels-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

type ParcelFormProps = {
  isParcelLoading: boolean
  parcel: Parcel
  isVisible?: boolean
}

const ParcelEditModalForm: FC<ParcelFormProps> = ({parcel, isParcelLoading, isVisible}) => {
  const { currentUser } = useAuth()
  return (
    <>
      <VerticalAdd  parcel={parcel} visible={currentUser?.type==='agent'?false:true} />
    </>
  )
}


const ParcelEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: parcel,
    error,
  } = useQuery(
    `${QUERIES.PARCELS_LIST}-parcel-${itemIdForUpdate}`,
    () => {
      return getParcelById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <ParcelEditModalForm isParcelLoading={isLoading} parcel={parcelInits} />
  }

  if (!isLoading && !error && parcel) {
    return <ParcelEditModalForm isParcelLoading={isLoading} parcel={parcel} />
  }

  return null
}

const EditParcel = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_parcel'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content'>
            <ParcelEditModalHeader />
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <ParcelEditModalFormWrapper />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {EditParcel}
