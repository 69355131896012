/* eslint-disable jsx-a11y/anchor-is-valid */
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {AlertModal} from '../../../_metronic/layout/components/others/AlertModal'
import {PageTitle} from '../../../_metronic/layout/core'
import {TrackingWidget} from './components/TrackingPage'
import {
  PositionRequestDefault,
  PositionRequestSchema,
  PositionRequest,
  PositionResponse,
} from './core/_models'
import {getTracking} from './core/_requests'

const PositionWrapper: FC = () => {
  const [items, setItems] = useState<PositionResponse>({} as PositionResponse)
  const [show, setShow] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const intl = useIntl()
  const formik = useFormik<PositionRequest>({
    initialValues: PositionRequestDefault,
    validationSchema: PositionRequestSchema,
    onSubmit: async (values) => {
      await getTracking(values)
        .then((response) => {
          setItems(response)
        })
        .catch((error) => {
          setShow(true)
          setMessage(error.response.data.error)
        })
    },
  })

  return (
    <>
      <AlertModal message={message} show={show} setShow={setShow} setMessage={setMessage} />
      <PageTitle>{intl.formatMessage({id: 'TITLE.POSITION'})}</PageTitle>
      <div className='rounded border card p-10 py-5 my-10'>
        <form onSubmit={formik.handleSubmit}>
          <div className='row mb-4 '>
            <div className='col-12'>
              <label className='form-label'>
                {intl.formatMessage({id: 'COLUMN.TRACKING_NUMBER'})}
              </label>
              <input
                type='text'
                className='form-control form-control-white'
                {...formik.getFieldProps('trackingNumber')}
              />
              {formik.errors.trackingNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {intl.formatMessage({id: formik.errors.trackingNumber})}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row '>
            <div className='col-8' />
            <div className='col-4'>
              <button
                type='submit'
                className='btn btn-primary w-100'
                disabled={formik.isSubmitting}
              >
                {intl.formatMessage({id: 'GENERAL.SEARCH'})}
              </button>
            </div>
          </div>
        </form>
      </div>

      {items.source && items.destination ? (
        <TrackingWidget className='card-xxl-stretch mb-5 mb-xl-8' items={items} />
      ) : null}
    </>
  )
}

export {PositionWrapper}
