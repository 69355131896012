/* eslint-disable react-hooks/exhaustive-deps */


import { useIntl } from 'react-intl'


import { ParcelsListToolbar} from '../../../modules/apps/user-management/users-list/components/header/UserListToolbar'
import { ParcelsListGrouping } from '../../../modules/apps/user-management/users-list/components/header/UsersListGrouping'

import { WithChildren } from '../../../../_metronic/helpers'
import { FC } from 'react'
import { useListView } from '../../../modules/wizards/core/ParcelListViewProvider'
import { PaymentListToolbar } from '../components/paymentToolbar'




const PaymentListHeader : FC<WithChildren> = () => {
  const { selected } = useListView()

  return (
    <div className='card-header border-0 pt-6'>
      {/* <UsersListSearchComponent /> */}
      <div className='card-title'>{useIntl().formatMessage({ id: "TITLE.PARCEL" })}</div>
      <div className='card-toolbar  '>
        {/* {selected.length > 0 ? <ParcelsListGrouping /> :  */}
        <PaymentListToolbar />
      </div>
    </div>
  )
}



export { PaymentListHeader }
function userListView(): { selected: any } {
  throw new Error('Function not implemented.')
}

