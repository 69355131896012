/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {getAddressBookSavPaginated} from './_requests'
import { AddressBook, AddressBookColumn, SavAddressBook } from './_models'
import {AddressBookUseQueryRequest} from './QueryRequestProvider'

const AddressBookQueryResponseContext = createResponseContext<SavAddressBook>(initialQueryResponse)

const AddressBookQueryResponseProvider: FC<WithChildren> = ({children, Search}) => {
  const {state} = AddressBookUseQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)     
    }
    else{
      setQuery(' ')
    }
  }, [updatedQuery, Search])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.ADDRESS_BOOK_LIST}-${query}`,
    () => {
      return getAddressBookSavPaginated(query, Search)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <AddressBookQueryResponseContext.Provider
      value={{isLoading: isFetching, refetch, response, query}}
    >
      {children}
    </AddressBookQueryResponseContext.Provider>
  )
}

const AddressBookUseQueryResponse = () => useContext(AddressBookQueryResponseContext)

const AddressBookUseQueryResponseData = () => {
  const {response} = AddressBookUseQueryResponse()
  if (!response) {
    return []
  }
  const data = [] as Array<AddressBookColumn>
  response.data?.forEach((item: SavAddressBook) => {
    data.push({
      id: item.id,
      category: item.category,
      desc: item.desc,
      price: item.price
    } as AddressBookColumn)
  })
  return data || []
}

const AddressBookUseQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }
  const {response} = AddressBookUseQueryResponse()
  if (!response) {
    return defaultPaginationState
  }
  const {current_page, links, per_page} = response
  return {current_page, links, per_page} as PaginationState
}

const AddressBookUseQueryResponseLoading = () => {
  const {isLoading} = AddressBookUseQueryResponse()
  return isLoading
}

export {
  AddressBookQueryResponseProvider,
  AddressBookUseQueryResponse,
  AddressBookUseQueryResponseData,
  AddressBookUseQueryResponsePagination,
  AddressBookUseQueryResponseLoading,
}
