/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {ParcelQueryResponseProvider} from '../../../../app/modules/wizards/core/ParcelQueryResponseProvider'
import {ParcelQueryRequestProvider} from '../../../../app/modules/wizards/core/QueryRequestProvider'
import {
  ParcelListViewProvider,
  useListView,
} from '../../../../app/modules/wizards/core/ParcelListViewProvider'
import {EditParcel} from './components/EditParcel'
import {KTCard} from '../../../helpers'
import {TablesBodyParcel, getCurrentDateInput} from '../../../../app/pages/Parcel/ParcelList/ParcelTable'
import {ParcelsListHeader} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListHeader'
import {AlertModal} from '../../../layout/components/others/AlertModal'
import { ChildComponentProps } from '../../../../app/pages/Parcel/ParcelList/ParcelTableProvider'
import { getExcelParcels } from '../../../../app/modules/wizards/core/_requests'

type Props = {
  className: string
}

const TablesWidgetParcel: FC<ChildComponentProps> = ({sendStatus, sendSearch, sendStartDate, sendEndDate, Status, Search, StartDate, EndDate}) => {
  const {itemIdForUpdate, messageA, setMessageA, setShowA, showA} = useListView()
  return (
    <>
      <KTCard>
        <ParcelsListHeader sendEExcel={()=>getExcelParcels(Status, Search, StartDate, EndDate)}  isExport={true} Status={Status} Search={Search} StartDate={StartDate} EndDate={EndDate}/>
        <TablesBodyParcel sendStatus={sendStatus!} sendSearch={sendSearch!} sendStartDate={sendStartDate!} sendEndDate={sendEndDate!}/>
      </KTCard>
      {itemIdForUpdate ? <EditParcel /> : null}
      <AlertModal message={messageA} setMessage={setMessageA} show={showA} setShow={setShowA} />
    </>
  )
}

const ParcelTableListWrapper: FC<Props> = ({className}) => {
  const [Status, setStatus] = useState("")
  const [Search, setSearch] = useState("")
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const [startDate, setStartDate] = useState(getCurrentDateInput(new Date(currentYear, currentMonth, 1)))
  const [endDate, setEndDate] = useState(getCurrentDateInput(new Date()))
  const processReceivedData = (data: string) => {
    setStatus(data)
  }
  const processSearch = (data: string) => {
    setSearch(data)
  }
  const processStartDate = (data: string) => {
    setStartDate(data)
  }
  const processEndDate = (data: string) => {
    setEndDate(data)
  }
  return (
    <div className={className}>
      <ParcelQueryRequestProvider>
        <ParcelQueryResponseProvider Status={Status} Search={Search} StartDate={startDate} EndDate={endDate}>
          <ParcelListViewProvider>
            <TablesWidgetParcel Status={Status} Search={Search} StartDate={startDate} EndDate={endDate}
            sendStatus={processReceivedData} sendSearch={processSearch} sendStartDate={processStartDate} sendEndDate={processEndDate}/>
          </ParcelListViewProvider>
        </ParcelQueryResponseProvider>
      </ParcelQueryRequestProvider>
    </div>
  )
}

export {ParcelTableListWrapper}
