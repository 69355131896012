/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo, useRef} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {getMovementsDelevery }from './_requests'
import { MovemntDetailedResponseDelevery} from './_models'
import {useQueryRequest as movementUseQueryRequest} from './MovementQueryRequestProvider'

const QueryResponseContext = createResponseContext<MovemntDetailedResponseDelevery>(initialQueryResponse)

const MovementQueryResponseProvider: FC<WithChildren> = ({children, Status, Search, StartDate, EndDate}) => {
  const {state} = movementUseQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

 
  
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)     
    }
    else{
      setQuery(' ')
    }
  }, [updatedQuery, Status, Search, StartDate, EndDate])
 
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.PARCELS_LIST}-${query}`,
    () => {   

      return getMovementsDelevery(query,Status, Search, StartDate, EndDate)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  console.log(response)
  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}



const MovementUseQueryResponse = () => useContext(QueryResponseContext)

const MovementUseQueryResponseData = () => {




  const {response} = MovementUseQueryResponse()
  const parcels = [] as Array<MovemntDetailedResponseDelevery>

  return (response?.data as Array<MovemntDetailedResponseDelevery>) || []
}

const ParcelUseQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }
  const {response} = MovementUseQueryResponse()
  if (!response) {
    return defaultPaginationState
  }
  const {current_page, links, per_page} = response
  return {current_page, links, per_page} as PaginationState
}

const ParcelUseQueryResponseLoading = (): boolean => {
  const {isLoading} = MovementUseQueryResponse()
  return isLoading
}

export {
  ParcelUseQueryResponsePagination,
  ParcelUseQueryResponseLoading,
  MovementQueryResponseProvider,
  MovementUseQueryResponse,
  MovementUseQueryResponseData
}
