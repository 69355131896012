import clsx from 'clsx'
import {Row} from 'react-table'
import {FC} from 'react'
import { AddressBookColumn } from '../core/_models'

type AdressBookProps = {
    row: Row<AddressBookColumn>
}

const AddressBookCustomRow: FC<AdressBookProps> = ({row}) => (
    <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
    return (
        <td
            {...cell.getCellProps()}
            className={clsx({
            'text-end min-w-100px': cell.column.id === 'actions' || cell.column.id === 'status',
            })}
            key={cell.column.id}
        >
            {cell.render('Cell')}
        </td>
        )
    })}
    </tr>
)

export {AddressBookCustomRow}