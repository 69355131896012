/* eslint-disable react-hooks/exhaustive-deps */
import { useListView as userListView } from '../../core/ListViewProvider'
import { useListView as parcelListView } from '../../../../../wizards/core/ParcelListViewProvider'
import { ParcelsListToolbar, UsersListToolbar } from './UserListToolbar'
import { ParcelsListGrouping, UsersListGrouping } from './UsersListGrouping'
import { useIntl } from 'react-intl'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { UserTypes } from '../../core/_models'
import {getExcelParcels} from '../../../../../../../app/modules/wizards/core/_requests'
import { FC } from 'react'
import { WithChildren } from '../../../../../../../_metronic/helpers'
import { getExcelMovementsDelevery } from '../../../../mouvement/core/_requests'

const UsersListHeader = () => {
  const { selected } = userListView()
  const { refetch } = useQueryResponse()

  const handleChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    await updateType(event.target.value as UserTypes)
    refetch()
  }
  const { updateType } = useQueryRequest()

  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-title'>{useIntl().formatMessage({ id: "TITLE.USERS" })}</div>
      <div className='card-toolbar'>
        <select className='form-select form-select-sm form-select-solid w-150px mx-3' onChange={handleChange} >
          <option value='provider'>{useIntl().formatMessage({id: "GENERAL.PROVIDER"})}</option>
          <option value='agent'>{useIntl().formatMessage({id: "GENERAL.AGENT"})}</option>
          <option value='driver'>{useIntl().formatMessage({id: "GENERAL.DRIVER"})}</option>
        </select>
        {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
      </div>
      {/* create select in the end of header */}
    </div>
  )
}

const ParcelsListHeader : FC<WithChildren> = ({ Status, Search, StartDate, EndDate}) => {
  const { selected } = parcelListView()

  return (
    <div className='card-header border-0 pt-6'>
      {/* <UsersListSearchComponent /> */}
      <div className='card-title'>{useIntl().formatMessage({ id: "TITLE.PARCEL" })}</div>
      <div className='card-toolbar  '>
      <button className='btn btn-primary mx-4' type='button' onClick={()=>{getExcelParcels(Status, Search, StartDate, EndDate)}} >Export Excel</button>
        {selected.length > 0 ? <ParcelsListGrouping /> : <ParcelsListToolbar />}
        
      </div>
    </div>
  )
}

const ParcelsReturnListHeader : FC<WithChildren> = ({ Status, Search, StartDate, EndDate}) => {
  const { selected } = parcelListView()
  console.log(Status)

  return (
    <div className='card-header border-0 pt-6'>
      {/* <UsersListSearchComponent /> */}
      <div className='card-title'>{useIntl().formatMessage({ id: "TITLE.PARCEL" })}</div>
      {/* <div className='card-toolbar  '>
        {selected.length > 0 ? <ParcelsListGrouping /> : <ParcelsListToolbar />}
        
      </div> */}
    </div>
  )
}

const SimpleParcelListHeader : FC<WithChildren> = ({sendEExcel, isExport, Status, Search, StartDate, EndDate}) => {
  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-title'>
        <h3 className='card-label'>{useIntl().formatMessage({ id: "TITLE.PARCEL" })}</h3>
      </div>
      {isExport &&
        <button className='flex justify-end btn btn-primary mx-4' type='button' onClick={sendEExcel} >Export Excel</button>}
    </div>
  )
}

export { UsersListHeader, ParcelsListHeader, ParcelsReturnListHeader, SimpleParcelListHeader }
