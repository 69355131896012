/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {PaginationState} from '../../crud-helper/models'

type Props = {
  useQueryResponseLoading: () => boolean
  useQueryResponsePagination: () => PaginationState
  useQueryRequest: () => {
    updateState: (state: any) => void
  }
  item: string
}

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const ListPagination: FC<Props> = ({
  useQueryResponseLoading,
  useQueryResponsePagination,
  useQueryRequest,
  item,
}) => {
  const {current_page, per_page, links} = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page: number | undefined) => {
    
    if (!page || isLoading || current_page === page) {
      return
    }
    updateState({page, per_page: per_page || 10})
  }


  return (
    <div className='row m-4'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id={`kt_table_${item}_paginate`}>
          <ul className='pagination'>
            {
            links
              ?.filter((link) => link.label !== '&laquo; Previous' && link.label !== 'Next &raquo;')
              ?.map((link) => {
                return {...link, label: mappedLabel(link.label)}
              })
              .map((link) => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: current_page?.toString() === link.label,
                    disabled: isLoading,
                    previous: link.label === 'Previous',
                    next: link.label === 'Next',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Previous' || link.label === 'Next',
                      'me-5': link.label === 'Previous',
                    })}
                    onClick={() => updatePage(+link.label)}
                    style={{cursor: 'pointer'}}
                  >
                    {mappedLabel(link.label)}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ListPagination}
