import {Column} from 'react-table'
import {UserActionsCell, ParcelActionsCell} from './UserActionsCell'
import {UserSelectionCell, ParcelSelectionCell} from './UserSelectionCell'
import {UserCustomHeader, ParcelCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader, ParcelSelectionHeader} from './UserSelectionHeader'
import {UserResponse} from '../../core/_models'
import {Parcel} from '../../../../../wizards/core/_models'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../../../modules/auth'


const Users=['agent','controle' , 'provider', 'admin']

const usersColumns: ReadonlyArray<Column<UserResponse>> = [
  {
    Header: (props) =>  <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) =>  <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.FIRST_NAME'})}
        className='min-w-125px'
      />
    ),
    accessor: 'firstname',
    id: 'firstname',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.LAST_NAME'})}
        className='min-w-125px'
      />
    ),
    accessor: 'lastname',
    id: 'lastname',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.EMAIL'})}
        className='min-w-125px'
      />
    ),
    accessor: 'email',
    id: 'email',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.BIRTHDATE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'birthdate',
    id: 'birthdate',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.GENDER'})}
        className='min-w-125px'
      />
    ),
    accessor: 'gender',
    id: 'gender',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.MARITAL_STATUS'})}
        className='min-w-125px'
      />
    ),
    accessor: 'marital_status',
    id: 'marital_status',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.HOMEPHONE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'phone_number',
    id: 'phone_number',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.MOBILEPHONE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'mobile',
    id: 'mobile',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.ACCOUNT_TYPE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'modelName',
    id: 'modelName',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.ACTIONS'})}
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

const agentsColumns: ReadonlyArray<Column<UserResponse>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.FIRST_NAME'})}
        className='min-w-125px'
      />
    ),
    accessor: 'firstname',
    id: 'firstname',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.LAST_NAME'})}
        className='min-w-125px'
      />
    ),
    accessor: 'lastname',
    id: 'lastname',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'MENU.RELAY_POINT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'relay',
    id: 'relay',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.EMAIL'})}
        className='min-w-125px'
      />
    ),
    accessor: 'email',
    id: 'email',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.BIRTHDATE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'birthdate',
    id: 'birthdate',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.GENDER'})}
        className='min-w-125px'
      />
    ),
    accessor: 'gender',
    id: 'gender',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.MARITAL_STATUS'})}
        className='min-w-125px'
      />
    ),
    accessor: 'marital_status',
    id: 'marital_status',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.HOMEPHONE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'phone_number',
    id: 'phone_number',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.MOBILEPHONE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'mobile',
    id: 'mobile',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.ACCOUNT_TYPE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'modelName',
    id: 'modelName',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.ACTIONS'})}
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

const simpleParcelColumns: ReadonlyArray<Column<Parcel>> = [
  {
    
    Header: (props) => (

      <ParcelCustomHeader
        tableProps={props}
        title={'source'}
        className='min-w-125px'
      />
    ),
    accessor: 'departure',
    id: 'departure',

  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.RELAY_POINT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'destination',
    id: 'destination',
  },
 /* {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.QUANTITY'})}
        className='min-w-125px'
      />
    ),
    accessor: 'quantity',
    id: 'quantity',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.WEIGHT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'weight',
    id: 'weight',
  },*/
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.DIMENSION'})}
        className='min-w-125px'
      />
    ),
    accessor: 'dimension',
    id: 'dimension',
  },

 /* {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.REFERENCE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'reference',
    id: 'reference',
  },*/
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.PRICE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'price',
    id: 'price',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.CONTENT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'content',
    id: 'content',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.TRACKING_NUMBER'})}
        className='min-w-125px'
      />
    ),
    accessor: 'tracking_number',
    id: 'tracking_number',
  },
 {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.PICK_UP_AT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'pick_up_at',
    id: 'pick_up_at',
  },
 /*  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.DELIVERY_COST'})}
        className='min-w-125px'
      />
    ),
    accessor: 'delivery_price',
    id: 'delivery_price',
  },*/
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.STATUS'})}
        className='text-end min-w-100px'
      />
    ),
    accessor: 'status',
    id: 'status',
  },
]

const parcelColumns: Array<Column<Parcel>> = [
  {
    Header: (props) => Users.slice(1).includes(String(useAuth().currentUser?.type))?<ParcelSelectionHeader tableProps={props} />:<div></div> ,
    id: 'selection',
    Cell: ({...props}) => Users.slice(1).includes(String(useAuth().currentUser?.type))?<ParcelSelectionCell id={props.data[props.row.index].id} />: <div></div>,
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'GENERAL.SOURCE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'departure',
    id: 'departure',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'GENERAL.DESTINATION'})}
        className='min-w-125px'
      />
    ),
    accessor: 'destination',
    id: 'destination',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.REFERENCE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'reference',
    id: 'reference',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.PRICE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'price',
    id: 'price',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.CONTENT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'content',
    id: 'content',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={'mobile client'}
        className='min-w-125px'
      />
    ),
    accessor: 'phone_number',
    id: 'phone_number',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.TRACKING_NUMBER'})}
        className='min-w-125px'
      />
    ),
    accessor: 'tracking_number',
    id: 'tracking_number',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.PICK_UP_AT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'pick_up_at',
    id: 'pick_up_at',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.STATUS'})}
        className='min-w-125px'
      />
    ),
    accessor: 'status',
    id: 'status',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'PARCEL.REASON'})}
        className='min-w-125px'
      />
    ),
    accessor: 'reason',
    id: 'reason',
  },
  {
    Header: (props) =>(
      <ParcelCustomHeader
        tableProps={props}
        title={'premier réception'}
        className='min-w-125px'
      />
    ),
    accessor: 'first_reception',
    id: 'first_reception',
  },
  {
    Header: (props) =>(
      <ParcelCustomHeader
        tableProps={props}
        title={'dernièr réception'}
        className='min-w-125px'
      />
    ),
    accessor: 'last_reception',
    id: 'last_reception',
  },
  {
    Header: (props) =>(
      <ParcelCustomHeader
        tableProps={props}
        title={'premier mouvement'}
        className='min-w-125px'
      />
    ),
    accessor: 'first_movement',
    id: 'first_movement',
  },
  {
    Header: (props) =>(
      <ParcelCustomHeader
        tableProps={props}
        title={'dernièr mouvement'}
        className='min-w-125px'
      />
    ),
    accessor: 'last_movement',
    id: 'last_movement',
  },
  {
    Header: (props) => Users.includes(String(useAuth().currentUser?.type))?
    (
      <ParcelCustomHeader
         tableProps={props}
         title={'actes'}
         className='text-end min-w-100px'
       />
     )
    :<div></div> ,
    id: 'actions',
    Cell: ({...props}) =>Users.includes(String(useAuth().currentUser?.type))?<ParcelActionsCell  IsUpdate={props.data[props.row.index].status==='pending'? true : false} userParcelId={props.data[props.row.index].user_id} id={props.data[props.row.index].id} />: <div></div>,
  },
  
]


const parcelReturnColumns: Array<Column<Parcel>> = [
  {
    Header: (props) => Users.slice(1).includes(String(useAuth().currentUser?.type))?<ParcelSelectionHeader tableProps={props} />:<div></div> ,
    id: 'selection',
    Cell: ({...props}) => Users.slice(1).includes(String(useAuth().currentUser?.type))?<ParcelSelectionCell id={props.data[props.row.index].id} />: <div></div>,
  },
 

  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.TRACKING_NUMBER'})}
        className='min-w-125px'
      />
    ),
    accessor: 'tracking_number',
    id: 'tracking_number',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={'raison'}
        className='min-w-125px'
      />
    ),
    accessor: 'reason',
    id: 'reason',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={'client'}
        className='min-w-125px'
      />
    ),
    accessor: 'client_name',
    id: 'client_name',
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.PICK_UP_AT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'pick_up_at',
    id: 'pick_up_at',
  },

  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={'mis à jour le'}
        className='min-w-125px'
      />
    ),
    accessor: 'updated_at',
    id: 'updated_at',
  },
  {
    Header: (props) => Users.includes(String(useAuth().currentUser?.type))?
    (
      <ParcelCustomHeader
         tableProps={props}
         title={'actes'}
         className='text-end min-w-100px'
       />
     )
    :<div></div> ,
    id: 'actions',
    Cell: ({...props}) =>Users.includes(String(useAuth().currentUser?.type))?<ParcelActionsCell parcelReturn={true} IsUpdate={props.data[props.row.index].status==='pending'? true : false} id={props.data[props.row.index].id} />: <div></div>,
  },
  
]


export {agentsColumns, usersColumns, parcelColumns, simpleParcelColumns, parcelReturnColumns}
