import { useIntl } from 'react-intl';
import { KTSVG } from '../../../../helpers'
import { ItemModel } from '../_models'

type TableProps = {
    header?: string;
    className?: string;
    columns: string[];
    items?: any[];
    headerAddButton?: boolean;
    onCheck: (e: any) => void;
    handleShowEdit: () => void;
    handleShowDelete: () => void;
    EditChild?: JSX.Element;
    DeleteChild?: JSX.Element;
}

export const CheckBoxTable = (props: TableProps) => {
    const intl = useIntl()
    return (
        <div className={`card ${props.className}`}>
            {
                props.header && (
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            {props.header}
                        </h3>
                        {props.headerAddButton && (
                            <div
                                className='card-toolbar'
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                data-bs-trigger='hover'
                                title='Click to add'>
                                <a
                                    href='/'
                                    className='btn btn-sm btn-light-primary'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_invite_friends'
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                                    New
                                </a>
                            </div>
                        )}
                    </div>
                )}
            <div className="card-body">
                <div className='table-responsive'>
                    <table className='table table-striped table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='fw-bold'>
                                <th className='w-25px'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='all'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-9-check'
                                            checked={!props.items?.some((item) => item?.isChecked !== true)}
                                            onChange={props.onCheck}
                                        />
                                    </div>
                                </th>
                                {props.columns.map((column, index) => {
                                    if (index === props.columns.length - 1 && column === "Actions") {
                                        return (
                                            <th className='min-w-100px text-end text-uppercase' key={`${index}+"key"`}>{column}</th>
                                        )
                                    }
                                    else {
                                        return (
                                            <th className='min-w-100px text-uppercase' key={`${index}+"key"`}>{column}</th>
                                        )
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            
                            {props.items?.length ?
                                (props.items.map((item) => (
                                    <tr key={`${item.id}+"checkbox"`}>
                                        <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input
                                                    className='form-check-input widget-9-check'
                                                    type='checkbox'
                                                    name={item.id}
                                                    checked={item?.isChecked}
                                                    onChange={props.onCheck}
                                                />
                                            </div>
                                        </td>

                                        {props.columns.filter((column) => column !== "Actions").map((column) => (
                                            <td key={`${item.id}${column}`}>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <span className='text-muted fw-bold d-block fs-5 '>
                                                        {item[column as keyof ItemModel]}
                                                    </span>

                                                </div>
                                            </td>
                                        ))}

                                        {
                                            props.columns.includes('Actions') &&
                                            (
                                                <td key={`${item.id}+"actions"`}>
                                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                                        <button
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            name='edit'
                                                            onClick={props.handleShowEdit}
                                                        >
                                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                        </button>
                                                        <button
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                            name='delete'
                                                            onClick={props.handleShowDelete}
                                                        >
                                                            <KTSVG
                                                                path='/media/icons/duotune/general/gen027.svg'
                                                                className='svg-icon-3'
                                                            />
                                                        </button>
                                                    </div>
                                                </td>)}
                                    </tr>
                                ))) : (<tr>
                                    <td colSpan={props.columns.length}>
                                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                      {intl.formatMessage({id: "TABLE.NO_ITEMS_FOUND"})}
                                      </div>
                                    </td>
                                  </tr>)}
                        </tbody>
                    </table>
                </div>
                <div className='border-top p-2 d-flex flex-row pt-2'>
                    <button
                        type='button'
                        className='btn btn-primary me-3'
                    >Accept</button>
                    <button
                        type='button'
                        className='btn btn-info'
                        onClick={props.handleShowEdit}
                        disabled={!props.items?.some((item) => item?.isChecked === true)}
                    >Edit</button>
                </div>
            </div>
            {props.EditChild}
            {props.DeleteChild}
        </div>
    )
}