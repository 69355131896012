import {Dispatch, FC, SetStateAction} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'

type Props = {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  setConfirm: (confirm: boolean) => void
}

const ConfirmModal: FC<Props> = ({show, setShow, setConfirm}) => {
  const handleClose = () => setShow(false)
  const handleConfirm = () => {
    setConfirm(true)
    setShow(false)
  }

  const intl = useIntl()
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({id: 'MESSAGE.DELETE_SELECTED'})}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{intl.formatMessage({id: 'MESSAGE.DELETE_ITEM'})}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          {intl.formatMessage({id: 'GENERAL.CANCEL'})}
        </Button>
        <Button variant='primary' onClick={handleConfirm}>
          {intl.formatMessage({id: 'GENERAL.CONFIRM'})}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export {ConfirmModal}
