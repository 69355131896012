import {FC, PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'
import {Parcel} from '../../../../../wizards/core/_models'
import {useListView as userListView} from '../../core/ListViewProvider'
import {useListView as parcelListView} from '../../../../../wizards/core/ParcelListViewProvider'
import {UserResponse} from '../../core/_models'

type UserProps = {
  tableProps: PropsWithChildren<HeaderProps<UserResponse>>
}

type ParcelProps = {
  tableProps: PropsWithChildren<HeaderProps<Parcel>>
}

const UserSelectionHeader: FC<UserProps> = ({tableProps}) => {
  const {isAllSelected, onSelectAll} = userListView()
  return (
    <th {...tableProps.column.getHeaderProps()} className='w-10px pe-2'>
      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
        <input
          className='form-check-input'
          type='checkbox'
          data-kt-check={isAllSelected}
          data-kt-check-target='#kt_table_users .form-check-input'
          checked={isAllSelected}
          onChange={onSelectAll}
        />
      </div>
    </th>
  )
}

const ParcelSelectionHeader: FC<ParcelProps> = ({tableProps}) => {
  const {isAllSelected, onSelectAll} = parcelListView()
  return (
    <th {...tableProps.column.getHeaderProps()} className='w-10px pe-2'>
      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
        <input
          className='form-check-input'
          type='checkbox'
          data-kt-check={isAllSelected}
          data-kt-check-target='#kt_table_parcels .form-check-input'
          checked={isAllSelected}
          onChange={onSelectAll}
        />
      </div>
    </th>
  )
}

export {UserSelectionHeader, ParcelSelectionHeader}
