import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Movement, MovementQueryResponse, MovementQueryResponseDelevery, MovementResponse, MovemntDetailedResponse, MovemntDetailedResponseDelevery, Reception, ReceptionQueryResponse, ReceptionResponse, ReceptionAll} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const MOVEMENT_URL = `${API_URL}/mouv`
const RECEPTION_URL = `${API_URL}/reception`



const getMovementsDelevery = (query?: string, status?:string, search?:string, startDate?: string, endDate?: string): Promise<MovementQueryResponseDelevery> => 
{
  return axios
    .get(`${MOVEMENT_URL}/delevery?${query}`, { params: { status: status, search: search, start_date: startDate, end_date: endDate } })
    .then((response: AxiosResponse<MovementQueryResponseDelevery>) => response.data)
}
const getExcelMovementsDelevery = (status?:string, search?:string, startDate?: string, endDate?: string)=> {
  return axios
    .get(`${MOVEMENT_URL}/delevery/export`, { params: { status: status, search: search, start_date: startDate, end_date: endDate }, responseType: 'blob' })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
  
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'ColisLivrés.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
  
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
  })
}
const getMovementsDeleveryProvider = (query?: string, status?:string, search?:string, startDate?: string, endDate?: string, provider?: number): Promise<MovementQueryResponseDelevery> => 
{
  return axios
    .get(`${MOVEMENT_URL}/deleveryProvider?${query}`, { params: { status: status, search: search, start_date: startDate, end_date: endDate, provider: provider } })
    .then((response: AxiosResponse<MovementQueryResponseDelevery>) => response.data)
}

const getExcelMovementsDeleveryProvider = (status?:string, search?:string, startDate?: string, endDate?: string, provider?: number)=> {
  return axios
    .get(`${MOVEMENT_URL}/deleveryProvider/export`, { params: { status: status, search: search, start_date: startDate, end_date: endDate, provider: provider }, responseType: 'blob' })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
  
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'ColisLivrés.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
  
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
  })
}

const getMovements = (query?: string): Promise<MovementQueryResponse> => {
  return axios
    .get(`${MOVEMENT_URL}`)
    .then((response: AxiosResponse<MovementQueryResponse>) => response.data)
}


const getPaginationMovements = (query?: string, type?:string, search?:string, startDate?: string, endDate?: string): Promise<MovementQueryResponse> => {
  return axios
    .get(`${MOVEMENT_URL}/page?${query}`, { params: { type: type, search: search, start_date: startDate, end_date: endDate } })
    .then((response: AxiosResponse<MovementQueryResponse>) => response.data)
}

const getMovementById = (id: ID): Promise<MovemntDetailedResponse | undefined> => {
   return axios
    .get(`${MOVEMENT_URL}/${id}`)
    .then((response: Response<MovemntDetailedResponse>) => response.data)
}
const getMovementBL = (id: ID): Promise<MovemntDetailedResponse | undefined> => {
  console.log(id);
  return axios
   .get(`${MOVEMENT_URL}/${id}/detail`)
   .then((response: Response<MovemntDetailedResponse>) => response.data)
}

const createMovement = (movement: Movement): Promise<Movement | undefined | void> => {
  return axios
    .post(MOVEMENT_URL, movement)
    .then((response: AxiosResponse<Response<Movement>>) => response.data)
    .then((response: Response<Movement>) => response.data)
}

const updateMovement = (movement: Movement): Promise<Movement | undefined | void> => {
  return axios
    .post(`${MOVEMENT_URL}/${movement.id}/update`, movement)
    .then((response: AxiosResponse<Response<Movement>>) => response.data)
    .then((response: Response<Movement>) => response.data)
}

const deleteMovement = (id: ID): Promise<Movement | undefined | void> => {
  return axios
    .post(`${MOVEMENT_URL}/${id}/delete`)
    .then((response: AxiosResponse<Response<Movement>>) => response.data)
    .then((response: Response<Movement>) => response.data)
}

const deleteSelectedMovements = (ids: ID[]): Promise<any> => {
  const requests = ids.map((id) => deleteMovement(id))
  return axios.all(requests)
}

const getAllReceptions = (query?: string): Promise<ReceptionResponse[]> => {
  return axios
    .get(RECEPTION_URL)
    .then((response: AxiosResponse<ReceptionResponse[]>) => response.data)
}

const getPaginationReceptions = (query?: string, status?:string, search?:string, startDate?: string, endDate?: string): Promise<ReceptionQueryResponse> => {
  return axios
    .get(`${RECEPTION_URL}/page?${query}`, { params: { status: status, search: search, start_date: startDate, end_date: endDate } })
    .then((response: AxiosResponse<ReceptionQueryResponse>) => response.data)
}

const getReceptionById = (id: ID): Promise<Reception | undefined> => {
  return axios.get(`${RECEPTION_URL}/${id}`).then((response: Response<Reception>) => response.data)
}

const createReception = (reception: Reception): Promise<Reception | undefined | void> => {
  return axios
    .post(RECEPTION_URL, reception)
    .then((response: AxiosResponse<Response<Reception>>) => response.data)
    .then((response: Response<Reception>) => response.data)
}

const deleteReception = (id: ID): Promise<Reception | undefined | void> => {
  return axios
    .post(`${RECEPTION_URL}/${id}/delete`)
    .then((response: AxiosResponse<Response<Reception>>) => response.data)
    .then((response: Response<Reception>) => response.data)
}

const deleteSelectedReceptions = (ids: ID[]): Promise<any> => {
  const requests = ids.map((id) => deleteReception(id))
  return axios.all(requests)
}


export {
  getMovementsDelevery,
  getExcelMovementsDelevery,
  getMovements,
  getPaginationMovements,
  getMovementById,
  createMovement,
  updateMovement,
  deleteMovement,
  deleteSelectedMovements,
  getAllReceptions,
  getPaginationReceptions,
  getReceptionById,
  createReception,
  deleteReception,
  deleteSelectedReceptions,
  getMovementBL,
  getMovementsDeleveryProvider, 
  getExcelMovementsDeleveryProvider
}
