
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useAuth } from '../../../modules/auth'
import { KTSVG } from '../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
// import {UsersListFilter} from './UsersListFilter'

function UsersListToolbar() {
  const { setItemIdForUpdate } = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  const intl = useIntl()
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <UsersListFilter /> */}
      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        {intl.formatMessage({ id: "BUTTON.ADD_USER" })}
      </button>
      {/* end::Add user */}
    </div>
  )
}

const PaymentListToolbar = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const goToSimpleAdd = () => {
 
    navigate('/Versement/Add')
    
  }

  const intl = useIntl()
  return (
    <div className='d-flex justify-content-end' data-kt-pricing-table-toolbar='base'>
      {/* <UsersListFilter /> */}
      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={goToSimpleAdd}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        {intl.formatMessage({id : "BUTTON.ADD_PARCEL"})}
      </button>
      {/* end::Add user */}
    </div>
  )
}

export {UsersListToolbar, PaymentListToolbar}
