import { Modal } from 'react-bootstrap';

type DeleteProps = {
    isShown?: boolean;
    handleClose?: () => void;
    handleDelete?: (id:string) => void;
}



export const DeleteModal = (props: DeleteProps) => {
    return (
        <Modal show={props.isShown} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete this?</p>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-danger ' >
                    DELETE
                </button>
                <button className='btn btn-primary' onClick={props.handleClose}>
                    CANCEL
                </button>
            </Modal.Footer>
        </Modal>

    )

}