import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, UserAllQueryResponse, UsersQueryResponse, UserTypes} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/user`
const PROVIDER_URL = `${API_URL}/provider`
const AGENT_URL = `${API_URL}/agent`
const DRIVER_URL = `${API_URL}/driver`

const getDrivers = (query?: string): Promise<UserAllQueryResponse> => {
  return axios
    .get(`${DRIVER_URL}?${query}`)
    .then((response: AxiosResponse<UserAllQueryResponse>) => response.data)
}

const getPaginationDrivers = (query?: string): Promise<UserAllQueryResponse> => {
  return axios
    .get(`${DRIVER_URL}?${query}`)
    .then((response: AxiosResponse<UserAllQueryResponse>) => response.data)
}

const getProviders = (query?: string): Promise<UserAllQueryResponse> => {
  return axios
    .get(`${PROVIDER_URL}?${query}`)
    .then((response: AxiosResponse<UserAllQueryResponse>) => response.data)
}

const getUsers = (query?: string, search?: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${USER_URL}?${query}`, { params: {search: search} })
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data)
}

const getPaginationUsers = (type: UserTypes, query?: string): Promise<UsersQueryResponse> => {
  const url =
    type === 'provider'
      ? `${PROVIDER_URL}/page`
      : type === 'agent'
      ? AGENT_URL
      : `${DRIVER_URL}/page`
  return axios
    .get(`${url}?${query}`)
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data)
}

const getProvider = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${PROVIDER_URL}?${query}`)
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data)
}

const getAgent = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${AGENT_URL}?${query}`)
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data)
}

const getDriver = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${DRIVER_URL}?${query}`)
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data)
}

const getPaginationDriver = (query?: string,  search?:string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${DRIVER_URL}/page?${query}`, { params: {search: search} })
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data)
}

const getUserById = (id: ID, type: UserTypes): Promise<User | undefined> => {
  const url = type === 'provider' ? PROVIDER_URL : type === 'agent' ? AGENT_URL : DRIVER_URL
  return axios.get(`${url}/${id}`).then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined | void> => {
  const url =
    user.modelName === 'provider'
      ? PROVIDER_URL
      : user.modelName === 'agent'
      ? AGENT_URL
      : DRIVER_URL
  return axios
    .post(url, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User, type: UserTypes): Promise<User | undefined | void> => {
  const url = type === 'provider' ? PROVIDER_URL : type === 'agent' ? AGENT_URL : DRIVER_URL
  return axios
    .post(`${url}/${user.id}/update`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID, type: UserTypes): Promise<void> => {
  const url = type === 'provider' ? PROVIDER_URL : type === 'agent' ? AGENT_URL : DRIVER_URL
  return axios.post(`${url}/${userId}/delete`)
}

const deleteSelectedUsers = (userIds: Array<ID>, type: UserTypes): Promise<any> => {
  const url = type === 'provider' ? PROVIDER_URL : type === 'agent' ? AGENT_URL : DRIVER_URL
  const requests = userIds.map((id) => axios.post(`${url}/${id}/delete`))
  return axios.all(requests)
}

export {
  getDrivers,
  getProviders,
  getUsers,
  getPaginationUsers,
  getAgent,
  getDriver,
  getPaginationDriver,
  getProvider,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
}
