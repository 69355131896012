import { Modal } from "react-bootstrap";

type EditPackageProps = {
    isShown?: boolean;
    handleClose?: () => void;
}

export const EditPackage = (props: EditPackageProps) => {
    return (
        <Modal show={props.isShown} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='form-group fv-plugins-icon-container'>
                    <label>truck type</label>
                    <select
                        className='form-select form-select-solid'
                        name='truckType'
                    >
                        <option value=''>Select truck type</option>
                        <option value='1'>Truck type 1</option>
                        <option value='2'>Truck type 2</option>
                        <option value='3'>Truck type 3</option>
                    </select>
                    <label>Matricul</label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        name='matricul'
                        placeholder='Matricul'
                    />
                    <label>driver</label>
                    <input
                        type='text'
                        className='form-control form-control-solid'
                        name='driver'
                        placeholder='driver'
                    />
                    <label>Destination</label>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Logiteam</span>
                        <select className="form-select" >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className='btn btn-danger'
                    onClick={props.handleClose}
                ><i className="bi bi-x"></i>Cancel</button>
                <button
                    className='btn btn-primary'
                ><i className="bi bi-check"></i> Confirm</button>
            </Modal.Footer>
        </Modal>
    )
}