import {useIntl} from 'react-intl'
import {KTSVG, WithChildren} from '../../../../../_metronic/helpers'
import { AddressBookUseListView } from '../core/ListViewProvider'
import { FC } from 'react'

const AddressBookListToolbar : FC<WithChildren> = ({savHeader}) => {
    const intl = useIntl()
    const {setItemIdForUpdate} = AddressBookUseListView()
    const openAddUserModal = () => {
        setItemIdForUpdate(null)
      }
    return (
        <div className='d-flex justify-content-end' data-kt-relay-table-toolbar='base'>
        {/* <UsersListFilter /> */}
        {savHeader?
      <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            
          Ajouter une tarification SAV
        </button>
        :
        <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        
        {intl.formatMessage({id: 'BUTTON.ADD_ADDRESS'})}
    </button>
}
        </div>
    )
}

export {AddressBookListToolbar}