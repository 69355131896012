import * as YUP from 'yup'
import {numberRegExp} from '../../../../_data'

export type transfer = {
  rp_src_id: number
  received_at: string
  relay_point_src: {
    id: number
    governorate: string
    adresse: string
    name: string
    mobile:string
  }

}


export type PositionResponse = {
  source: {adresse: string,  firstname: string  , lastname: string, mobile: string}
  destination: {adresse: string,  firstname: string , lastname: string,  mobile: string, date?: string}
  transfers: [{
    type?: string
    governorate?: string
    date?: string
    source?: string
    destination?: string
    src_mobile?: string
    mobile?: string
    dest_mobile?: string
  }]
  nb_jrs?: string
}

export type PositionQueryResponse = Array<PositionResponse>

export type PositionRequest = {
  trackingNumber: string
}

export const PositionRequestDefault: PositionRequest = {
  trackingNumber: '',
}

export const PositionRequestSchema = YUP.object().shape({
  trackingNumber: YUP.string()
    .min(10, 'VALIDATION.TRACKING_NUMBER')
    .matches(numberRegExp, 'VALIDATION.TRACKING_NUMBER')
    .required('VALIDATION.TRACKING_NUMBER_REQUIRED'),
})
