import { Column } from 'react-table'
import { useIntl } from 'react-intl'
import {  payment } from '../core/_models'
import {useAuth} from '../../../modules/auth'
import { PaymentSelectionHeader } from './PaymentSelectionHeader'
import { PaymentSelectionCell } from './PaymentSelectionCell'
import { PaymentCustomHeader } from './CustomHeaderColumn'
import { PaymentActionsCell } from './PaymentActionsCell'



const paymentColumns: ReadonlyArray<Column<payment>> = [
    {
        Header: (props) => <PaymentSelectionHeader tableProps={props} />,
        id: 'selection',
        Cell: ({...props}) => <PaymentSelectionCell id={props.data[props.row.index].id} />,
    },
    {
        Header: (props) => (
            <PaymentCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "PAYMENT.PARCELS_NUMBER" })} className='min-w-125px' />
        ),
        accessor: 'parcels_count',
        id: 'parcels_count',
    },
    {
        Header: (props) => (
            <PaymentCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "PAYMENT.AGENT" })} className='min-w-125px' />
        ),
        accessor: 'agent',
        id: 'agent',
    },
    {
        Header: (props) => (
            <PaymentCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "PAYMENT.TOTAL" })} className='min-w-125px' />
        ),
        accessor: 'total',
        id: 'total',
    },
    {
        Header: (props) => (
            <PaymentCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "PAYMENT.CREATED_AT" })} className='min-w-125px' />
        ),
        accessor: 'created_at',
        id: 'created_at',
    },
    {
        Header: (props) => (
            <PaymentCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "PAYMENT.UPDATED_AT" })} className='min-w-125px' />
        ),
        accessor: 'updated_at',
        id: 'updated_at',
    },
    {
        Header: (props) =>
        (
            <PaymentCustomHeader tableProps={props} title={'actes'} className='text-end min-w-100px ' />
        )
        ,
        id: 'actions',
        Cell: ({...props}) =><PaymentActionsCell id={props.data[props.row.index].id} />
    },
]

export { paymentColumns }