/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { CheckBoxTable } from './components/CheckboxTableC'
import { claimingColumns, claimingItems } from '../../../../_data'
import { ItemModel } from './_models'
import { EditPackage } from './components/EditPackageC'
import { DeleteModal } from './components/DeleteModal'

type Props = {
  className: string
}

const TablesWidgetParam: React.FC<Props> = ({ className }) => {
  const [items, setItems] = useState([] as ItemModel[]);
  const [show, setShow] = useState({ edit: false, delete: false });

  const handleShow = (v: any) => {
    if (v === 'edit') {
      setShow({ edit: true, delete: false });
    } else if (v === 'delete') {
      setShow({ edit: false, delete: true });
    }
  }

  const handleClose = () => {
    setShow({ edit: false, delete: false });
  }

  useEffect(() => {
    setItems(claimingItems);
  }, []);

  const handleCheck = (e: any) => {
    const { name, checked } = e.target;
    if (name === "all") {
      let newItems = items.map((item) => { return { ...item, isChecked: checked }; }
      );
      setItems(newItems);
    } else {
      let newItems = items.map((item) =>
        item.ID === name ? { ...item, isChecked: checked } : item
      );
      setItems(newItems);
    }
  };

  return (
    <div>
      <CheckBoxTable
        header='Claiming'
        className={className}
        columns={claimingColumns}
        items={items}
        onCheck={handleCheck}
        handleShowEdit={() => handleShow("edit")}
        handleShowDelete={() => handleShow("delete")}
        EditChild={<EditPackage isShown={show.edit} handleClose={handleClose} />}
        DeleteChild={<DeleteModal isShown={show.delete} handleClose={handleClose}/>}
      />
    </div>
  )
}

export { TablesWidgetParam }
