import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {ReclamationWrapper} from '../pages/reclamation/ReclamationWrapper'
import {ParcelListWrapper} from '../pages/Parcel/ParcelListWrapper'
import {ParcelReturnAddWrapper} from '../pages/Parcel/ParcelReturnAddWrapper'
import {ParcelReturnWrapper} from '../pages/Parcel/ParcelReturnWrapper'
import {VerticalAddWrapper} from '../pages/Parcel/VerticalAddWrapper'
import {TruckListWrapper} from '../pages/Truck/TruckListWrapper'
import {TruckAddWrapper} from '../pages/Truck/TruckAddWrapper'
import {HorizontalAddWrapper} from '../pages/Parcel/HorizontalAddWrapper'
import {PositionWrapper} from '../pages/Position/PositionWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {ParamPageWrapper} from '../pages/Configuation/ParamPageWrapper'
import {RequireRole} from './RequireRole'
import MovementAdd from '../modules/apps/mouvement/MovementAdd'
import ReceptionAdd from '../modules/apps/mouvement/ReceptionAdd'
import { HorizontalAddWrapperAgent } from '../pages/Parcel/HorizontalAddWrapperAgent'
import { VerticalAddWrapperAgent } from '../pages/Parcel/VerticalAddWrapperAgent'
import { CaisseWrapper ,} from '../pages/dashboard/CaisseWrapper'
import{CaisseProviderWrapper} from '../pages/dashboard/CaisseProviderWrapper'
import {ProviderParcelListWrapper} from '../pages/Parcel/ProviderParcelListWrapper'
import { SavAddWrapper } from '../pages/Parcel/SavAddWrapper'
import { AddressBookSavWrapper } from '../modules/apps/adressBook/list/AddressBookSavWrapper'
import { PaymentListWrapper } from '../pages/Payment/payment-list/PaymentWrapper'
import { PaymentAddWrapper } from '../pages/Payment/payment-list/PaymentAddWrapper'



type privateRoutesProps = {
  model: string
}

const PrivateRoutes = (props: privateRoutesProps) => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const PricingsPage = lazy(() => import('../modules/apps/pricing/PricingsPage'))
  const RelayPointsPage = lazy(() => import('../modules/apps/RelayPoints/RelayPointsPage'))
  const MovementsPage = lazy(() => import('../modules/apps/mouvement/MovementsPage'))
  const ReceptionsPage = lazy(() => import('../modules/apps/mouvement/ReceptionsPage'))
  const AddressBookPage = lazy(() => import('../modules/apps/adressBook/AddressBookPage'))


  return (
    <Routes>
      <Route element={<MasterLayout role={props.model} />}>

      <Route element={<RequireRole allowedRoles={['admin','agent','controle','provider']} role={props.model} />}>
          <Route path='/delevery' element={<CaisseWrapper/>} />
        </Route>

        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route
          element={<RequireRole allowedRoles={['admin', 'provider', 'agent','controle']} role={props.model} />}
        >
          <Route path='dashboard' element={<DashboardWrapper />} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin']} role={props.model} />}>
          <Route path='reclamation' element={<ReclamationWrapper />} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin','controle']} role={props.model} />}>
          <Route path='/Parcel/Provider' element={<ProviderParcelListWrapper/>} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin','controle']} role={props.model} />}>
          <Route path='/Parcel/delevery' element={<CaisseProviderWrapper/>} />
        </Route>
        <Route
          element={<RequireRole allowedRoles={['admin', 'provider', 'agent','controle']} role={props.model} />}
        >
          <Route path='position' element={<PositionWrapper />} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin']} role={props.model} />}>
          <Route path='configuration' element={<ParamPageWrapper />} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin']} role={props.model} />}>
          <Route path='menu-test' element={<MenuTestPage />} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin','agent','controle']} role={props.model} />}>
          <Route path='Versement/List' element={<PaymentListWrapper/>} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin','agent','controle']} role={props.model} />}>
          <Route path='Versement/Add' element={<PaymentAddWrapper/>} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin', 'provider','agent','controle']} role={props.model} />}>
          <Route path='Parcel/List' element={<ParcelListWrapper/>} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin','controle']} role={props.model} />}>
          <Route path='Parcel/Return/Add' element={<ParcelReturnAddWrapper/>} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin', 'provider','agent','controle']} role={props.model} />}>
          <Route path='Parcel/Return/List' element={<ParcelReturnWrapper/>} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin', 'provider']} role={props.model} />}>
          <Route path='Parcel/Simple_Add' element={<VerticalAddWrapper />} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin', 'provider']} role={props.model} />}>
          <Route path='Parcel/Normal_Add' element={<HorizontalAddWrapper />} />
        </Route>


        {/* SAV tarif List */}
        <Route element={<RequireRole allowedRoles={['admin', 'agent', 'conrole']} role={props.model} />}>
          <Route path='SAV/pircing/management' element={<AddressBookSavWrapper />} />
        </Route>

        <Route element={<RequireRole allowedRoles={['admin', 'agent', 'controle']} role={props.model} />}>
          <Route path='Parcel/SAV_Add' element={<SavAddWrapper />} />
        </Route>
        {/* Add Parcel Agent SODIG **************/}

        <Route element={<RequireRole allowedRoles={['agent','controle']} role={props.model} />}>
          <Route path='Parcel/Simple_Add_Agent' element={<VerticalAddWrapperAgent />} />
        </Route>
        <Route element={<RequireRole allowedRoles={['agent','controle']} role={props.model} />}>
          <Route path='Parcel/Normal_Add_Agent' element={<HorizontalAddWrapperAgent />} />
        </Route>


        <Route element={<RequireRole allowedRoles={['admin', 'agent','controle']} role={props.model} />}>
          <Route path='Truck/List' element={<TruckListWrapper />} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin', 'agent','controle']} role={props.model} />}>
          <Route path='/Truck/Add' element={<TruckAddWrapper />} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin', 'agent','controle']} role={props.model} />}>
          <Route path='/movement/Add' element={<MovementAdd />} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin', 'agent','controle']} role={props.model} />}>
          <Route path='/reception/add' element={<ReceptionAdd />} />
        </Route>
        {/* Lazy Modules */}
        <Route element={<RequireRole allowedRoles={['admin', 'agent','controle']} role={props.model} />}>
          <Route
            path='/reception/*'
            element={
              <Suspense fallback={<TopBarProgress />}>
                <ReceptionsPage />
              </Suspense>
            }
          />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin', 'agent','controle']} role={props.model} />}>
          <Route
            path='/movement/*'
            element={
              <SuspensedView>
                <MovementsPage />
              </SuspensedView>
            }
          />
        </Route>

        <Route element={<RequireRole allowedRoles={['admin', 'provider','controle']} role={props.model} />}>
          <Route
            path='app/addressbook/*'
            element={
              <SuspensedView>
                <AddressBookPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route
          element={<RequireRole allowedRoles={['admin', 'agent', 'provider','controle']} role={props.model} />}
        >
          <Route
            path='app/pricing/*'
            element={
              <Suspense fallback={<TopBarProgress />}>
                <PricingsPage />
              </Suspense>
            }
          />
        </Route>
        <Route
          element={<RequireRole allowedRoles={['admin', 'agent', 'provider','controle']} role={props.model} />}
        >
          <Route
            path='app/relaypoints/*'
            element={
              <SuspensedView>
                <RelayPointsPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route
          element={<RequireRole allowedRoles={['admin', 'agent', 'provider','controle']} role={props.model} />}
        >
          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin']} role={props.model} />}>
          <Route
            path='crafted/pages/wizards/*'
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin']} role={props.model} />}>
          <Route
            path='crafted/widgets/*'
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route
          element={<RequireRole allowedRoles={['admin', 'agent', 'provider','controle']} role={props.model} />}
        >
          <Route
            path='crafted/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route
          element={<RequireRole allowedRoles={['admin', 'agent', 'provider']} role={props.model} />}
        >
          <Route
            path='apps/chat/*'
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin']} role={props.model} />}>
          <Route
            path='apps/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
        </Route>

        {/* Error Pages */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
