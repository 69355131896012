/* eslint-disable jsx-a11y/anchor-is-valid */
import {AxiosError} from 'axios'
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {isNotEmpty, KTSVG, QUERIES} from '../../../_metronic/helpers'
import {AlertModal} from '../../../_metronic/layout/components/others/AlertModal'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse} from './core/QueryResponseProvider'
import {addTruckSchema, initialTruck, Truck} from './core/_models'
import {createTruck, getTruckById, updateTruck} from './core/_requests'

type FormProps = {
  isItemLoading: boolean
  item: Truck
  modal?: boolean
}

const TruckForm: FC<FormProps> = ({isItemLoading, item, modal}) => {
  const {setItemIdForUpdate, setMessageA, setShowA} = useListView()
  const {refetch} = useQueryResponse()

  const [Pricing] = useState<Truck>(item)

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: Pricing,
    validationSchema: addTruckSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateTruck(values)
          setMessageA(intl.formatMessage({id: 'TRUCK.UPDATE_SUCCESS'}))
          setShowA(true)
        } else {
          await createTruck(values)
          setMessageA(intl.formatMessage({id: 'TRUCK.CREATE_SUCCESS'}))
          setShowA(true)
          cancel(true)
        }
      } catch (err: any) {
        setMessageA(err.response.data.message)
        setShowA(true)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const intl = useIntl()
  return (
    <>
      <form id='kt_add_truck_form' className='form' onSubmit={formik.handleSubmit}>
        <div className='scroll-y mx-5 mx-xl-15 my-7'>
          <div className='row g-0'>
            <div className='col m-2'>
              <label className='required fs-6 fw-bold mb-2'>
                {intl.formatMessage({id: 'TRUCK.TYPE'})}
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                {...formik.getFieldProps('type_truck')}
                name='type_truck'
              />
              {formik.touched.type_truck && formik.errors.type_truck ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.type_truck}</div>
                </div>
              ) : null}
            </div>
            <div className='col m-2'>
              <label className='required fs-6 fw-bold mb-2'>
                {intl.formatMessage({id: 'TRUCK.CODE'})}
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                {...formik.getFieldProps('truck_code')}
                name='truck_code'
              />
              {formik.touched.truck_code && formik.errors.truck_code ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.truck_code}</div>
                </div>
              ) : null}
            </div>
            <div className='col m-2'>
              <label className='required fs-6 fw-bold mb-2'>
                {intl.formatMessage({id: 'TRUCK.NUMBER'})}
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                {...formik.getFieldProps('truck_number')}
                name='truck_number'
              />
              {formik.touched.truck_number && formik.errors.truck_number ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.truck_number}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className='text-end'>
          <button type='submit' className='btn btn-primary mt-3'>
            {isItemLoading ? (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'GENERAL.PROCESSING'})}
              </span>
            ) : (
              <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SUBMIT'})}</span>
            )}
            <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0' />
          </button>
          {modal && (
            <button
              type='button'
              className='btn btn-light btn-active-light-primary ms-2 mt-3 me-2'
              data-kt-pricings-modal-action='close'
              onClick={() => cancel()}
            >
              {intl.formatMessage({id: 'GENERAL.CANCEL'})}
            </button>
          )}
        </div>
      </form>
    </>
  )
}

const TruckAddPage: FC = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    data: pricing,
    isLoading,
    error,
  } = useQuery(
    `${QUERIES.TRUCKS_LIST}-truck-${itemIdForUpdate}`,
    () => {
      return getTruckById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (error: AxiosError) => {
        setItemIdForUpdate(undefined)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <TruckForm item={initialTruck} isItemLoading={isLoading} />
  }

  if (!isLoading && !error && pricing) {
    return <TruckForm item={pricing} isItemLoading={isLoading} modal={true} />
  }

  return null
}

const TruckFormHeader: FC = () => {
  const intl = useIntl()

  const TruckBreadcrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'MENU.TRUCKS_MANAGEMENT'}),
      path: '/truck/list',
      isActive: true,
      isSeparator: false,
    },
    {
      title: '',
      path: '',
      isActive: true,
      isSeparator: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={TruckBreadcrumbs}>{intl.formatMessage({id: 'TRUCK.ADD'})}</PageTitle>

      <h2 className='p-4'>{intl.formatMessage({id: 'TRUCK.ADD'})}</h2>
      <div className='separator separator-dashed '></div>
    </>
  )
}

const TruckAddComplete: FC = () => {
  const {messageA, setMessageA, showA, setShowA} = useListView()

  return (
    <>
      <AlertModal message={messageA} setMessage={setMessageA} show={showA} setShow={setShowA} />
      <div className='modal-dialog modal-dialog-centered modal-xl'>
        <div className='modal-content'>
          <TruckFormHeader />
          <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
            <TruckAddPage />
          </div>
        </div>
      </div>
    </>
  )
}

const TruckAddWrapper: FC = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <TruckAddComplete />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export {TruckAddWrapper, TruckAddPage}
