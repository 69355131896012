/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, Component, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {deleteParcel} from '../../../../../wizards/core/_requests'
import {useListView as userListView} from '../../core/ListViewProvider'
import {useListView as parcelListView} from '../../../../../wizards/core/ParcelListViewProvider'
import {useQueryResponse as userQueryResponse} from '../../core/QueryResponseProvider'
import {ParcelUseQueryResponse as parcelQueryResponse} from '../../../../../wizards/core/ParcelQueryResponseProvider'
import {deleteUser} from '../../core/_requests'
import {useIntl} from 'react-intl'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {ConfirmModal} from '../../../../../../../_metronic/layout/components/others/ConfirmModal'
import { useAuth } from '../../../../../auth'
import {getParcelById} from '../../../../../../modules/wizards/core/_requests'
import { Parcel } from '../../../../../wizards/core/_models'
import {PrintModal} from '../../../../../../../_metronic/layout/components/others/PrintParcelModal'
import { getRelayPointById } from '../../../../RelayPoints/core/_requests'
import { RelayPoint } from '../../../../RelayPoints/core/_models'

type Props = {
  id: ID
  IsUpdate?: boolean
  userParcelId?: number
  parcelReturn?: boolean
}

const UserActionsCell: FC<Props> = ({id}) => {
  const [show, setShow] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const {setMessageA, setShowA, setItemIdForUpdate} = userListView()
  const {query} = userQueryResponse()
  const {type} = useQueryRequest()
  const queryClient = useQueryClient()

  const intl = useIntl()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteUserItem = useMutation(() => deleteUser(id, type), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      setMessageA(intl.formatMessage({id: 'USER.DELETE_SUCCESS'}))
      setShowA(true)
    },
    onError: (err: any) => {
      console.log(err)
      setMessageA(err.response.data.message)
      setShowA(true)
    },
  })
  const openConfirmModal = () => {
    setShow(true)
  }

  const confirmDelete = async () => {
    if (confirm) {
      await deleteUserItem.mutateAsync()
    }
  }

  useEffect(() => {
    confirmDelete()
    return () => {
      setShow(false)
      setConfirm(false)
    }
  }, [confirm])


  return (
    <>
      <ConfirmModal show={show} setShow={setShow} setConfirm={setConfirm} />
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'MENU.ACTIONS'})}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            {intl.formatMessage({id: 'ACTIONS.EDIT'})}
          </a>
        </div>
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={openConfirmModal}
          >
            {intl.formatMessage({id: 'ACTIONS.DELETE'})}
          </a>
        </div>
      </div>
    </>
  )
}

const ParcelActionsCell: FC<Props> = ({id, IsUpdate= true, userParcelId, parcelReturn}) => {
  const [show, setShow] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [parcel, setParcel] = useState<Parcel>({} as Parcel)
  const [parcelreturn, setParcelReturn] = useState<Parcel>({} as Parcel)
  const [showPrintModal, setShowPrintModal] = useState(false)
  const {setItemIdForUpdate} = parcelListView()
  const {query} = parcelQueryResponse()
  const queryClient = useQueryClient()
  const [selectedRPData, setSelectedRPData] = useState<RelayPoint | undefined>(undefined)

  console.log(IsUpdate)


  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteParcelItem = useMutation(() => deleteParcel(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.PARCELS_LIST}-${query}`])
    },
    onError: () => {},
  })


  const openParcelDetailModal = async () => {
    setShowPrintModal(true)
    const response = await getParcelById(id)
    const responseSrc = await getRelayPointById(1)


    if (response) {
      const updatedParcelReturn = {
        ...response,
        sender: {
          ...(response.sender || {}),
          id: responseSrc?.id || '',
          firstname: "Société logiteam" || '', 
          lastname:  '',
          governorate: responseSrc?.governorate || '',
          adresse: " Avenue taieb hachicha route de sousse m'saken" || '',
          postal_code: responseSrc?.postal_code || '',
          city: responseSrc?.city || '',
          supplement: responseSrc?.supplement || '',
          phone_number: responseSrc?.phone_number || '',
          mobile: responseSrc?.mobile || '',
          email: responseSrc?.email || '',
          email_verified_at: responseSrc?.email || '',
        },
        receiver:response.sender
      };
      setParcel(response as Parcel)
      setParcelReturn(updatedParcelReturn as Parcel);
    } else {
      setParcel({} as Parcel)
      setParcelReturn({} as Parcel) 
    }
  }
  

  const openConfirmModal = () => {
    setShow(true)
  }

  const confirmDelete = async () => {
    if (confirm) {
      await deleteParcelItem.mutateAsync()
    }
  }

  useEffect(() => {
    confirmDelete()
    return () => {
      setShow(false)
      setConfirm(false)
    }
  }, [confirm])

  const intl = useIntl()

  const { currentUser } = useAuth()
  console.log(currentUser?.id)

    return (
      <>
        <ConfirmModal show={show} setShow={setShow} setConfirm={setConfirm} />

        <PrintModal
          show={showPrintModal}
          setShow={setShowPrintModal}
          Parcel={parcelReturn?parcelreturn:parcel}
          />
        <a
          href='#'
          className='btn btn-light btn-active-light-primary btn-sm me-2'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          {intl.formatMessage({ id: 'MENU.ACTIONS' })}
          <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
        </a>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-parcels-table-filter='delete_row'
              onClick={openParcelDetailModal}>
              {'Details'}
            </a>
          </div>
          {!IsUpdate || currentUser?.id!==userParcelId?
          <div className='menu-item px-6'>
            <div className='text-start' title={'Colis non modifiable'}>
              {intl.formatMessage({ id: 'ACTIONS.EDIT' })}
            </div>
          </div>
          :<div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            {intl.formatMessage({ id: 'ACTIONS.EDIT' })}
          </a>
        </div>}
          {currentUser?.type!=='agent' &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-parcels-table-filter='delete_row'
              onClick={openConfirmModal}
            >
              {intl.formatMessage({ id: 'ACTIONS.DELETE' })}
            </a>
          </div>
         }
        </div>
      </>
    );
  
}

export {UserActionsCell, ParcelActionsCell}
