/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {ParcelQueryResponseProvider} from '../../../modules/wizards/core/ParcelQueryResponseProvider'
import {ParcelQueryRequestProvider} from '../../../modules/wizards/core/QueryRequestProvider'
import {
  ParcelListViewProvider,
  useListView,
} from '../../../modules/wizards/core/ParcelListViewProvider'


import {getCurrentDateInput} from '../../Parcel/ParcelList/ParcelTable'

import { ChildComponentProps } from '../../Parcel/ParcelList/ParcelTableProvider'
import { getExcelParcels } from '../../../modules/wizards/core/_requests'
import { AlertModal } from '../../../../_metronic/layout/components/others/AlertModal'
import { KTCard } from '../../../../_metronic/helpers'
import { EditParcel } from '../../../../_metronic/partials/widgets/tables/components/EditParcel'
import { PaymentListHeader } from './PaymentListHeader'
import { TablesBodyPayment } from './PaymentTable'
import { PaymentQueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider } from '../core/QueryResponseProvider'
import { PaymentListViewProvider } from '../core/ListViewProvider'


type Props = {
  className: string
}

const TablesWidgetPayment: FC<ChildComponentProps> = ({sendStatus, sendSearch, sendStartDate, sendEndDate, Status, Search, StartDate, EndDate}) => {
  const {itemIdForUpdate, messageA, setMessageA, setShowA, showA} = useListView()
  return (
    <>
      <KTCard>
        <PaymentListHeader />
        <TablesBodyPayment sendStatus={sendStatus!} sendSearch={sendSearch!} sendStartDate={sendStartDate!} sendEndDate={sendEndDate!}/>
      </KTCard>
      {itemIdForUpdate ? <EditParcel /> : null}
      <AlertModal message={messageA} setMessage={setMessageA} show={showA} setShow={setShowA} />
    </>
  )
}

const PaymentTableListWrapper: FC<Props> = ({className}) => {
  const [Status, setStatus] = useState("")
  const [Search, setSearch] = useState("")
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const [startDate, setStartDate] = useState(getCurrentDateInput(new Date(currentYear, currentMonth, 1)))
  const [endDate, setEndDate] = useState(getCurrentDateInput(new Date()))
  const processReceivedData = (data: string) => {
    setStatus(data)
  }
  const processSearch = (data: string) => {
    setSearch(data)
  }
  const processStartDate = (data: string) => {
    setStartDate(data)
  }
  const processEndDate = (data: string) => {
    setEndDate(data)
  }
  return (
    <div className={className}>
      <PaymentQueryRequestProvider>
        <QueryResponseProvider Status={Status} Search={Search} StartDate={startDate} EndDate={endDate}>
          <PaymentListViewProvider>
            <TablesWidgetPayment Status={Status} Search={Search} StartDate={startDate} EndDate={endDate}
            sendStatus={processReceivedData} sendSearch={processSearch} sendStartDate={processStartDate} sendEndDate={processEndDate}/>
          </PaymentListViewProvider>
        </QueryResponseProvider>
      </PaymentQueryRequestProvider>
    </div>
  )
}

export {PaymentTableListWrapper}
