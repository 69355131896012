/* eslint-disable react-hooks/exhaustive-deps */
import {Field} from 'formik'
import {FC, useState} from 'react'
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {dimensionRegExp} from '../../../../../_data'
import {GetTranslate} from '../../../../../_metronic/helpers'
import {getDeliveryPrice, getProducts} from '../../core/_requests'
import {useAuth} from '../../../auth'
import Select from 'react-select'
import {Options} from '../../../apps/mouvement/core/_models'
import { NULL } from 'sass'
import { product } from '../../core/_models'

type Props = {
  quantity?: number
  weight?: number
  dimension?: string
  reference?: string
  price?: number
  pick_up_at?: string
  content?: string
  loading?: boolean
  formik?: any
  visible?: boolean
  IsSelect?: boolean
}
interface Option {
  value: number
  label: string
 price: number
}



const ParcelDetails: FC<Props> = ({
  quantity,
  weight,
  dimension,
  reference,
  price,
  pick_up_at,
  content,
  loading,
  formik,
  visible= true,
  IsSelect
}) => {
  const thisWeight = formik?.values?.weight
  const thisDimension = formik?.values?.dimension
  const thisQuantity = formik?.values?.quantity

  const {currentUser} = useAuth()

  const [selectedOptions, setSelectedOptions] = useState<any[]>([]) 
  const [products, setProducts] = useState<product[]>([] as product[])
  const [searchRef, setSearchRef] = useState<string>('')

  useEffect(() => {
    const getproducts = async () => {     
        const response = await getProducts(undefined, searchRef)
        response?
          setProducts(response.data as product[]):
          setProducts([])     
    }

    getproducts()
  }, [searchRef])

  const selectedoptions = selectedOptions.map(option => option.label).join(' + ');

  useEffect(() => {
    if (thisWeight && thisDimension && dimensionRegExp.test(thisDimension) && thisQuantity) {
      const dimensions = {
        weight: thisWeight,
        dimension: thisDimension,
        quantity: thisQuantity,
        provider_id: currentUser?.id,
      }
      
      getDeliveryPrice(dimensions).then((res) => {
        formik.setFieldValue('delivery_price', res.price)
      })
    }
    else if(deleveryPrice){
     
      formik.setFieldValue('content', selectedoptions);
      formik.setFieldValue('delivery_price', deleveryPrice);
      formik.setFieldValue('dimension', '1*1*1 ');
      formik.setFieldValue('reference', selectedOptions.map(option => option.reference).join(','));
    }
  }, [thisWeight, thisDimension, thisQuantity, selectedOptions])

  useEffect(() => {
    if ((pick_up_at && pick_up_at < new Date().toISOString().substring(0, 10)) || !pick_up_at) {
      formik.setFieldValue('pick_up_at', new Date().toISOString().substring(0, 10))
    }
  }, [])
  const [deleveryPrice, setdeleveryPrice] = useState<number>()


  const handleSelectChange = (selected: any) => {
    setSelectedOptions(selected)
      const selectedPrices = selected.map((option: { price: number }) => option.price) 

      const highestPrice = Math.max(...selectedPrices) 

        setdeleveryPrice(highestPrice) 


  }
  


  
  const intl = useIntl()
  return (
    <div className='w-100 card' id='parcelDetail'>
      {visible && 
      <><div className='card-header'>
          <h3 className='card-title'>{intl.formatMessage({ id: 'PARCEL.DETAILS' })}</h3>
        </div><div className='card-body form-floating'>

            <div className='row mb-1'>
              <div className='col-lg-3'>
                <div className='fv-row mb-0'>
                  <label
                    htmlFor='quantity'
                    className='d-inline overflow-hidden text-nowrap col-lg-20 col-form-label required fw-bold fs-6'
                  >
                    {intl.formatMessage({ id: 'COLUMN.QUANTITY' })}
                  </label>
                  <Field
                    type='number'
                    className='form-control form-control-lg form-control-white'
                    name='quantity'
                    defaultValue={quantity}
                    id='quantity'
                    disabled={loading}
                    onChange={formik.handleChange} />
                  <div className='text-danger mt-2'>
                    {formik?.touched?.quantity &&
                      formik?.errors?.quantity &&
                      GetTranslate(formik?.errors?.quantity)}
                  </div>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='fv-row mb-0'>
                  <label
                    htmlFor='weight'
                    className='d-inline overflow-hidden text-nowrap col-lg-8 col-form-label required fw-bold fs-6 '
                  >
                    {intl.formatMessage({ id: 'COLUMN.WEIGHT' })}(kg)
                  </label>
                  <Field
                    type='number'
                    className='form-control form-control-lg form-control-white'
                    name='weight'
                    defaultValue={weight}
                    id='weight'
                    disabled={loading}
                    onChange={formik.handleChange} />
                  <div className='text-danger mt-2'>
                    {formik?.touched?.weight &&
                      formik?.errors?.weight &&
                      GetTranslate(formik?.errors?.weight)}
                  </div>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='fv-row mb-0'>
                  <label
                    htmlFor='dimension'
                    className='d-inline overflow-hidden text-nowrap col-lg-6 col-form-label fw-bold fs-6 required'
                  >
                    {intl.formatMessage({ id: 'COLUMN.DIMENSION' })}(cm)
                  </label>
                  
                  <Field
                    type='text'
                    className='form-control form-control-lg form-control-white'
                    name='dimension'
                    placeholder='L*L*H'
                    onChange={formik.handleChange}
                    id='dimension'
                    disabled={loading} />
                  <div className='text-danger mt-2'>
                    {formik?.touched?.dimension &&
                      formik?.errors?.dimension &&
                      GetTranslate(formik?.errors?.dimension)}
                  </div>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='fv-row mb-0'>
                  <label
                    htmlFor='reference'
                    className='d-inline overflow-hidden text-nowrap col-lg-6 col-form-label fw-bold fs-6'
                  >
                    {intl.formatMessage({ id: 'COLUMN.REFERENCE' })}
                  </label>
                  {IsSelect ?
           <Select
           className='form-control form-control-solid'
           styles={{
             control: (provided) => ({
               ...provided,
               border: 0,
               boxShadow: 'none',
               backgroundColor: 'transparent',
             }),
           }}
           options={products.map((item) => ({ value: item.id, label: item.ref + ' - '+ item.cat_logiteam+',  '+item.des, price:parseInt(item.price), reference: item.ref }))}
           isMulti
           hideSelectedOptions={false}
           name='reference'
           id='reference'
           value={selectedOptions}
           onInputChange={(ChangedValue) => {
            setSearchRef(ChangedValue)
           
            }}
           onChange={handleSelectChange}
         />:
                  <Field
                    type='string'
                    className='form-control form-control-lg form-control-white'
                    name='reference'
                    defaultValue={reference}
                    id='reference'
                    onChange={formik.handleChange}/>
        }
                  <div className='text-danger mt-2'>
                    {formik?.touched?.reference &&
                      formik?.errors?.reference &&
                      GetTranslate(formik?.errors?.reference)}
                  </div>
                </div>
              </div>
            </div><div className='row mb-1'>
              <div className='col-lg-3'>
                <div className='fv-row mb-0'>
                  <label
                    htmlFor='price'
                    className='d-inline  overflow-hidden text-nowrap col-lg-8 col-form-label  fw-bold fs-6'
                  >
                    {intl.formatMessage({ id: 'COLUMN.PRICE' })}(DT)
                  </label>
                  <Field
                    type='number'
                    className='form-control form-control-lg form-control-white'
                    name='price'
                    defaultValue={price}
                    id='price'
                    onChange={formik.handleChange} />
                  <div className='text-danger mt-2'>
                    {formik?.touched?.price &&
                      formik?.errors?.price &&
                      GetTranslate(formik?.errors?.price)}
                  </div>
                </div>
              </div>
              <div className='col-lg-5'>
                <div className='fv-row mb-0'>
                  <label className='d-inline overflow-hidden col-lg-6 col-form-label text-nowrap fw-bold fs-6 required'>
                    {intl.formatMessage({ id: 'COLUMN.PICK_UP_AT' })}
                  </label>
                  <Field
                    type='Date'
                    className='form-control form-control-lg form-control-white'
                    name='pick_up_at'
                    min={new Date().toISOString().substring(0, 10)}
                    id='pick_up_at'
                    defaultValue={pick_up_at}
                    onChange={formik.handleChange}/>
                  <div className='text-danger mt-2'>
                    {formik?.touched?.pick_up_at &&
                      formik?.errors?.pick_up_at &&
                      GetTranslate(formik?.errors?.pick_up_at)}
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='fv-row mb-0'>
                  <label className='d-inline text-nowrap col-lg-6 col-form-label fw-bold fs-6 required'>
                    {intl.formatMessage({ id: 'COLUMN.DELIVERY_PRICE' })}(DT)
                  </label>
                  <Field
                    type='number'
                    className='form-control form-control-lg form-control-white'
                    name='delivery_price'
                    onChange={formik.handleChange}
                    id='delivery_price'
                    disabled />
                  <div className='text-danger mt-2'>
                    {formik?.touched?.delivery_price &&
                      formik?.errors?.delivery_price &&
                      GetTranslate(formik?.errors?.delivery_price)}
                  </div>
                </div>
              </div>
            </div>
          </div></>
       }
      <div className='col-lg-12'>
          <div className='fv-row mb-0'>
            <label className='d-inline overflow-hidden col-lg-6 col-form-label  fw-bold fs-6 '>
              {intl.formatMessage({id: 'COLUMN.CONTENT'})}
            </label>
            <Field
              type='text'
              className='form-control form-control-lg form-control-white'
              name='content'
              as='textarea'
              onChange={formik.handleChange}
              defaultValue={content}
              disabled={false}
            />
            <div className='text-danger mt-2'>
              {formik?.touched?.content &&
                formik?.errors?.content &&
                GetTranslate(formik?.errors?.content)}
            </div>
          </div>
        </div>
    </div>
  )
}

export {ParcelDetails}
