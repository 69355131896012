/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import {deleteMovement, deleteReception} from '../core/_requests'
import {MovementUseQueryResponse, ReceptionUseQueryResponse} from '../core/QueryResponseProvider'
import {useIntl} from 'react-intl'
import {ConfirmModal} from '../../../../../_metronic/layout/components/others/ConfirmModal'
import {MovementUseListView} from '../core/ListViewProvider'
import {useAuth} from '../../../auth'

type Props = {
  id: ID
  type? :string
}

const MovementActionsCell: FC<Props> = ({id,type}) => {
  const {setDetailsItemId,setDetailsItemType} = MovementUseListView()
  const [show, setShow] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const {query} = MovementUseQueryResponse()
  const queryClient = useQueryClient()
  const { setShowA, setMessageA} = MovementUseListView()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openDetailsModal = () => {
    console.log(id)
    setDetailsItemId(id)
    setDetailsItemType(type!)
  }

  const deleteMovementItem = useMutation(() => deleteMovement(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.MOVEMENTS_LIST}-${query}`])
    },
    onError: (error: any) => {
      setShowA(true)
      setMessageA(error.response.data.message)
      setConfirm(false)
    },
  })

  const openConfirmModal = () => {
    setShow(true)
  }

  const confirmDelete = async () => {
    if (confirm) {
      await deleteMovementItem.mutateAsync()
    }
  }

  useEffect(() => {
    confirmDelete()
    return () => {
      setShow(false)
      setConfirm(false)
    }
  }, [confirm])

  const intl = useIntl()

  const {currentUser} = useAuth()

  if(currentUser?.type !=='admin' ){
    return <>
          <div className='menu-item'>
            <a className='menu-link px-3' onClick={openDetailsModal}>
              {intl.formatMessage({id: 'ACTIONS.DETAILS'})}
            </a>
          </div></>
    }
    else{
      return<><ConfirmModal show={show} setShow={setShow} setConfirm={setConfirm} />
        <a
          href='#'
          className='btn btn-light btn-active-light-primary btn-sm mx-2'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          {intl.formatMessage({id: 'MENU.ACTIONS'})}
          <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
        </a>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openDetailsModal}>
              {intl.formatMessage({id: 'ACTIONS.DETAILS'})}
            </a>
          </div>
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-pricings-table-filter='delete_row'
              onClick={openConfirmModal}
            >
              {intl.formatMessage({id: 'ACTIONS.DELETE'})}
            </a>
          </div>
        </div>
      </>
    }
}

const ReceptionActionsCell: FC<Props> = ({id}) => {
  const [show, setShow] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const {query} = ReceptionUseQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const deleteReceptionItem = useMutation(() => deleteReception(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.RECEPTIONS_LIST}-${query}`])
    },
  })

  const openConfirmModal = () => {
    setShow(true)
  }

  const confirmDelete = async () => {
    if (confirm) {
      await deleteReceptionItem.mutateAsync()
    }
  }

  useEffect(() => {
    confirmDelete()
    return () => {
      setShow(false)
      setConfirm(false)
    }
  }, [confirm])

  const intl = useIntl()

  return (
    <>
      <ConfirmModal show={show} setShow={setShow} setConfirm={setConfirm} />
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm mx-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'MENU.ACTIONS'})}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-pricings-table-filter='delete_row'
            onClick={openConfirmModal}
          >
            {intl.formatMessage({id: 'ACTIONS.DELETE'})}
          </a>
        </div>
      </div>
    </>
  )
}

export {MovementActionsCell, ReceptionActionsCell}
