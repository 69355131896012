import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import { MovemntDetailedResponse } from '../../apps/mouvement/core/_models'
import {
  DeliveryPrice,
  deliveryPriceResponse,
  Parcel,
  ParcelsAllQueryResponse,
  ParcelsQueryResponse,
  product,
} from './_models'


const API_URL = process.env.REACT_APP_API_URL

const PARCEL_URL = `${API_URL}/parcel`
const MOVEMENT_URL = `${API_URL}/mouv`




/**Parcel Livred */
const getPaginationParcelsLivred = (query?: string): Promise<MovemntDetailedResponse> => {
 
    return axios
    .get(`${MOVEMENT_URL}/pend?${query}`)
    .then((response: AxiosResponse<MovemntDetailedResponse>) => response.data)
}
const getParcels = (query?: string): Promise<ParcelsQueryResponse> => {
  return axios
   .get(`${PARCEL_URL}`)
    .then((response: AxiosResponse<ParcelsQueryResponse>) => response.data)
}



const getProcessingParcels = (query?: string): Promise<Parcel[]> => {
  return axios
   .get(`${PARCEL_URL}/processing`)
    .then((response: AxiosResponse<Parcel[]>) => response.data)
}

const updateProcessingParcel = (parcel: Parcel): Promise<Parcel> => {
  return axios.post(`${PARCEL_URL}/${parcel.id}/refunded`, {
    status: 'refunded',
    reason: parcel.reason
    
  })
  
}

const getParcelsProvider = (query?: string, status?:string, search?:string, startDate?: string, endDate?: string, provider?: number): Promise<ParcelsQueryResponse> => {
  return axios
   .get(`${PARCEL_URL}/provider?${query}`, { params: { status: status, search: search, start_date: startDate, end_date: endDate, provider: provider } })
    .then((response: AxiosResponse<ParcelsQueryResponse>) => response.data)
}

const getExcelParcelsProvider = (status?:string, search?:string, startDate?: string, endDate?: string, provider?: number)=> {
  return axios
    .get(`${PARCEL_URL}/provider/export`, { params: { status: status, search: search, start_date: startDate, end_date: endDate, provider: provider }, responseType: 'blob' })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
  
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'Colis.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
  
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
  })
}


const getParcelsList = (query?: string): Promise<ParcelsQueryResponse> => {
  return axios
   .get(`${PARCEL_URL}/page?${query}`)
    .then((response: AxiosResponse<ParcelsQueryResponse>) => response.data)
}
const getTransferParcels = (): Promise<ParcelsAllQueryResponse> => {
  return axios
    .get(`${PARCEL_URL}/movement`)
    .then((response: AxiosResponse<ParcelsAllQueryResponse>) => response.data)
   
}

const getReceptionParcels = (): Promise<ParcelsAllQueryResponse> => {
  return axios
    .get(`${PARCEL_URL}/reception`)
    .then((response: AxiosResponse<ParcelsAllQueryResponse>) => response.data)
}

const getParcelById = (id: ID): Promise<Parcel | undefined> => {
  return axios.get(`${PARCEL_URL}/${id}`).then((response: Response<Parcel>) => response.data)
}

const getParcelByTrackingNumber = (trackingNumber: string): Promise<Parcel | undefined> => {
  return axios
    .get(`${PARCEL_URL}/tracking/${trackingNumber}`)
    .then((response: Response<Parcel>) => response.data)
}

const getPaginationParcels = (query?: string, status?:string, search?:string, startDate?: string, endDate?: string): Promise<ParcelsQueryResponse> => {
  return axios
    .get(`${PARCEL_URL}/page?${query}`, { params: { status: status, search: search, start_date: startDate, end_date: endDate } })
    .then((response: AxiosResponse<ParcelsQueryResponse>) => response.data)
}
const getRefundedParcels = (query?: string, status?:string, search?:string, startDate?: string, endDate?: string): Promise<ParcelsQueryResponse> => {
  return axios
    .get(`${PARCEL_URL}/refunded?${query}`, { params: { status: status, search: search, start_date: startDate, end_date: endDate } })
    .then((response: AxiosResponse<ParcelsQueryResponse>) => response.data)
}
const getExcelParcels = (status?:string, search?:string, startDate?: string, endDate?: string)=> {
  return axios
    .get(`${PARCEL_URL}/export`, { params: { status: status, search: search, start_date: startDate, end_date: endDate }, responseType: 'blob' })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
  
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'Colis.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
  
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
  })
}


const getPaginationParcelsPend= (query?: string, status?:string, search?:string, startDate?: string, endDate?: string): Promise<ParcelsQueryResponse> => {

    return axios
    .get(`${PARCEL_URL}/pend?${query}`, { params: { status: status, search: search, start_date: startDate, end_date: endDate } })
    .then((response: AxiosResponse<ParcelsQueryResponse>) => response.data)
}

const createParcel = (parcel: Parcel): Promise<Parcel | undefined> => {
  return axios.post(`${PARCEL_URL}`, parcel).then((response: Response<Parcel>) => response.data)
}


const updateParcel = (parcel: Parcel): Promise<Parcel> => {
  return axios.post(`${PARCEL_URL}/${parcel.id}/update`, {
    quantity: parcel.quantity,
    dimension: parcel.dimension,
    weight: parcel.weight,
    reference: parcel.reference,
    price: parcel.price,
    status: parcel.status,
    pick_up_at: parcel.pick_up_at,
    content: parcel.content,
    delivery_price: parcel.delivery_price,
    sender: {...parcel.sender, created_at: undefined, updated_at: undefined, id: undefined},
    receiver: {...parcel.receiver, created_at: undefined, updated_at: undefined, id: undefined},
  })
}

const deleteParcel = (id: ID): Promise<void> => {
  return axios.post(`${PARCEL_URL}/${id}/delete`)
}

const deleteSelectedParcels = (ParcelIds: Array<ID>): Promise<void> => {
  const requests = ParcelIds.map((id) => axios.post(`${PARCEL_URL}/${id}/delete`))
  return axios.all(requests).then(() => {})
}

const getDeliveryPrice = (dimensions: DeliveryPrice): Promise<deliveryPriceResponse> => {
  return axios
    .get(`${API_URL}/pricing/price`, {params: dimensions})
    .then((response: AxiosResponse<deliveryPriceResponse>) => response.data)
}


const getProducts = (query?: string,  search?:string): Promise<Response<Array<product>>> => {
  return axios
    .get(`${API_URL}/product`, { params: {search: search} })
    .then((response: AxiosResponse<Response<Array<product>>>) => response.data)
}


export {
  getParcelById,
  getReceptionParcels,
  getParcelsProvider,
  getExcelParcelsProvider,
  getTransferParcels,
  getParcels,
  getProcessingParcels,
  getRefundedParcels,
  getParcelsList,
  getExcelParcels,
  getPaginationParcels,
  getPaginationParcelsPend,
  getPaginationParcelsLivred,
  createParcel,
  updateProcessingParcel,
  updateParcel,
  deleteParcel,
  deleteSelectedParcels,
  getDeliveryPrice,
  getParcelByTrackingNumber,
  getProducts
}
