/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {getMovementsDelevery, getMovementsDeleveryProvider}from './_requests'
import { MovemntDetailedResponseDelevery} from './_models'
import {useQueryRequest as movementUseQueryRequest} from './MovementQueryRequestProvider'


const MovementQueryResponseContext = createResponseContext<MovemntDetailedResponseDelevery>(initialQueryResponse)

const MovementQueryResponseProvider: FC<WithChildren> = ({children, Status, Search, StartDate, EndDate, Provider}) => {
  const {state} = movementUseQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)     
    }
    else{
      setQuery(' ')
    }
  }, [updatedQuery, Status, Search, StartDate, EndDate, Provider])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.MOVEMENTS_LIST}-${query}`,
    () => {
      
     return getMovementsDeleveryProvider(query, Status, Search, StartDate, EndDate, Provider) 
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <MovementQueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}} >
      {children}
    </MovementQueryResponseContext.Provider>
  )
}
const MovementQueryResponseParcelProvider: FC<WithChildren> = ({children}) => {
  const {state} = movementUseQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.MOVEMENTS_LIST}-${query}`,
    () => {
      
      return getMovementsDelevery(query)
   
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
   
  )

  return (
    <MovementQueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}} >
      {children}
    </MovementQueryResponseContext.Provider>
  )
}

const MovementUseQueryResponse = () => useContext(MovementQueryResponseContext)
const MovementUseQueryResponseData = () => {
  const {response} = MovementUseQueryResponse()
  if (!response) {
    return []
  }

  return (response.data as Array<MovemntDetailedResponseDelevery>) || []
}

const MovementUseQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }
  const {response} = MovementUseQueryResponse()
  if (!response) {
    return defaultPaginationState
  }
  const {current_page, links, per_page} = response
  return {current_page, links, per_page} as PaginationState
}

const MovementUseQueryResponseLoading = (): boolean => {
  const {isLoading} = MovementUseQueryResponse()
  return isLoading
}

export {
  MovementUseQueryResponsePagination,
  MovementUseQueryResponseLoading,
  MovementQueryResponseProvider,
  MovementQueryResponseParcelProvider,
  MovementUseQueryResponse,
  MovementUseQueryResponseData


}
