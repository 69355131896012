import axios, {AxiosResponse} from 'axios'
import {Parcel, ParcelsQueryResponse } from '../../../modules/wizards/core/_models'
import { PaymentQueryResponse, payment } from './_models'
import { ID, Response } from '../../../../_metronic/helpers'


const API_URL = process.env.REACT_APP_API_URL


const getParcelsCompleted = (search?: string): Promise<ParcelsQueryResponse> => {
  return axios
   .get(`${API_URL}/parcel/page`, { params: { search: search }})
    .then((response: AxiosResponse<ParcelsQueryResponse>) => response.data)
}

const getPaginationPayment = (search?: string, startDate?: string, endDate?: string): Promise<PaymentQueryResponse> => {
  return axios
   .get(`${API_URL}/versement/page`, { params: { search: search, start_date: startDate, end_date: endDate }})
    .then((response: AxiosResponse<PaymentQueryResponse>) => response.data)
}

const getPayment = (id: ID): Promise<payment | undefined> => {
  return axios.get(`${API_URL}/versement/${id}/show`).then((response: Response<payment>) => response.data)
}


const getPaymentDownload = (id: ID) => {
  return axios
    .get(`${API_URL}/versement/${id}/download`, {
      responseType: 'blob' // Ensure response type is set to blob
    })
    .then((response) => {

      const href = URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'Versement.pdf');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch((error) => {
      console.error('Error downloading PDF:', error);
    });
};



const getPaymentFrame = (id: ID): Promise<string | undefined> => {
  return axios
    .get(`${API_URL}/versement/${id}/download`, {
      responseType: 'blob'
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      return url;
    })
    .catch((error) => {
      console.error('Error downloading or displaying PDF:', error);
      throw error;
    });
};





const createPayment = (pdf: File, parcels: number[]): Promise<string | undefined> => {
  const formData = new FormData();
  formData.append('pdf', pdf);
  parcels.forEach((parcelId, index) => {
    formData.append(`parcels[${index}]`, parcelId.toString());
  });


  return axios.post(`${API_URL}/versement/comparison`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  .then((response: AxiosResponse<{message: string}>) => {
    return response.data.message;
  })
  .catch((error) => {
    console.error('Error creating payment:', error);
    return undefined;
  });
};




export {
    getParcelsCompleted,
    createPayment,
    getPaginationPayment,
    getPayment,
    getPaymentDownload,
    getPaymentFrame
}
