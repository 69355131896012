import {FC, forwardRef, useRef, useState} from 'react'
import Barcode from 'react-barcode'
import {useIntl} from 'react-intl'
import ReactToPrint from 'react-to-print'
import {Parcel} from '../core/_models'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { Document, Page, pdfjs  } from 'react-pdf';
import { payment } from '../../../pages/Payment/core/_models'





export const ParcelPrintInfo = forwardRef<HTMLDivElement, payment>((parcel, ref) => {
  const [numPages, setNumPages] = useState<number>();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const onDocumentLoadSuccess = () => {
    setNumPages(1);
  };


console.log(parcel.pdf_path)

  const intl = useIntl()
  return (
   
    
    <div ref={ref} className='m-20 mb-10'>

      {parcel?.parcels?.map((parcel, index) => (  
  <div>     
      <div className='row d-flex'>
        <div className='col-4 p-4 '>
          <Barcode value={parcel?.tracking_number} />
        </div>
        {index === 0 && (
        <><div className='col-4 p-4 justify-content-center text-center'>
                          <h1>{intl.formatMessage({ id: 'PRINT.SLIP' })}</h1>
                      </div><div className='col-4 p-4 justify-content-end'>
                              <img src={toAbsoluteUrl('/media/logo.png')} alt='logoLogiteam' height={100} />
                          </div></>
        )}
       
      </div>
     
      <div className='mb-4'>
        <div className='row'>
          <div className='col-6 border border-black'>
            <div className=' me-1'>
              <span className='d-block row'>
                <span className='fw-bold col-6'>{intl.formatMessage({id: 'GENERAL.FROM'})}:</span>
                <span className='col-6'>
                  {parcel.sender?.firstname + ' ' + parcel.sender?.lastname}
                </span>
              </span>
              <span className='d-block row'>
                <span className='fw-bold col-6'>{intl.formatMessage({id: 'USER.ADDRESS'})}:</span>
                <span className='col-6'>{parcel.sender?.adresse}</span>
              </span>
              <span className='d-block row'>
                <span className='fw-bold col-6'>
                  {intl.formatMessage({id: 'COLUMN.PHONE_NUMBER'})}:
                </span>
                <span className='col-6'>{parcel.sender?.phone_number}</span>
              </span>
              <span className='d-block row'>
                <span className='fw-bold col-6'>
                  {intl.formatMessage({id: 'COLUMN.MOBILEPHONE'})}:
                </span>
                <span className='col-6'>{parcel.sender?.mobile}</span>
              </span>
              <span className='d-block row'>
                <span className='fw-bold col-6'>
                  {intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}:
                </span>
                <span className='col-6'>{parcel.sender?.email}</span>
              </span>
            </div>
          </div>
          <div className='col-6 border border-black'>
            <div className=' ms-1'>
              <span className='d-block row'>
                <span className='fw-bold col-6'>{intl.formatMessage({id: 'GENERAL.TO'})}:</span>
                <span className='col-6'>
                  {parcel.receiver?.firstname + ' ' + parcel.receiver?.lastname}
                </span>
              </span>
              <span className='d-block row'>
                <span className='fw-bold col-6'>{intl.formatMessage({id: 'USER.ADDRESS'})}:</span>
                <span className='col-6'>{parcel.receiver?.adresse}</span>
              </span>
              <span className='d-block row'>
                <span className='fw-bold col-6'>
                  {intl.formatMessage({id: 'COLUMN.PHONE_NUMBER'})}:
                </span>
                <span className='col-6'>{parcel.receiver?.phone_number}</span>
              </span>
              <span className='d-block row'>
                <span className='fw-bold col-6'>
                  {intl.formatMessage({id: 'COLUMN.MOBILEPHONE'})}:
                </span>
                <span className='col-6'>{parcel.receiver?.mobile}</span>
              </span>
              <span className='d-block row'>
                <span className='fw-bold col-6'>
                  {intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}:
                </span>
                <span className='col-6'>{parcel.receiver?.email}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='mb-4'>
        <div className='row '>
        <div className='max-h-[400px] overflow-y-auto'>
          <table className='p-2 border table table-fixed '>
            <thead>
              <tr>
                <th className='border'>
                  <span className='fw-bold'>{intl.formatMessage({id: 'COLUMN.QUANTITY'})}</span>
                </th>
                <th className='border'>
                  <span className='fw-bold'>{intl.formatMessage({id: 'COLUMN.WEIGHT'})}</span>
                </th>
                <th className='border'>
                  <span className='fw-bold'>{intl.formatMessage({id: 'COLUMN.CONTENT'})}</span>
                </th>
                <th className='border'>
                  <span className='fw-bold'>{intl.formatMessage({id: 'COLUMN.REFERENCE'})}</span>
                </th>
                <th className='border'>
                  <span className='fw-bold'>
                    {intl.formatMessage({id: 'GENERAL.CREATION_DATE'})}
                  </span>
                </th>
                <th className='border'>
                  <span className='fw-bold'>
                    {intl.formatMessage({id: 'GENERAL.PICK_UP_DATE'})}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='border'>{parcel.quantity}</td>
                <td className='border'>{parcel.weight}</td>
                <td className='border' style={{ wordBreak: 'break-all' }}>{parcel.content}</td>
                <td className='border'>{parcel.reference}</td>
                <td className='border'>{parcel.created_at?.substring(0, 10)}</td>
                <td className='border'>{parcel.pick_up_at}</td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
      {parcel.reason&&
      <div className='mb-4'>
        <div className='row'>
          <table className='p-2 border table table-fixed'>
            <thead>
              <tr>
                <th className='border fs-5'>
                  <span className='fw-bold'>{intl.formatMessage({id: 'PARCEL.REASON'})}</span>
                </th>
              
               {/** <th className='border'>
                  <span className='fw-bold'>
                    {intl.formatMessage({id: 'COLUMN.DELIVERY_COST'})}
                  </span>
                </th>*/} 
              </tr>
            </thead>
            <tbody>
              <tr className='border fs-5'>
                <td className='border'>{parcel?.reason}</td>
              {/** <td className='border'>{parcel.delivery_price}</td>*/}  
              </tr>
            </tbody>
          </table>
        </div>
      </div>
}
      <div className='mb-4'>
        <div className='row'>
          <table className='p-2 border table table-fixed'>
            <thead>
              <tr>
                <th className='border fs-2'>
                  <span className='fw-bold'>{intl.formatMessage({id: 'COLUMN.PRICE'})}</span>
                </th>
              
               {/** <th className='border'>
                  <span className='fw-bold'>
                    {intl.formatMessage({id: 'COLUMN.DELIVERY_COST'})}
                  </span>
                </th>*/} 
              </tr>
            </thead>
            <tbody>
              <tr className='border fs-3'>
                <td className='border'>{parcel.price > 0 ? parcel.price + 'DT' : 'payé'}</td>
              {/** <td className='border'>{parcel.delivery_price}</td>*/}  
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div>
      ))}

 <Document file={parcel.pdf_path}  onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess} noData='Veuillez patienter...'>
        <Page pageNumber={1} renderAnnotationLayer={false} renderTextLayer={false} />
      </Document> 
      {/* <iframe src="https://www.africau.edu/images/default/sample.pdf" width="540" height="450"></iframe> */}

      
      <div>
        {intl.formatMessage({id: 'PRINT.FOR_MORE_INFO_VISIT'})}{' '}
        <span className='fw-bold'>www.logiteam.tn</span>
      </div>
    </div>

  )
})

type Props = {
  parcel?: payment

}

const ParcelPrint: FC<Props> = ({parcel}) => {
  const componentRef = useRef(null)
  const intl = useIntl()


  return (
    <>
    
      <ParcelPrintInfo
        ref={componentRef}
        parcels={parcel?.parcels}
        pdf_path={parcel?.pdf_path}

      />
      <ReactToPrint
        trigger={() => (
          <button className='btn btn-primary ms-20'>{intl.formatMessage({id: 'GENERAL.PRINT'})}</button>
        )}
        content={() => componentRef.current}
      />
    </>
  )
}

export default ParcelPrint
