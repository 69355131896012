/* eslint-disable jsx-a11y/anchor-is-valid */
import {AxiosError} from 'axios'
import {useFormik} from 'formik'
import {FC, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import Select from 'react-select'

import { KTSVG } from '../../../../_metronic/helpers'
import { AlertModal } from '../../../../_metronic/layout/components/others/AlertModal'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { PaymentListViewProvider, useListView } from '../core/ListViewProvider'
import { PaymentQueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider } from '../core/QueryResponseProvider'
import { createPayment, getParcelsCompleted } from '../core/_requests'
import { Parcel } from '../../../modules/wizards/core/_models'
import { array, mixed, object } from 'yup'



const PaymentAddPage=() => {

  const [selectedParcels, setSelectedParcels] = useState<number[]>([]);
  const [ParcelSearch, setParcelSearch] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [selectedFileName, setSelectedFileName] = useState<any>();
  const fileInputRef = useRef<HTMLInputElement>(null);
    const intl = useIntl()
    const parcels = ParcelsCompletedUseQueryResponseData(ParcelSearch)
    const data = useMemo(() => parcels, [parcels])

    const {setMessageA, setShowA, messageA, showA} = useListView()

    const handleButtonClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };
  
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files && event.target.files[0];
      setSelectedFile(selectedFile);
      formik.setValues({
        ...formik.values,
        file: selectedFile
      });
      setSelectedFileName(selectedFile?.name);
    };


    const handleSelectChange = (selectedOptions: any) => {

      const selectedParcelIds = selectedOptions.map((option: any) => option.value.id);
      setSelectedParcels(selectedParcelIds);
      formik.setValues({
        ...formik.values,
        selectedParcels: selectedParcelIds
      });
    };

  

  
    const handleSubmit = async () => {
      if (selectedFile && selectedParcels.length > 0) {
        try {
          const message = await createPayment(selectedFile, selectedParcels);
          if (message) {
            setShowA(true);
            setMessageA(message)
          } else {
            console.log('No message received.');
          }
        } catch (error) {
          console.error('Error creating payment:', error);
        }
      }
    };
    
        const formik = useFormik({
            initialValues: {
              selectedParcels: selectedParcels || [],
              file: selectedFile,
            },
            
            validationSchema: object().shape({
              selectedParcels: array()
                .min(1, 'Au moins un numéro de suivi est requis'),
                file: mixed()
               .required("Télécharger un fichier")
            }),
            onSubmit: handleSubmit,
          });

console.log(formik.errors)
  return (
    <>
<AlertModal message={messageA} setMessage={setMessageA} show={showA} setShow={setShowA} />
<form id='kt_add_truck_form' className='form' onSubmit={formik.handleSubmit}>
        <div className=' mx-5 mx-xl-15 my-7'>
          <div >
            <div className="mb-10">
              <label className='required fs-6 fw-bold mb-2'>
                {intl.formatMessage({id: 'COLUMN.TRACKING_NUMBER'})}
              </label>       
            
              <Select
      className='form-select form-select-solid'
      aria-label='Default select example'
      styles={{
        control: (provided: any) => ({
          ...provided,
          border: 0,
          boxShadow: 'none',
          backgroundColor: 'transparent',
        }),
      }}
      isMulti
      onInputChange={(ChangedValue) => {
        setParcelSearch(ChangedValue)
       
        }}
      onChange={handleSelectChange}
      options={[
        ...data.map((item) => ({
          value: item,
          label: `${item.tracking_number} - ${item.sender?.firstname} - ${item.receiver?.firstname}`,
        })),
      ]}
    />
      {formik.touched.selectedParcels && formik.errors.selectedParcels && (
            <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.selectedParcels}</div>
          </div>
          )}
             </div>
            </div>
            <div>
          <input
          name="file"
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}

      />
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleButtonClick}
      >
        <span className="indicator-label">
          { intl.formatMessage({ id: 'GENERAL.UPLOAD' })}
        </span>
      </button>
      {selectedFileName?
       <span className='m-4'>{selectedFileName}</span>
       :
       <div className='fv-plugins-message-container'>
            <span className='fv-help-block text-red-500'>{typeof formik.errors.file === 'string'&&formik.errors.file}</span>
          </div>
      }
        </div>
          </div>
       
        <div className='text-end'>
          <button type='submit' className='btn btn-primary mt-3 m-14'>
            
              <span className='indicator-label'>
                {intl.formatMessage({id: 'GENERAL.SUBMIT'})}
              </span>
           
            <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0' />
          </button>
        </div>
        </form>

    </>
  )
}

const ParcelsCompletedUseQueryResponseData = (search?:string) => {
    const [parcelsList, setParcels] = useState<Parcel[]>([])
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await getParcelsCompleted(search);
          setParcels(response.data || []);
        } catch (error) {
        }
      };
      fetchData();
    }, [search]);

    return (parcelsList || [])
  }

const PaymentFormHeader: FC = () => {
  const intl = useIntl()

  const TruckBreadcrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'MENU.PARCEL_MANAGEMENT'}),
      path: '/truck/list',
      isActive: true,
      isSeparator: false,
    },
    {
      title: '',
      path: '',
      isActive: true,
      isSeparator: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={TruckBreadcrumbs}>{intl.formatMessage({id: 'BUTTON.ADD_PARCEL'})}</PageTitle>

      <h2 className='p-4'>{intl.formatMessage({id: 'BUTTON.ADD_PARCEL'})}</h2>
      <div className='separator separator-dashed '></div>
    </>
  )
}

const PaymentAddComplete: FC = () => {

  return (
    <>
      <div className='modal-dialog modal-dialog-centered modal-xl'>
        <div className='modal-content'>
          <PaymentFormHeader />
          <div >
            <PaymentAddPage  />
          </div>
        </div>
      </div>
    </>
  )
}

const PaymentAddWrapper: FC = () => {
  return (
    <PaymentQueryRequestProvider>
      <QueryResponseProvider>
        <PaymentListViewProvider>
          <PaymentAddComplete />
        </PaymentListViewProvider>
      </QueryResponseProvider>
    </PaymentQueryRequestProvider>
  )
}

export {PaymentAddWrapper, PaymentAddPage}
