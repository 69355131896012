import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {Parcel} from '../../../../../wizards/core/_models'
import { UserResponse} from '../../core/_models'
import { DeleveryColumn } from '../../../../mouvement/core/_models'

type UserProps = {
  row: Row<UserResponse>
}

type ParcelProps = {
  row: Row<Parcel>
}

type DeleveryProps = {
  row: Row<DeleveryColumn>
}
const UserCustomRow: FC<UserProps> = ({row}) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return cell.column.id !== 'birthdate' ? (
        <td
          {...cell.getCellProps()}
          className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
          key={cell.column.id}
        >
          {cell.render('Cell')}
        </td>
      ) : (
        <td {...cell.getCellProps()} key={cell.column.id}>
          {cell.row.original.birthdate?.substring(0, 10)}
        </td>
      )
    })}
  </tr>
)

const ParcelCustomRow: FC<ParcelProps> = ({row}) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return ( <td
          {...cell.getCellProps()}
          className={clsx({'text-end min-w-100px': (cell.column.id === 'actions' || cell.column.id === 'status')})}
          key={cell.column.id}
        >
          {cell.render('Cell')}
        </td>)
     
    })}
  </tr>
)

const DeleveryCustomRow: FC<DeleveryProps> = ({row}) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return ( <td
          {...cell.getCellProps()}
          className={clsx({'text-end min-w-100px': (cell.column.id === 'actions' || cell.column.id === 'status')})}
          key={cell.column.id}
        >
          {cell.render('Cell')}
        </td>)
     
    })}
  </tr>
)


export {UserCustomRow, ParcelCustomRow, DeleveryCustomRow}
