import {Response} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {User} from '../../user-management/users-list/core/_models'
import {emailRegExp, numberRegExp, phoneRegExp} from '../../../../../_data'

export interface AddressBook {
  id?: number
  name: string
  label: string
  governorate: string
  address: string
  postal_code: string
  city: string
  supplement?: string
  phone_number: string
  mobile: string
  email?: string
  provider: User
  created_at?: string
  updated_at?: string
}

export interface SavAddressBook {
  id?: number
  category?: string
  desc?:string
  price?:string
  created_at?: string
  updated_at?: string
}


export const initialAddressBook: AddressBook = {
  id: undefined,
  name: '',
  label: '',
  governorate: '',
  address: '',
  postal_code: '',
  city: '',
  supplement: '',
  phone_number: '',
  mobile: '',
  email: '',
  provider: {} as User,
}


export const initialSavAddressBook: SavAddressBook = {
  id: undefined,
  category: '',
  desc: '',
  price: ''
}
export const AddressBookSchema = Yup.object({
  name: Yup.string().min(2, 'VALIDATION.NAME').required('VALIDATION.NAME.REQUIRED'),
  label: Yup.string().required('VALIDATION.LABEL.REQUIRED'),
  governorate: Yup.string().required('VALIDATION.GOVERNORATE.REQUIRED'),
  address: Yup.string().min(5, 'VALIDATION.ADDRESS').required('VALIDATION.ADDRESS.REQUIRED'),
  postal_code: Yup.string()
    .matches(numberRegExp, 'VALIDATION.POSTAL_CODE')
    .min(4, 'VALIDATION.POSTAL_CODE')
    .required('VALIDATION.POSTAL_CODE.REQUIRED'),
  city: Yup.string().required('VALIDATION.CITY.REQUIRED'),
  phone_number: Yup.string().matches(phoneRegExp, 'VALIDATION.PHONE'),
  email: Yup.string()
    .min(8, 'VALIDATION.MOBILE')
    .email('VALIDATION.EMAIL')
    .matches(emailRegExp, 'VALIDATION.EMAIL')
    .required('VALIDATION.EMAIL.REQUIRED'),
  mobile: Yup.string()
    .min(8, 'VALIDATION.MOBILE')
    .matches(phoneRegExp, 'VALIDATION.MOBILE')
    .required('VALIDATION.MOBILE.REQUIRED'),
})


export const SavAddressBookSchema = Yup.object({
  category: Yup.string().required('VALIDATION.CATEGORY.REQUIRED'),
  desc: Yup.string().required('VALIDATION.DESCRIPTION.REQUIRED'),
  price: Yup.string().required('VALIDATION.PRICE.REQUIRED')
})
export type AddressBookColumn = {
  id: number
  name: string
  label: string
  governorate: string
  address: string
  postal_code: string
  city: string
  supplement?: string
  phone_number: string
  mobile: string
  email?: string
  provider: string
  category?: string
  desc?: string
  price?: string
}

export type AddressBookQueryResponse = Response<Array<AddressBook>>
