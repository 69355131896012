/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo, useRef} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../_metronic/helpers'
import {getPaginationParcels, getRefundedParcels} from './_requests'
import {Parcel} from './_models'
import {useQueryRequest} from './QueryRequestProvider'

const QueryResponseContext = createResponseContext<Parcel>(initialQueryResponse)

const ParcelQueryResponseProvider: FC<WithChildren> = ({children, Status, Search, StartDate, EndDate, Return}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

 
  
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)     
    }
    else{
      setQuery(' ')
    }
  }, [updatedQuery, Status, Search, StartDate, EndDate])

  
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.PARCELS_LIST}-${query}`,
    () => {   
  
      return getRefundedParcels(query, Status, Search, StartDate, EndDate )
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  
  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}



const ParcelUseQueryResponse = () => useContext(QueryResponseContext)

const ParcelQueryResponseData = () => {



  const {response} = ParcelUseQueryResponse()
  const parcels = [] as Array<Parcel>
  response &&
    response.data &&
    response.data.forEach((parcel: Parcel) => {
    parcels.push({
      ...parcel,
       
      client_name: parcel?.receiver?.firstname,
      updated_at: parcel?.updated_at?.substring(0, parcel?.updated_at?.indexOf("T"))
    })
    })
  return (parcels as Array<Parcel>) || []
}

const ParcelUseQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }
  const {response} = ParcelUseQueryResponse()
  if (!response) {
    return defaultPaginationState
  }
  const {current_page, links, per_page} = response
  return {current_page, links, per_page} as PaginationState
}

const ParcelUseQueryResponseLoading = (): boolean => {
  const {isLoading} = ParcelUseQueryResponse()
  return isLoading
}

export {
  ParcelQueryResponseProvider,
  ParcelUseQueryResponse,
  ParcelQueryResponseData,
  ParcelUseQueryResponsePagination,
  ParcelUseQueryResponseLoading,
}
