/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryClient, useMutation } from 'react-query'
import { QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView as userListView } from '../../core/ListViewProvider'
import { useListView as parcelListView } from '../../../../../wizards/core/ParcelListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteSelectedUsers } from '../../core/_requests'
import { deleteSelectedParcels } from '../../../../../wizards/core/_requests'
import { ParcelUseQueryResponse } from '../../../../../wizards/core/ParcelQueryResponseProvider'
import { useIntl } from 'react-intl'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useEffect, useState } from 'react'
import { ConfirmModal } from '../../../../../../../_metronic/layout/components/others/ConfirmModal'

const UsersListGrouping = () => {
  const [show, setShow] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const { selected, clearSelected } = userListView()
  const queryClient = useQueryClient()
  const { query } = useQueryResponse()
  const { type } = useQueryRequest()

  const deleteSelectedItems =
    useMutation(() => deleteSelectedUsers(selected, type), {
      onSuccess: () => {
        queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
        clearSelected()
      },
    })

  const openConfirmModal = () => {
    setShow(true)
  }

  const confirmDelete = async () => {
    if (confirm) {
      await deleteSelectedItems.mutateAsync()
    }
  }

  useEffect(() => {
    confirmDelete()
    return () => {
      setShow(false)
      setConfirm(false)
    }
  }, [confirm])

  const intl = useIntl()

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <ConfirmModal show={show} setShow={setShow} setConfirm={setConfirm} />
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> {intl.formatMessage({ id: "BUTTON.SELECTED" })}
      </div>

      <button
        type='button'
        className='btn btn-danger'
        onClick={openConfirmModal}
      >
        {intl.formatMessage({ id: "BUTTON.DELETE_SELECTED" })}
      </button>
    </div>
  )
}

const ParcelsListGrouping = () => {
  const [show, setShow] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const { selected, clearSelected, setShowA, setMessageA } = parcelListView()
  const queryClient = useQueryClient()
  const { query } = ParcelUseQueryResponse()
  const intl = useIntl()

  const deleteSelectedItems = useMutation(() => deleteSelectedParcels(selected), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.PARCELS_LIST}-${query}`])
      clearSelected()
      setMessageA(intl.formatMessage({ id: "MESSAGE.PARCELS_DELETED" }))
      setShowA(true)
    },
  })

  const openConfirmModal = () => {
    setShow(true)
  }

  const confirmDelete = async () => {
    if (confirm) {
      await deleteSelectedItems.mutateAsync()
    }
  }

  useEffect(() => {
    confirmDelete()
    return () => {
      setShow(false)
      setConfirm(false)
    }
  }, [confirm])

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <ConfirmModal show={show} setShow={setShow} setConfirm={setConfirm} />
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> {intl.formatMessage({ id: "BUTTON.SELECTED" })}
      </div>

      <button
        type='button'
        className='btn btn-danger'
        onClick={openConfirmModal}
      >
        {intl.formatMessage({ id: "BUTTON.DELETE_SELECTED" })}
      </button>
    </div>
  )
}

export { UsersListGrouping, ParcelsListGrouping }
