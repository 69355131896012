import {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {ColumnInstance, Row, useTable} from 'react-table'
import {KTCardBody, KTSVG} from '../../../../../_metronic/helpers'
import {ListLoading} from '../../../../../_metronic/helpers/components/loading/ListLoading'
import {ListPagination} from '../../../../../_metronic/helpers/components/pagination/ListPagination'
import {AddressBookCustomHeaderColumn} from '../columns/CustomHeaderColumn'
import {AddressBookCustomRow} from '../columns/CustomRow'
import {AddressBookSavColumns} from '../columns/_columns'
import {AddressBookUseQueryRequest} from '../core/QueryRequestProvider'
import {
  AddressBookUseQueryResponseData,
  AddressBookUseQueryResponseLoading,
  AddressBookUseQueryResponsePagination,
} from '../core/QueryResponseSavProvider'
import { AddressBookColumn} from '../core/_models'
type ChildComponentProps = {
  sendSearch: (data: string) => void;
};

export const AddressBookSavTable : FC<ChildComponentProps> = ({sendSearch}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const intl = useIntl()
  const addressBooks = AddressBookUseQueryResponseData()
  const isLoading = AddressBookUseQueryResponseLoading()
  const columns = useMemo(() => AddressBookSavColumns, [])
  const data = useMemo(() => addressBooks, [addressBooks])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleSendData = () => {
    sendSearch(searchTerm)
  };

  return (
    <KTCardBody className='py-4'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          className='form-control form-control-solid w-250px ps-14'
          onChange={handleSearchChange}
          value={searchTerm}
        />
        <button type="button" className ="btn btn-primary mx-4" onClick={handleSendData}>Rechercher</button>
      </div>
      {/* end::Search */}
      <div className='table-responsive'>
        <table
          id='kt_table_relays'
          className='table table-striped align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<AddressBookColumn>) => (
                <AddressBookCustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className=' fs-5 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows
                .map((row: Row<AddressBookColumn>, index: number) => {
                  prepareRow(row)
                  return <AddressBookCustomRow key={index} row={row} />
                })
            )  : (
              <tr>
                <td colSpan={headers.length} className='text-center'>
                  {intl.formatMessage({id: 'TABLE.NO_DATA'})}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isLoading && <ListLoading />}
      <ListPagination
        useQueryRequest={AddressBookUseQueryRequest}
        useQueryResponseLoading={AddressBookUseQueryResponseLoading}
        useQueryResponsePagination={AddressBookUseQueryResponsePagination}
        item='addresses'
      />
    </KTCardBody>
  )
}
