/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'

import { TablesWidgetParam } from '../../../_metronic/partials/widgets/tables/TablesWidgetParam'

const ParamPage: FC = () => (
  <> 
        <TablesWidgetParam className='card-xxl-stretch mb-5 mb-xl-8' />
  </>
)

const ParamPageWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
       <PageTitle >Configuration</PageTitle>
      <ParamPage />
    </>
  )
}

export {ParamPageWrapper}
