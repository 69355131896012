/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { ParcelTableListWrapper } from '../../../../_metronic/partials/widgets'
import { PaymentTableListWrapper } from './TablesWidgetPayment'


const PaymentListePage: FC = () => (
  <>
    <PaymentTableListWrapper className='card-xxl-stretch mb-5 mb-xl-8' />
  </>
)

const PaymentListWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[{
                title: intl.formatMessage({ id: 'MENU.PARCEL_MANAGEMENT' }),
                path: '/apps/user-management/users',
                isSeparator: false,
                isActive: false,
              }]}>
                {intl.formatMessage({ id: 'TITLE.PARCEL_LIST' })}
              </PageTitle>
      <PaymentListePage />
    </>
  )
}

export { PaymentListWrapper }
