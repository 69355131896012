/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {ParcelQueryResponseProvider} from '../../../../app/modules/wizards/core/ParcelReturnQueryResponseProvider'
import {ParcelQueryRequestProvider} from '../../../../app/modules/wizards/core/QueryRequestProvider'
import {
  ParcelListViewProvider,
  useListView,
} from '../../../../app/modules/wizards/core/ParcelListViewProvider'
import {EditParcel} from './components/EditParcel'
import {KTCard} from '../../../helpers'
import {TablesBodyParcel, getCurrentDateInput} from '../../../../app/pages/Parcel/ParcelList/ParcelTable'
import {ParcelsReturnListHeader} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListHeader'
import {AlertModal} from '../../../layout/components/others/AlertModal'
import { TablesBodyParcelReturn } from '../../../../app/pages/Parcel/ParcelList/ParcelReturnTable'



type Props = {
  className?: string
}

const ParcelReturnTableListWrapper: FC<Props> = ({className}) => {


  const [Status, setStatus] = useState("")
  const [Search, setSearch] = useState("")
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const [startDate, setStartDate] = useState(getCurrentDateInput(new Date(currentYear, currentMonth, 1)))
  const [endDate, setEndDate] = useState(getCurrentDateInput(new Date()))
  const {itemIdForUpdate, messageA, setMessageA, setShowA, showA} = useListView()
  const processReceivedData = (data: string) => {
    setStatus(data)
  }
  const processSearch = (data: string) => {
    setSearch(data)
  }
  const processStartDate = (data: string) => {
    setStartDate(data)
  }
  const processEndDate = (data: string) => {
    setEndDate(data)
  }
  console.log(Status)

  return (
    <div className={className}>
      <ParcelQueryRequestProvider>
        <ParcelQueryResponseProvider Status={Status} Search={Search} StartDate={startDate} EndDate={endDate} >
          <ParcelListViewProvider>
          <KTCard>
        <ParcelsReturnListHeader Status={Status} Search={Search} StartDate={startDate} EndDate={endDate}/>
        <TablesBodyParcelReturn  sendStatus={processReceivedData} sendSearch={processSearch} sendStartDate={processStartDate} sendEndDate={processEndDate}/>
      </KTCard>
      {itemIdForUpdate ? <EditParcel /> : null}
      <AlertModal message={messageA} setMessage={setMessageA} show={showA} setShow={setShowA} />
          </ParcelListViewProvider>
        </ParcelQueryResponseProvider>
      </ParcelQueryRequestProvider>
    </div>
  )
}

export {ParcelReturnTableListWrapper}
