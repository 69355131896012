/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

type Props = {
  role: string
}

export function AsideMenuMain(props: Props) {
  const intl = useIntl()

  return (
    <>
    

      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
       <AsideMenuItem
        to='/delevery'
        icon='/media/icons/duotune/maps/map006.svg'
        title={intl.formatMessage({id: 'MENU.PARCEL'})}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to='/reclamation'
        icon='/media/icons/duotune/general/gen040.svg'
        title='Claiming'
        fontIcon='bi-layers'
      /> */}
      <AsideMenuItem
        to='/Position'
        icon='/media/icons/duotune/maps/map007.svg'
        title={intl.formatMessage({id: 'MENU.TRACKING_POSITION'})}
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'MENU.MANAGEMENT'})}
          </span>
        </div>
      </div>

      {(props.role === 'admin' || props.role === 'provider'  ) && (
        <AsideMenuItemWithSub
          to='/crafted/pages'
          title={intl.formatMessage({id: 'MENU.PARCEL_MANAGEMENT'})}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        >
           <AsideMenuItem
            to='/Parcel/List'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
          <AsideMenuItemWithSub
            to='/Parcel/Add'
            title={intl.formatMessage({id: 'MENU.ADD'})}
            hasBullet={true}
          >
            <AsideMenuItem
              to='/Parcel/Normal_Add'
              title={intl.formatMessage({id: 'MENU.NORMAL_ADD'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/Parcel/Simple_Add'
              title={intl.formatMessage({id: 'MENU.SIMPLE_ADD'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </AsideMenuItemWithSub>
      )}

      
       {( props.role === 'agent' || props.role === 'controle') && (
         <AsideMenuItemWithSub
         to='/crafted/pages'
         title={intl.formatMessage({id: 'MENU.PARCEL_MANAGEMENT'})}
         fontIcon='bi-archive'
         icon='/media/icons/duotune/general/gen022.svg'
       >
         <AsideMenuItem
           to='/Parcel/List'
           title={intl.formatMessage({id: 'MENU.LIST'})}
           hasBullet={true}
         />
         <AsideMenuItemWithSub
           to='/Parcel/Add'
           title={intl.formatMessage({id: 'MENU.ADD'})}
           hasBullet={true}
         >
           <AsideMenuItem
             to='/Parcel/Normal_Add_Agent'
             title={intl.formatMessage({id: 'MENU.NORMAL_ADD'})}
             hasBullet={true}
           />
           <AsideMenuItem
             to='/Parcel/Simple_Add_Agent'
             title={intl.formatMessage({id: 'MENU.SIMPLE_ADD'})}
             hasBullet={true}
           />
         </AsideMenuItemWithSub>
       </AsideMenuItemWithSub>
       )}
     
      {(props.role === 'admin' ||  props.role === 'controle') && (
        <AsideMenuItemWithSub
          to='/crafted/pages'
          title={intl.formatMessage({id: 'MENU.PARCEL_PROVIDER'})}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        >
          <AsideMenuItem
            to='/Parcel/Provider'
            title={intl.formatMessage({id: 'MENU.PARCEL_PROVIDER_LIST'})}
            hasBullet={true}
          />
          
          <AsideMenuItem
            to='/Parcel/delevery'
            title={intl.formatMessage({id: 'MENU.PARCEL_DELEVERY'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}


{( props.role === 'controle' || props.role=== 'admin') && (
         <AsideMenuItemWithSub
         to='/crafted/pages'
         title={intl.formatMessage({id: 'MENU.PARCEL_RETURN'})}
         fontIcon='bi-archive'
         icon='/media/icons/duotune/general/gen022.svg'
       >
          <AsideMenuItem
           to='Parcel/Return/List'
           title={intl.formatMessage({id: 'MENU.PARCEL_RETURN_LIST'})}
           hasBullet={true}
         />
         <AsideMenuItem
           to='/Parcel/Return/Add'
           title={intl.formatMessage({id: 'MENU.PARCEL_RETURN_Add'})}
           hasBullet={true}
         />
       </AsideMenuItemWithSub>
       )}

{( props.role === 'agent' || props.role === 'admin'|| props.role === 'controle') && (
         <AsideMenuItemWithSub
         to='/crafted/pages'
         title={intl.formatMessage({id: 'MENU.PARCEL_SAV'})}
         fontIcon='bi-archive'
         icon='/media/icons/duotune/general/gen022.svg'
       >
     <AsideMenuItem
            to='/Parcel/SAV_Add'
            title={intl.formatMessage({id: 'MENU.SAV_ADD'})}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/SAV/pircing/management'
            title={intl.formatMessage({id: 'MENU.SAV_PRICING'})}
            hasBullet={true}
          />
         </AsideMenuItemWithSub>
       )}

{( props.role === 'agent' || props.role === 'admin' || props.role === 'controle') && (
         <AsideMenuItemWithSub
         to='/crafted/pages'
         title={intl.formatMessage({id: 'MENU.PAYMENT'})}
         fontIcon='bi-archive'
         icon='/media/icons/duotune/general/gen022.svg'
       >
       <AsideMenuItem
            to='Versement/List'
            title={intl.formatMessage({id: 'MENU.PAYMENT_List'})}
            hasBullet={true}
          />
          <AsideMenuItem
            to='Versement/Add'
            title={intl.formatMessage({id: 'MENU.PAYMENT_ADD'})}
            hasBullet={true}
          />
         </AsideMenuItemWithSub>
       )}

       
      <AsideMenuItem
        to='app/pricing/management'
        title={intl.formatMessage({id: 'MENU.PRICING'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/finance/fin003.svg'
      />
      {(props.role === 'admin' || props.role === 'controle') && ( //|| props.role === 'agent'
        <AsideMenuItemWithSub
          to='/crafted/pages'
          title={intl.formatMessage({id: 'MENU.TRUCKS_MANAGEMENT'})}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/ecommerce/ecm006.svg'
        >
          <AsideMenuItem
            to='/Truck/List'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/Truck/Add'
            title={intl.formatMessage({id: 'MENU.ADD'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
      {(props.role === 'admin' || props.role === 'controle' || props.role === 'provider') && (//|| props.role === 'provider'
        <AsideMenuItem
          to='/app/addressbook/management'
          title={intl.formatMessage({id: 'MENU.ADDRESS_BOOK'})}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/ecommerce/ecm004.svg'
        />
      )}
      <AsideMenuItem
        to='/app/relaypoints/management'
        title={intl.formatMessage({id: 'MENU.RELAY_POINTS'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen018.svg'
      />
      {(props.role === 'admin' || props.role === 'agent' || props.role === 'controle') && (
        <AsideMenuItemWithSub
          to='/crafted/pages'
          title={intl.formatMessage({id: 'MENU.PARCEL_MOVEMENT_MANAGEMENT'})}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/maps/map006.svg'
        >
          <AsideMenuItem
            to='/movement/list'
            title={intl.formatMessage({id: 'MENU.TRANSFERS_LIST'})}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/movement/add'
            title={intl.formatMessage({id: 'MENU.CREATE_PARCEL_MOVEMENT'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}

      {(props.role === 'admin' || props.role === 'agent' || props.role === 'controle') && (
        <AsideMenuItemWithSub
          to='/crafted/pages'
          title={intl.formatMessage({id: 'MENU.RECEPTION_MANAGEMENT'})}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/maps/map001.svg'
        >
          <AsideMenuItem
            to='/reception/list'
            title={intl.formatMessage({id: 'MENU.RECEPTION_LIST'})}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/reception/add'
            title={intl.formatMessage({id: 'MENU.RECEPTION'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
      {/* <AsideMenuItem
        to='/crafted/account/overview'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Account'
        fontIcon='bi-layers'
      /> */}
      {props.role === 'admin' && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'MENU.GENERAL_ADMIN'})}
              </span>
            </div>
          </div>

          <AsideMenuItem
            to='/apps/user-management/users'
            icon='/media/icons/duotune/general/gen051.svg'
            title={intl.formatMessage({id: 'MENU.USERS_MANAGEMENT'})}
            fontIcon='bi-layers'
          />
        </>
      )}
      {/* <AsideMenuItem
        to='/param'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Configuration'
        fontIcon='bi-layers'
      /> */}
    </>
  )
}
