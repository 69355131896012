/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../helpers'
import { useLang, setLanguage } from '../../../i18n/Metronici18n'


type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
}

const Languages: FC<Props> = ({
  toggleBtnClass = '',
  toggleBtnIconClass = 'svg-icon-2',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}) => {

  const languages = [
    {
      lang: 'en',
      name: useIntl().formatMessage({ id: "LANGUAGE.ENGLISH" }),
      flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    },
    {
      lang: 'fr',
      name: useIntl().formatMessage({ id: "LANGUAGE.FRENCH" }),
      flag: toAbsoluteUrl('/media/flags/france.svg'),
    },
    {
      lang: 'ar',
      name: useIntl().formatMessage({ id: "LANGUAGE.ARABIC" }),
      flag: toAbsoluteUrl('/media/flags/tunisia.svg'),
    }
  ]
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  const intl = useIntl()
  return (
    <>
      <a
        href='#'
        className={clsx('btn btn-icon border ', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        <span className='menu-title'>
          <span className='fs-8 rounded px-3 py-2 '>
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='metronic'
            />
          </span>
        </span>
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
        {languages.map((l) => (
          <div
            className='menu-item px-3 my-0'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', { active: l.lang === currentLanguage?.lang })}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </>
  )
}

export { Languages }
