import {FC, useState, createContext, useContext} from 'react'
import {
  QueryState,
  UserQueryRequestContextProps,
  userInitialQueryRequest,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import { UserTypes } from './_models'

const QueryRequestContext = createContext<UserQueryRequestContextProps>(userInitialQueryRequest)

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<QueryState>(userInitialQueryRequest.state)
  const [type, setType] = useState<UserTypes>('provider')

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState
    setState(updatedState)
  }

  const updateType = (type: UserTypes) => {
    setType(type)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState, type, updateType}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
