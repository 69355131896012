/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'

import { TablesWidgetNewParcel } from '../../../_metronic/partials/widgets/tables/TablesWidgetNewDashboard'


const DashboardPage: FC = () => (
  <>
      <TablesWidgetNewParcel className='row gy-5 g-xl-8' />
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
