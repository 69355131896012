import {Response} from '../../../../../_metronic/helpers'
import {Truck} from '../../../../pages/Truck/core/_models'
import { ParcelPersonModel } from '../../../wizards/core/_models'
import {RelayPoint} from '../../RelayPoints/core/_models'
import { getRelayPointDestination } from '../../RelayPoints/core/_requests'
import {User, UserTypes} from '../../user-management/users-list/core/_models'
import * as Yup from 'yup'

// MOVEMENT
export type MovementType = 'it' | 'd' | 'p'

export type Movement = {
  id?: number
  parcel_id: number[]
  truck_id?: number
  driver_id?: number
  agent_id: number
  rp_src_id?: number
  rp_dest_id?: number
  type: MovementType
  register:number
  parcels: Array<{
    id: number
    tracking_number: string
    quantity:number
    dimension: number
    weight:number
    reference: string
    price: number
    status?: 'pending' | 'processing' | 'completed' | 'canceled' | 'refunded'
    content: string
    reason?: string
    pick_up_at?: string
    user_id?: number
    updated_at?: string
    created_at?: string
    delivery_price: number
    sender?: ParcelPersonModel
    receiver?: ParcelPersonModel
    destination?: string
    departure?: string
  }>
}

export const MovementSchema = Yup.object({
  truck_id: Yup.number()
              .required()
              .moreThan(0, 'VALIDATION.TRUCK.REQUIRED'),
  driver_id: Yup.number()
  .required()
  .moreThan(0, 'VALIDATION.DRIVER.REQUIRED'),
  rp_dest_id:
  Yup.number()
  .required()
  .moreThan(0, 'VALIDATION.DESTINATION.REQUIRED')
})

export type MovementResponse = {
  id: number
  parcels_count: number
  received_at: string
  type: MovementType
  register:number
  driver: {
    id?: number
    firstname?: string
    lastname?: string
    modelName: 'admin' | UserTypes
    is_admin: boolean
  }
  relay_point_src: {
    id?: number
    name?: string
  }
  relay_point_dest: {
    id?: number
    name?: string
  }
  truck: {
    id: number
    type_truck: string
    truck_number: string
  }
  parcels: Array<{
    id: number
    tracking_number: string
    quantity:number
    relay?: string
    dimension: number
    weight:number
    reference: string
    price: number
    status?: 'pending' | 'processing' | 'completed' | 'canceled' | 'refunded'
    content: string
    reason?: string
    pick_up_at?: string
    user_id?: number
    updated_at?: string
    created_at?: string
    delivery_price: number
    sender?: ParcelPersonModel
    receiver?: ParcelPersonModel
    destination?: string
    departure?: string
  }>
}

export type MovemntDetailedResponse = {
  id: number
  truck_id?: number
  agent_id?: number
  driver_id?: number
  rp_src_id?: number | null
  rp_dest_id?: number | null
 
  type:string
  created_at?: string
  updated_at?: string
  received_at: string
  parcels: Array<{
    id: number
    tracking_number: string
    quantity:number
    relay?: string
    dimension: number
    weight:number
    reference: string
    price: number
    status?: 'pending' | 'processing' | 'completed' | 'canceled' | 'refunded'
    content: string
    reason?: string
    pick_up_at?: string
    user_id?: number
    updated_at?: string
    created_at?: string
    delivery_price: number
    sender?: ParcelPersonModel
    receiver?: ParcelPersonModel
    destination?: string
    departure?: string
  }>
  truck: Truck
  driver: User
  relay_point_src: RelayPoint
  relay_point_dest: RelayPoint
  register:number
}

export type MovementQueryResponse = Response<Array<MovementResponse>>

export type MovementColumn = {
  id?: number
  parcels_count: number
  driver_fullname: string
  truck_number: string
  relay_point_src: string
  relay_point_dest: string
  received_at: string
  type: MovementType
  register:number
}
export type DeleveryColumn = {
  id?: number
  received_at: string
  trucking_number:string
  prix:number
  agent_fullname: string
  client_fullname: string
  num_provider: string
  pick_up_at:string
  register:number
  reception_at:string
  delevery_price: number
  phone_number?: string

}

const response =  getRelayPointDestination();

export const movementInitial: Movement = {
  parcel_id: [],
  truck_id: 0,
  driver_id: 0,
  agent_id: 0,
  rp_src_id: 0,
  rp_dest_id: 0,
  type: 'it',
  register: 0,
  parcels: []
}



// RECEPTION
export type ReceptionQueryResponse = Response<Array<ReceptionResponse>>

export type Reception = {
  id?: number
  user_id?: number
  rp_id?: number
  parcel_id: number[]
  received_at?: string
  created_at?: string
  updated_at?: string
}

export type ReceptionAll = Reception[]

export type ReceptionResponse = {
  id: number
  received_at: string
  relay: {
    id: number
    name: string
  }
  parcels_count: number
  user: {
    id: number
    firstname: string
    lastname: string
  }
}

export type ReceptionColumn = {
  type?: string
  id?: number
  received_at?: string
  user?: string
  parcels_count?: number
  rp_name?: string
}

// OPTIONS
export type Option = {
  value: number
  label: string
}

export type Options = Array<Option>

/*********************** Delevery */
export type MovemntDetailedResponseDelevery = {
  id: number
  truck_id?: number
  agent_id?: number
  driver_id?: number
  rp_src_id?: number | null
  rp_dest_id?: number | null
  type:string
  created_at?: string
  updated_at?: string
  received_at: string
  register:number
  parcels: Array<{
    id: number
    tracking_number: string
    quantity:number
    relay?: string
    dimension: number
    weight:number
    reference: string
    price: number
    status?: 'pending' | 'processing' | 'completed' | 'canceled' | 'refunded'
    content: string
    reason?: string
    pick_up_at?: string
    user_id?: number
    updated_at?: string
    created_at?: string
    delivery_price: number
    sender?: ParcelPersonModel
    receiver?: ParcelPersonModel
    destination?: string
    departure?: string
    receptions: Array<{
      received_at: string
    }>
  }>

  agent?:User

}


export type MovementQueryResponseDelevery= Response<Array<MovemntDetailedResponseDelevery>>