import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  initialListView,
  groupingOnSelectAll,
  WithChildren,
  ListViewContextProps,
} from '../../../../../_metronic/helpers'
import { AddressBookUseQueryResponse, AddressBookUseQueryResponseData } from './QueryResponseProvider'


const ListViewContext = createContext<ListViewContextProps>(initialListView)

const AddressBookListViewProvider: FC<WithChildren> = ({children}) => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [showA, setShowA] = useState<boolean>(false)
    const [messageA, setMessageA] = useState<string>('')
    const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
    const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
    const { isLoading } = AddressBookUseQueryResponse()
    const data = AddressBookUseQueryResponseData()
    const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
    const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])
  
    return (
      <ListViewContext.Provider
        value={{
          searchTerm,
          setSearchTerm,
          selected,
          itemIdForUpdate,
          setItemIdForUpdate,
          disabled,
          isAllSelected,
          showA,
          setShowA,
          messageA,
          setMessageA,
          onSelect: (id: ID) => {
            groupingOnSelect(id, selected, setSelected)
          },
          onSelectAll: () => {
            groupingOnSelectAll(isAllSelected, setSelected, data)
          },
          clearSelected: () => {
            setSelected([])
          },
        }}
        >
        {children}
        </ListViewContext.Provider>
    )
}

const AddressBookUseListView = () => useContext(ListViewContext)

export  {AddressBookListViewProvider, AddressBookUseListView}