import axios, { AxiosResponse } from "axios"
import { AddressBook, AddressBookQueryResponse, SavAddressBook } from "./_models"
import { ID } from "../../../../../_metronic/helpers"

const API_URL = process.env.REACT_APP_API_URL
const ADRESSBOOK_API_URL = `${API_URL}/addressbook`

const getAddressBook = (): Promise<AddressBook[]> => {
return axios
    .get(ADRESSBOOK_API_URL)
    .then((response: AxiosResponse<AddressBook[]>) => response.data)
}

const getAddressBookPaginated = (query: String , search?:string): Promise<AddressBookQueryResponse> => {
return axios
    .get(`${ADRESSBOOK_API_URL}/page?${query}`, { params: {search: search} })
    .then((response: AxiosResponse<AddressBookQueryResponse>) => response.data)
}

const getAddressBookSavPaginated = (query: String , search?:string): Promise<AddressBookQueryResponse> => {
    return axios
        .get(`${API_URL}/pricingsav?${query}`, { params: {search: search} })
        .then((response: AxiosResponse<AddressBookQueryResponse>) => response.data)
    }
  
const getAddressBookById = (id: ID): Promise<AddressBook | undefined> => {
return axios
    .get(`${ADRESSBOOK_API_URL}/${id}`)
    .then((response: AxiosResponse<AddressBook>) => response.data)
}


const createAddressBook = (addressBook: AddressBook): Promise<AddressBook> => {
return axios
    .post(ADRESSBOOK_API_URL, addressBook)
    .then((response: AxiosResponse<AddressBook>) => response.data)
}

const createSavAddressBook = (addressBook: SavAddressBook): Promise<SavAddressBook> => {
    return axios
        .post(`${API_URL}/pricingsav`, addressBook)
        .then((response: AxiosResponse<SavAddressBook>) => response.data)
    }

const updateAddressBook = (addressBook: AddressBook): Promise<AddressBook> => {
return axios
    .post(`${ADRESSBOOK_API_URL}/${addressBook.id}/update`, addressBook)
    .then((response: AxiosResponse<AddressBook>) => response.data)
}

const deleteAddress = (id: ID): Promise<AddressBook> => {
return axios
    .post(`${ADRESSBOOK_API_URL}/${id}/delete`)
    .then((response: AxiosResponse<AddressBook>) => response.data)
}

const deleteSelectedAddresses = (ids: ID[]): Promise<AddressBook[]> => {
const requests = ids.map((id) => deleteAddress(id))
return axios.all(requests)
}

export {
    getAddressBook,
    getAddressBookPaginated,
    getAddressBookSavPaginated,
    getAddressBookById,
    createAddressBook,
    createSavAddressBook,
    updateAddressBook,
    deleteAddress,
    deleteSelectedAddresses,
}