import * as Yup from 'yup'
import { Response } from '../../../../_metronic/helpers'

export type Truck = {
    id?: number
    type_truck: string
    truck_code: string
    truck_number: string
    created_at?: string
    updated_at?: string
}

export type TruckAllQueryResponse = Array<Truck>

export type TruckQueryResponse = Response<Array<Truck>>

export const initialTruck: Truck = {
    type_truck: '',
    truck_code: '',
    truck_number: '',
}

export const addTruckSchema = Yup.object({
    type_truck: Yup.string().required('Type Truck is required'),
    truck_code: Yup.string().required('Truck Code is required'),
    truck_number: Yup.string().required('Truck Number is required'),
})