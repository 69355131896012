
import {FC} from 'react'
import {ColumnInstance} from 'react-table'
import { Parcel } from '../../../../../wizards/core/_models'
import { UserResponse} from '../../core/_models'

type UserProps = {
  column: ColumnInstance<UserResponse>
}

type ParcelProps = {
  column: ColumnInstance<Parcel>
}

const UserCustomHeaderColumn: FC<UserProps> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th className='' {...column.getHeaderProps()}>
        {column.render('Header')}
      </th>
    ) : (
      column.render('Header')
    )}
  </>
)

const ParcelCustomHeaderColumn: FC<ParcelProps> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)


export {UserCustomHeaderColumn, ParcelCustomHeaderColumn}
