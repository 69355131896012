/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../_metronic/helpers'

import {useQueryRequest} from './QueryRequestProvider'
import { payment } from './_models'
import { getPaginationPayment } from './_requests'

const QueryResponseContext = createResponseContext<payment>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children, Search, StartDate, EndDate}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)     
    }
    else{
      setQuery(' ')
    }
  }, [updatedQuery, Search, StartDate, EndDate])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.TRUCKS_LIST}-${query}`,
    () => {
      return getPaginationPayment(Search, StartDate, EndDate)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  const payments = [] as Array<payment>
  
  response &&
  response.data &&
    response.data.forEach((payment: payment) => {
      payments.push({
        ...payment,

        created_at: payment.created_at?.substring(0,payment?.created_at.lastIndexOf("T")),
        updated_at: payment.updated_at?.substring(0,payment?.updated_at.lastIndexOf("T"))
      })
    })
  
  return (payments as Array<payment>) || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }
  const {response} = useQueryResponse()
  if (!response) {
    return defaultPaginationState
  }
  const {current_page, links, per_page} = response
  return {current_page, links, per_page} as PaginationState
}

const useQueryResponseLoading = () => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
