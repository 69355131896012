import {FC} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {PositionResponse, transfer} from '../core/_models'

type TrackingProps = {
  className: string
  header: string
  items: PositionResponse
}

export const TrackingList: FC<TrackingProps> = ({className, header, items}) => {
  const intl = useIntl()
  return (
    <div>
      <div className={`card ${className} border mt-4`}>
        {header && (
          <div className='card-header align-items-center border-0 mt-4'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='fw-bold mb-2 text-dark'>{header}</span>
             {/**<span className='text-muted fw-semibold fs-7'>State</span> */} 
            </h3>
          </div>
        )}
        <div className='card-body pt-5'>
          <div className='timeline-label'>
            <div className='timeline-item'>
              <div className='timeline-label h6 text-gray-800 fs-6'>
                <KTSVG
                  path='/media/icons/duotune/ecommerce/ecm006.svg'
                  className='svg-icon-2 svg-icon-gray-900'
                />
              </div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-primary fs-1'></i>
              </div>
              <div className='fw-mormal timeline-content fw-bold text-gray-800 ps-3'>
                {items.source.adresse}<span className='text-muted fw-bold d-block fs-7'> {items.source.firstname} ,{items.source.mobile}</span>
              </div>
            </div>
            {items.transfers?.length > 0 &&
                  items.transfers.map((item, index) => (
                    (index === items.transfers.length - 2 && item.type === 'Réception' && items.nb_jrs!=="")
              || (index === items.transfers.length - 1 && item.type === 'Réception' && items.nb_jrs!=="") ?
              <div className='timeline-item' >
              <div className='timeline-label fw-bold text-success fs-6'>                  
              </div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-success fs-1'></i>
              </div>
              <div className='fw-mormal timeline-content fw-bold text-gray-800 ps-3'>
              Réceptionnée à {item.source}
                <span className='text-muted fw-bold d-block fs-7'>
                  <time>{item.governorate} ,{item.mobile}</time>
                </span>
                <p className="mt-10 text-gray-600">Livraison en {items.nb_jrs} jours</p>
              </div>                
            </div>
            :
                    (item?.type==="Réception")?
                    <div className='timeline-item' >
                      <div className='timeline-label fw-bold text-gray-800 fs-6'> 
                      </div>
                  <div className='timeline-badge'>
                    <i className='fa fa-genderless text-primary fs-1'></i>
                  </div>
                  <div className='fw-mormal timeline-content fw-bold text-gray-800 ps-3'>
                  Réceptionnée à {item.source}
                    <span className='text-muted fw-bold d-block fs-7'>
                    <time>{item.governorate} ,{item.mobile}</time>
                    </span>
                  </div>
                  </div>
                :(item?.type==="Transfert")?
                <div className='timeline-item' >
                <div className='timeline-label fw-bold text-gray-800 fs-6'>                  
                </div>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <div className='fw-mormal timeline-content fw-bold text-gray-800 ps-3'>
                Transferée de {item.source} à {item.destination}
                  <span className='text-muted fw-bold d-block fs-7'>
                    <time>{item.governorate} ,{item.mobile}</time>
                  </span>
                </div>                
              </div>
                :
                <div className='timeline-item' >
                <div className='timeline-label fw-bold text-gray-800 fs-6'>                  
                </div>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-primary fs-1'></i>
                </div>
                <div className='fw-mormal timeline-content fw-bold text-gray-800 ps-3'>
                Livrée à {item.source}
                  <span className='text-muted fw-bold d-block fs-7'>
                    <time>{item.governorate} ,{item.mobile}</time>
                  </span>
                </div>                
              </div>
              ))}
              {items.destination && items.transfers[items.transfers.length-1]?.type!=="Livraison" && (
            <div className='timeline-item'>
              <div className='timeline-label h6 text-gray-800 fs-6'>
                <KTSVG
                  path='/media/icons/duotune/maps/map001.svg'
                  className='svg-icon-2 svg-icon-gray-900'/>
              </div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-primary fs-1'></i>
              </div>
              <div className='fw-mormal timeline-content fw-bold text-gray-800 ps-3'>
              {items.destination.firstname} {items.destination.lastname}<span className='text-muted fw-bold d-block fs-7'> {items.destination.adresse} , {items.destination.mobile}</span>
              </div>
            </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}
