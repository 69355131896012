/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { TablesWidgetMovementEtatProvider } from '../../../_metronic/partials/widgets/tables/TablesWidgetDashboardEtatProvider'




const CaissePage: FC = () => (
  <>
      <TablesWidgetMovementEtatProvider className='row gy-5 g-xl-8' />
  </>
)

const CaisseProviderWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <CaissePage />
    </>
  )
}

export {CaisseProviderWrapper}
