import {Dispatch, SetStateAction} from 'react'
import {UserTypes} from '../../../app/modules/apps/user-management/users-list/core/_models'

export type ID = undefined | null | number

export type PaginationState = {
  current_page: number
  per_page: number
  links?: Array<{label: string; active: boolean; url: string | null}>
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  [x: string]: any
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
  }
  per_page?: number
  current_page?: number
  links?: Array<{label: string; active: boolean; url: string | null}>
  last_page?: number
  next_page_url?: string
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export type UserQueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
  type: UserTypes
  updateType: (type: UserTypes) => void
}

export const initialQueryState: QueryState = {
  current_page: 1,
  per_page: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export const userInitialQueryRequest: UserQueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
  type: 'provider',
  updateType: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type NoUpdateListViewContextProps = {
  detailsItemId?: ID
  setDetailsItemId: Dispatch<SetStateAction<ID>>
  detailsItemType: string
  setDetailsItemType: Dispatch<SetStateAction<string>>
  searchTerm: string
  setSearchTerm: Dispatch<SetStateAction<string>>
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  isAllSelected: boolean
  disabled: boolean
  showA: boolean
  setShowA: Dispatch<SetStateAction<boolean>>
  messageA: string
  setMessageA: Dispatch<SetStateAction<string>>
}

export type ListViewContextProps = {
  searchTerm: string
  setSearchTerm: Dispatch<SetStateAction<string>>
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
  showA: boolean
  setShowA: Dispatch<SetStateAction<boolean>>
  messageA: string
  setMessageA: Dispatch<SetStateAction<string>>
}

export const initialListView: ListViewContextProps = {
  searchTerm: '',
  setSearchTerm: () => {},
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
  showA: false,
  setShowA: () => {},
  messageA: '',
  setMessageA: () => {},
}

export const noUpdateInits: NoUpdateListViewContextProps = {
  setDetailsItemId: () => {},
  detailsItemType: '',
  setDetailsItemType:() => {},
  searchTerm: '',
  setSearchTerm: () => {},
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  isAllSelected: false,
  disabled: false,
  showA: false,
  setShowA: () => {},
  messageA: '',
  setMessageA: () => {},
}

