import {Column} from 'react-table'
import {useIntl} from 'react-intl'
import { AddressBookActionsCell } from './AddressBookActionsCell'
import { AddressBookCustomHeader } from './AddressBookCustomHeader'
import { AddressBookSelectionCell} from './AddressBookSelectionCell'
import { AddressBookSelectionHeader } from './AddressBookSelectionHeader'
import { AddressBookColumn } from '../core/_models'

const AddressBookColumns: ReadonlyArray<Column<AddressBookColumn>> = [
    {
        Header: (props) => <AddressBookSelectionHeader tableProps={props} />,
        id: 'selection',
        Cell: ({...props}) => <AddressBookSelectionCell id={props.data[props.row.index].id} />,
      },
    {
        Header: (props) => (
            <AddressBookCustomHeader
                tableProps={props}
                title={useIntl().formatMessage({id: 'COLUMN.NAME'})}
                className='min-w-125px'
            />
        ),
        accessor: 'name',
        id: 'name',
    },
    {
        Header: (props) => (
            <AddressBookCustomHeader
                tableProps={props}
                title={useIntl().formatMessage({id: 'COLUMN.LABEL'})}
                className='min-w-125px'
            />
        ),
        accessor: 'label',
        id: 'label',
    },
    {
        Header: (props) => (
            <AddressBookCustomHeader
                tableProps={props}
                title={useIntl().formatMessage({id: 'COLUMN.GOVERNORATE'})}
                className='min-w-125px'
            />
        ),
        accessor: 'governorate',
        id: 'governorate',
    },
    {
        Header: (props) => (
            <AddressBookCustomHeader
                tableProps={props}
                title={useIntl().formatMessage({id: 'COLUMN.ADDRESS'})}
                className='min-w-125px'
            />
        ),
        accessor: 'address',
        id: 'address',
    },
    {
        Header: (props) => (
            <AddressBookCustomHeader
                tableProps={props}
                title={useIntl().formatMessage({id: 'COLUMN.POSTAL_CODE'})}
                className='min-w-125px'
            />
        ),
        accessor: 'postal_code',
        id: 'postal_code',
    },
    {
        Header: (props) => (
            <AddressBookCustomHeader
                tableProps={props}
                title={useIntl().formatMessage({id: 'COLUMN.CITY'})}
                className='min-w-125px'
            />
        ),
        accessor: 'city',
        id: 'city',
    },
    {
        Header: (props) => (
            <AddressBookCustomHeader
                tableProps={props}
                title={useIntl().formatMessage({id: 'COLUMN.SUPPLEMENT'})}
                className='min-w-125px'
            />
        ),
        accessor: 'supplement',
        id: 'supplement',
    },
    {
        Header: (props) => (
            <AddressBookCustomHeader
                tableProps={props}
                title={useIntl().formatMessage({id: 'COLUMN.PHONE_NUMBER'})}
                className='min-w-125px'
            />
        ),
        accessor: 'phone_number',
        id: 'phone_number',
    },
    {
        Header: (props) => (
            <AddressBookCustomHeader
                tableProps={props}
                title={useIntl().formatMessage({id: 'COLUMN.MOBILE'})}
                className='min-w-125px'
            />
        ),
        accessor: 'mobile',
        id: 'mobile',
    },
    {
        Header: (props) => (
            <AddressBookCustomHeader
                tableProps={props}
                title={useIntl().formatMessage({id: 'COLUMN.EMAIL'})}
                className='min-w-125px'
            />
        ),
        accessor: 'email',
        id: 'email',
    },
    {
        Header: (props) => (
          <AddressBookCustomHeader
            tableProps={props}
            title={useIntl().formatMessage({id: 'COLUMN.ACTIONS'})}
            className='min-w-125px'
          />
        ),
        id: 'actions',
        Cell: ({...props}) => <AddressBookActionsCell id={props.data[props.row.index].id} />,
      },
]


const AddressBookSavColumns: ReadonlyArray<Column<AddressBookColumn>> = [
    {
        Header: (props) => (
            <AddressBookCustomHeader
                tableProps={props}
                title={useIntl().formatMessage({id: 'COLUMN.CATEGORY'})}
                className='min-w-125px'
            />
        ),
        accessor: 'category',
        id: 'category',
    },
    {
        Header: (props) => (
            <AddressBookCustomHeader
                tableProps={props}
                title={useIntl().formatMessage({id: 'COLUMN.DESCRIPTION'})}
                className='min-w-125px'
            />
        ),
        accessor: 'desc',
        id: 'dsc',
    },
    {
        Header: (props) => (
            <AddressBookCustomHeader
                tableProps={props}
                title={useIntl().formatMessage({id: 'COLUMN.PRICE'})}
                className='min-w-125px'
            />
        ),
        accessor: 'price',
        id: 'price',
    },
]
export {AddressBookColumns, AddressBookSavColumns}