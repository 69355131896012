/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {ParcelQueryResponseProvider} from '../../../../app/modules/wizards/core/ParcelProviderQueryResponse'
import {ParcelQueryRequestProvider} from '../../../../app/modules/wizards/core/QueryRequestProvider'
import {
  ParcelListViewProvider,
  useListView,
} from '../../../../app/modules/wizards/core/ParcelListViewProvider'
import {EditParcel} from './components/EditParcel'
import {KTCard} from '../../../helpers'
import {ChildComponentProps, TablesBodyParcel} from '../../../../app/pages/Parcel/ParcelList/ParcelTableProvider'
import {SimpleParcelListHeader} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListHeader'
import {AlertModal} from '../../../layout/components/others/AlertModal'
import { getCurrentDateInput } from '../../../../app/pages/Parcel/ParcelList/ParcelTable'
import { getExcelParcelsProvider } from '../../../../app/modules/wizards/core/_requests'

type Props = {
  className: string
}

const TablesWidgetParcel: FC<ChildComponentProps> = ({sendStatus, sendSearch, sendStartDate, sendEndDate, sendProvider, Status, Search, StartDate, EndDate, Provider}) => {
  const {itemIdForUpdate, messageA, setMessageA, setShowA, showA} = useListView()
  return (
    <>
      <KTCard>
        <SimpleParcelListHeader sendEExcel={()=>getExcelParcelsProvider(Status, Search, StartDate, EndDate, Provider)}  isExport={true} Status={Status} Search={Search} StartDate={StartDate} EndDate={EndDate} Provider={Provider}/>
        <TablesBodyParcel sendStatus={sendStatus} sendSearch={sendSearch} sendStartDate={sendStartDate} sendEndDate={sendEndDate} sendProvider={sendProvider}/>
      </KTCard>
      {itemIdForUpdate ? <EditParcel /> : null}
      <AlertModal message={messageA} setMessage={setMessageA} show={showA} setShow={setShowA} />
    </>
  )
}

const ParcelTableListWrapperForProvider: FC<Props> = ({className}) => {

  const [Status, setStatus] = useState("")
  const [Search, setSearch] = useState("")
  const [Provider, setProvider] = useState<number>()
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const [startDate, setStartDate] = useState(getCurrentDateInput(new Date(currentYear, currentMonth, 1)))
  const [endDate, setEndDate] = useState(getCurrentDateInput(new Date()))
  const processReceivedData = (data: string) => {
    setStatus(data)
  }
  const processSearch = (data: string) => {
    setSearch(data)
  }
  const processStartDate = (data: string) => {
    setStartDate(data)
  }
  const processEndDate = (data: string) => {
    setEndDate(data)
  }

  const processProvider = (data: number) => {
    setProvider(data)
  }
  return (
    <div className={className}>
      <ParcelQueryRequestProvider>
        <ParcelQueryResponseProvider Status={Status} Search={Search} StartDate={startDate} EndDate={endDate} Provider={Provider}>
          <ParcelListViewProvider>
            <TablesWidgetParcel
            Status={Status} Search={Search} StartDate={startDate} EndDate={endDate} Provider={Provider}
            sendStatus={processReceivedData} sendSearch={processSearch} sendStartDate={processStartDate} sendEndDate={processEndDate} sendProvider={processProvider}/>
          </ParcelListViewProvider>
        </ParcelQueryResponseProvider>
      </ParcelQueryRequestProvider>
    </div>
  )
}

export {ParcelTableListWrapperForProvider}
