import {FC, SetStateAction, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {ColumnInstance, Row, useTable} from 'react-table'
import {KTCardBody, KTSVG} from '../../../../_metronic/helpers'
import {ListLoading} from '../../../../_metronic/helpers/components/loading/ListLoading'
import {ListPagination} from '../../../../_metronic/helpers/components/pagination/ListPagination'
import {useQueryRequest} from '../../../modules/wizards/core/QueryRequestProvider'
import {ChildComponentProps} from '../../../../_metronic/helpers'
import { useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination } from '../core/QueryResponseProvider'
import { paymentColumns } from '../columns/column'
import { PaymentCustomHeader } from '../columns/CustomHeaderColumn'
import { payment } from '../core/_models'
import { PaymentCustomHeaderColumn } from '../columns/PaymentCustomHeaderColumn'
import { PaymentCustomRow } from '../columns/CustomRow'


interface Statut {
  name:string
  }

function getCurrentDateInput  (dateObj: Date = new Date())  {
  // get the month in this format of 04, the same for months
  const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  const day = ("0" + dateObj.getDate()).slice(-2);
  const year = dateObj.getFullYear();

  const shortDate = `${year}-${month}-${day}`;

  return shortDate;
};
const status: Statut[] = [{name:'pending'},{name:'processing'},{name:'completed'},{name:'canceled'},{name:'refunded'},];

const TablesBodyPayment : FC<ChildComponentProps> = ({sendSearch, sendStartDate, sendEndDate}) => {

  
  const [searchTerm, setSearchTerm] = useState<string>('')
  const payments = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => payments, [payments])
  const columns = useMemo(() => paymentColumns, [])
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.getMonth();
  const [startDate, setStartDate] = useState(getCurrentDateInput(new Date(currentYear, currentMonth, 1)));
  const [endDate, setEndDate] = useState(getCurrentDateInput(new Date()));
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  console.log(payments)

  const handleStartDateChange = (event: { target: { value: SetStateAction<string> } }) => {
    setStartDate(event.target.value);
  };
  const handleEndDateChange = (event: { target: { value: SetStateAction<string> } }) => {
    setEndDate(event.target.value);
  };


  const intl = useIntl()

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }
  const handleSendData = () => {
    sendSearch?.(searchTerm)
    sendStartDate?.(startDate)
    sendEndDate?.(endDate)
  };
  

  return (
    <KTCardBody className='py-4'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          className='form-control form-control-solid w-250px ps-14'
          onChange={handleSearchChange}
          value={searchTerm}
        />
        
        <input
        type="date"
        id="startDate"
        className='form-control form-control-solid w-250px ps-14 m-4'
        value={startDate}
        onChange={handleStartDateChange}
      />
      <input
        type="date"
        id="endDate"
        className='form-control form-control-solid w-250px ps-14 m-4'
        value={endDate}
        onChange={handleEndDateChange}
        />
        <button type="button" className ="btn btn-primary" onClick={handleSendData}>Rechercher</button>
      </div>
      {/* end::Search */}
      <div className='table-responsive'>
        <table
          id='kt_table_parcels'
          className='table table-striped align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<payment>) => (
                <PaymentCustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className=' fs-5 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows
                .map((row: Row<payment>, i) => {
                  prepareRow(row)
                  return <PaymentCustomRow row={row} key={`row-${i}-${row.id}`} />
                })
            ) : (
              <tr>
                <td colSpan={20}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({id: 'TABLE.NO_ITEMS_FOUND'})}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ListPagination
        useQueryResponseLoading={useQueryResponseLoading}
        useQueryResponsePagination={useQueryResponsePagination}
        useQueryRequest={useQueryRequest}
        item='parcels'
      />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export {TablesBodyPayment, getCurrentDateInput}
