import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'


const Unauthorized: FC = () => {
    const navigate = useNavigate()

    const goBack = () => {
        navigate(-1)
    }

    return (
        <div className='text-center mb-5'>
            <img src={toAbsoluteUrl('/media/auth/401-error-unauthorized.png')} alt='Unauthorized' />
            <div>
                <button className='btn btn-primary' onClick={goBack}>Go Back</button>
            </div>
        </div>
    )
}

export { Unauthorized }