import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Truck, TruckAllQueryResponse, TruckQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const PRICING_URL = `${API_URL}/truck`

const getTrucks = (): Promise<TruckAllQueryResponse> => {
  return axios
    .get(`${PRICING_URL}`)
    .then((response: AxiosResponse<TruckAllQueryResponse>) => response.data)
}

const getPaginationTrucks = (query?: string, search?: string): Promise<TruckQueryResponse> => {
  return axios
    .get(`${PRICING_URL}/page?${query}`, { params: {search: search} })
    .then((response: AxiosResponse<TruckQueryResponse>) => response.data)
}

const getTruckById = (id: ID): Promise<Truck | undefined | void> => {
  return axios.get(`${PRICING_URL}/${id}`).then((response: AxiosResponse<Truck>) => response.data)
}

const createTruck = (truck: Truck): Promise<Truck | undefined | void> => {
  return axios
    .post(PRICING_URL, truck)
    .then((response: AxiosResponse<Response<Truck>>) => response.data)
    .then((response: Response<Truck>) => response.data)
}

const updateTruck = (truck: Truck): Promise<Truck | undefined | void> => {
  return axios
    .post(`${PRICING_URL}/${truck.id}/update`, truck)
    .then((response: AxiosResponse<Response<Truck>>) => response.data)
    .then((response: Response<Truck>) => response.data)
}

const deleteTruck = (id: ID): Promise<Truck | undefined | void> => {
  return axios
    .post(`${PRICING_URL}/${id}/delete`)
    .then((response: AxiosResponse<Response<Truck>>) => response.data)
    .then((response: Response<Truck>) => response.data)
}

const deleteSelectedTrucks = (ids: ID[]): Promise<Truck | undefined | any> => {
  const requests = ids.map((id: ID) => deleteTruck(id))
  return axios.all(requests)
}

export {
  getTrucks,
  getPaginationTrucks,
  getTruckById,
  createTruck,
  updateTruck,
  deleteTruck,
  deleteSelectedTrucks,
}