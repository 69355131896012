/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PositionResponse} from '../core/_models'
import {TrackingList} from './TrackingList'

type Props = {
  className: string
  items?: PositionResponse
}

const TrackingWidget: FC<Props> = ({className, items}) => {
  const intl = useIntl()
  return (
    <div>
      {items ? (
        <TrackingList
          className={className}
          header={intl.formatMessage({id: 'GENERAL.TRACKING'})}
          items={items}
        />
      ) : null}
    </div>
  )
}

export {TrackingWidget}
