/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Field} from 'formik'
import {ParcelPersonModel} from '../../core/_models'
import {useIntl} from 'react-intl'
import {GetTranslate} from '../../../../../_metronic/helpers'
import {RelayPoint} from '../../../apps/RelayPoints/core/_models'
import {getRelayPointById} from '../../../apps/RelayPoints/core/_requests'
import {governorates} from '../../../../../_data'
import Select from 'react-select'
import {Options} from '../../../apps/mouvement/core/_models'

type Props = {
  user?: ParcelPersonModel
  loading?: boolean
  formik?: any
  data?: RelayPoint[]
  IsSelect?: boolean
}

const DeliveryAddress: FC<Props> = ({user, formik, loading, data, IsSelect}) => {
  const [RelayPointId, setRelayPointId] = useState<string | undefined>(undefined)
  const [selectedRPData, setSelectedRPData] = useState<RelayPoint | undefined>(undefined)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [options, setOptions] = useState<any>([])

  const getSelectedRPData = async (id: string) => {
    await getRelayPointById(+id).then((res) => {
      setSelectedRPData(res)
    })
  }

  useEffect(() => {
    if (selectedRPData && RelayPointId && RelayPointId !== '') {
      formik.setFieldValue('receiver.postal_code', selectedRPData?.postal_code)
      formik.setFieldValue('receiver.city', selectedRPData?.city)
      formik.setFieldValue('receiver.supplement', selectedRPData?.supplement)
      formik.setFieldValue('receiver.adresse', selectedRPData?.address)
      formik.setFieldValue('receiver.governorate', selectedRPData?.governorate)
      formik.setFieldValue('receiver.mobile', selectedRPData?.mobile)
      formik.setFieldValue('receiver.email', selectedRPData?.email)
      formik.setFieldValue('receiver.firstname', selectedRPData?.name)
      formik.setFieldValue('receiver.lastname', selectedRPData?.label)
      setDisabled(true)
    } else if (!user) {
      formik.setFieldValue('receiver.postal_code', '')
      formik.setFieldValue('receiver.city', '')
      formik.setFieldValue('receiver.supplement', '')
      formik.setFieldValue('receiver.adresse', '')
      formik.setFieldValue('receiver.governorate', '')
      setDisabled(false)
    }
  }, [selectedRPData, RelayPointId])

  useEffect(() => {
    const options: Options = []
    data?.map((item) =>
      options.push({
        value: item.id!,
        label: item.label + ' - '+ item.name+',  '+item.postal_code ,
      })
    )
    setOptions(options)
  }, [data])

  useEffect(() => {
    RelayPointId && getSelectedRPData(RelayPointId)
  }, [RelayPointId])
  const intl = useIntl()
  return (
    <div className='w-100 card' id='deliveryAddress'>
      <div className='card-header'>
        <h3 className='card-title'>{intl.formatMessage({id: 'PARCEL.DELIVERY_ADDRESS'})}</h3>
        {!IsSelect &&
        <Select
          className='form-control form-control-solid w-50'
          styles={{
            control: (provided) => ({
              ...provided,
              border: 0,
              boxShadow: 'none',
              backgroundColor: 'transparent',
            }),
          }}
          options={options}
          hideSelectedOptions={false}
          name='parcel_id'
          onChange={(e: any) => setRelayPointId(e?.value)}
          isDisabled={loading}
        />}
      </div>
      <div className='card-body'>
        <div className='row mb-1'>
          <div className='col-lg-4'>
            <div className='fv-row mb-0'>
              <label className='d-inline overflow-hidden col-lg-3 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'COLUMN.FIRST_NAME'})}
              </label>
              <Field
                type='text'
                className='form-control formp-control-lg form-control-white'
                name='receiver.firstname'
                onChange={formik.handleChange}
                defaultValue={user?.firstname}
                disabled={loading}
              />
              <div className='text-danger mt-2'>
                {formik?.touched?.receiver?.firstname &&
                  formik.errors.receiver?.firstname &&
                  GetTranslate(formik.errors.receiver?.firstname)}
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='fv-row mb-0'>
              <label className='d-inline overflow-hidden col-lg-6 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'COLUMN.LAST_NAME'})}
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-white'
                name='receiver.lastname'
                onChange={formik.handleChange}
                defaultValue={user?.lastname}
                disabled={loading}
              />
              <div className='text-danger mt-2'>
                {formik?.touched?.receiver?.lastname &&
                  formik.errors.receiver?.lastname &&
                  GetTranslate(formik.errors.receiver?.lastname)}
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='fv-row mb-0'>
              <label className='d-inline overflow-hidden col-lg-3 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'USER.GOVERNORATE'})}
              </label>
              <Field
                as='select'
                className='form-select form-control-lg form-control-white'
                name='receiver.governorate'
                onChange={formik.handleChange}
                defaultValue={user?.governorate}
                disabled={true || loading}
              >
                <option value=''>
                  {intl.formatMessage({id: 'RELAY_POINT.SELECT_GOVERNORATE'})}
                </option>
                {governorates.map((item) => (
                  <option key={item.id} value={item.name}>
                     {item.name}
                  </option>
                ))}
              </Field>
              <div className='text-danger mt-2'>
                {formik?.touched?.receiver?.governorate &&
                  formik.errors.receiver?.governorate &&
                  GetTranslate(formik.errors.receiver?.governorate)}
              </div>
            </div>
          </div>
        </div>

        {/* Ligne 2 */}
        <div className='row mb-1'>
          <div className='col-lg-4'>
            <div className='fv-row mb-0'>
              <label className='d-inline overflow-hidden col-lg-10 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'USER.POSTAL_CODE'})}
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-white'
                name='receiver.postal_code'
                onChange={formik.handleChange}
                defaultValue={user?.postal_code}
                disabled={true || loading}
              />
              <div className='text-danger mt-2'>
                {formik?.touched?.receiver?.postal_code &&
                  formik.errors.receiver?.postal_code &&
                  GetTranslate(formik.errors.receiver?.postal_code)}
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='fv-row mb-0'>
              <label className='d-inline overflow-hidden  col-lg-6 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'USER.CITY'})}
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-white'
                name='receiver.city'
                onChange={formik.handleChange}
                defaultValue={user?.city}
                disabled={true || loading}
              />
              <div className='text-danger mt-2'>
                {formik?.touched?.receiver?.city &&
                  formik.errors.receiver?.city &&
                  GetTranslate(formik.errors.receiver?.city)}
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='fv-row mb-0'>
              <label className='required d-inline overflow-hidden col-lg-10 col-form-label  fw-bold fs-6'>
                {intl.formatMessage({id: 'COLUMN.MOBILEPHONE'})}
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-white'
                name='receiver.mobile'
                onChange={formik.handleChange}
                defaultValue={user?.mobile}
                disabled={loading}
              />
              <div className='text-danger mt-2'>
                {formik?.touched.receiver?.mobile &&
                  formik?.errors.receiver?.mobile &&
                  GetTranslate(formik.errors.receiver?.mobile)}
              </div>
            </div>
          </div>
        </div>
        {/* third line  */}
        <div className='row mb-1'>
          <div className='col-lg-6'>
            <div className='fv-row mb-0'>
              <label className='d-inline overflow-hidden col-lg-3 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'USER.ADDRESS'})}
              </label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-white'
                name='receiver.adresse'
                onChange={formik.handleChange}
                defaultValue={user?.adresse}
                disabled={true || loading}
              />
              <div className='text-danger mt-2'>
                {formik?.touched?.receiver?.adresse &&
                  formik.errors.receiver?.adresse &&
                  GetTranslate(formik.errors.receiver?.adresse)}
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <label className='d-inline overflow-hidden  col-lg-6 col-form-label fw-bold fs-6'>
              {intl.formatMessage({id: 'COLUMN.HOMEPHONE'})}
            </label>
            <Field
              type='text'
              className='form-control form-control-lg form-control-white'
              name='receiver.phone_number'
              onChange={formik.handleChange}
              defaultValue={user?.phone_number}
              disabled={loading}
            />
            <div className='text-danger mt-2'>
              {formik?.touched?.receiver?.phone_number &&
                formik.errors.receiver?.phone_number &&
                GetTranslate(formik.errors.receiver?.phone_number)}
            </div>
          </div>
        </div>
        <div className='row mb-1'>
          <div className='col-lg-6'>
            <label className='d-inline overflow-hidden  col-lg-10 col-form-label fw-bold fs-6'>
              {intl.formatMessage({id: 'COLUMN.EMAIL'})}
            </label>
            <Field
              type='text'
              className='form-control form-control-lg form-control-white'
              name='receiver.email'
              onChange={formik.handleChange}
              defaultValue={user?.email}
              disabled={loading}
            />
            <div className='text-danger mt-2'>
              {formik?.touched?.receiver?.email &&
                formik.errors.receiver?.email &&
                GetTranslate(formik.errors.receiver?.email)}
            </div>
          </div>
          <div className='col-lg-6'>
            <label className='d-inline overflow-hidden  col-lg-6 col-form-label fw-bold fs-6'>
              {intl.formatMessage({id: 'USER.SUPPLEMENT'})}
            </label>
            <Field
              type='text'
              className='form-control form-control-lg form-control-white'
              name='receiver.supplement'
              onChange={formik.handleChange}
              defaultValue={user?.supplement}
              disabled={loading}
            />
            <div className='text-danger mt-2'>
              {formik?.touched?.receiver?.supplement &&
                formik.errors.receiver?.supplement &&
                GetTranslate(formik.errors.receiver?.supplement)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {DeliveryAddress}
