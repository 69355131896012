import {useIntl} from 'react-intl'
import { AddressBookListToolbar } from '../components/AddressBookListToolbar'
import { AddressBookListGrouping } from '../components/AddressBookListGrouping'
import { AddressBookUseListView } from '../core/ListViewProvider'
import { WithChildren } from '../../../../../_metronic/helpers'
import { FC } from 'react'

const AddressBookListHeader : FC<WithChildren> = ({savHeader}) => {
    const {selected} = AddressBookUseListView()
    return (
        <div className='card-header border-0 pt-6'>
        <div className='card-title'>
            <h3 className='card-label'>{useIntl().formatMessage({id: 'MENU.ADDRESSES_LIST'})}</h3>
        </div>
        <div className='card-toolbar'>
            {selected.length > 0 ? <AddressBookListGrouping /> : <AddressBookListToolbar savHeader={savHeader} />}
        </div>
        </div>
    )
    }

export {AddressBookListHeader}