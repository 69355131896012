
import {forwardRef, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {Truck} from '../../../pages/Truck/core/_models'
import {getPaginationTrucks, getTrucks} from '../../../pages/Truck/core/_requests'
import {Parcel} from '../../wizards/core/_models'
import {getTransferParcels} from '../../wizards/core/_requests'
import {RelayPoint} from '../RelayPoints/core/_models'

import {
  getPaginationRelayPointDestination,
  getPaginationRelayPoints,
  getRelayPointById,
} from '../RelayPoints/core/_requests'
import {User} from '../user-management/users-list/core/_models'
import {getDriver, getPaginationDriver} from '../user-management/users-list/core/_requests'
import {Movement, movementInitial, MovementSchema, MovementType, Options} from './core/_models'
import Select, {components} from 'react-select'
import {createMovement} from './core/_requests'
import {AlertModal} from '../../../../_metronic/layout/components/others/AlertModal'
import {useAuth} from '../../auth'
import { useNavigate } from "react-router-dom"
import { useFormik } from 'formik'
import { GetTranslate } from '../../../../_metronic/helpers'







const MovementForm = forwardRef((props, ref) => {
  const [parcels, setParcels] = useState<Parcel[]>([])
  const [relayPoints, setRelayPoints] = useState<RelayPoint[]>([] as RelayPoint[])
  const [destRelayPoints, setDestRelayPoints] = useState<RelayPoint[]>([] as RelayPoint[])
  const [relayPoint, setRelayPoint] = useState<RelayPoint>({} as RelayPoint)
  const [trucks, setTrucks] = useState<Truck[]>([])
  const [drivers, setDrivers] = useState<User[]>([])
  const [options, setOptions] = useState<any>([])
  const [message, setMessage] = useState<string>('')
  const [show, setShow] = useState<boolean>(false)
  const [values, setValues] = useState<Movement>(movementInitial)
  const [searchTruck, setSearchTruck] = useState<string>('')
  const [searchDriver, setSearchDriver] = useState<string>('')
  const [searchRelaySr, setSearchRelaySr] = useState<string>('')
  const [searchRelayDest, setSearchRelayDest] = useState<string>('')

  const {currentUser} = useAuth()
 

console.log(currentUser)


  useEffect(() => {
    const getAllRelayPoints = async () => {     
        const response = await getPaginationRelayPoints(undefined, searchRelaySr)
        response?
          setRelayPoints(response.data as RelayPoint[]):
          setRelayPoints([])     
    }

    getAllRelayPoints()
  }, [searchRelaySr])


  useEffect(() => {
    const getDestRelayPoints = async () => {
        const response = await getPaginationRelayPointDestination(undefined, searchRelayDest)   
        response?    
          setDestRelayPoints(response as RelayPoint[]):
          setDestRelayPoints([])         
    }

    getDestRelayPoints()
  }, [searchRelayDest])
  


    useEffect(() => {
      const getAllDrivers = async () => {       
          const response = await getPaginationDriver(undefined, searchDriver)
         response? setDrivers(response.data as User[]):       
          setDrivers([])
        }  
      getAllDrivers()
    }, [searchDriver])


    useEffect(() => {
      const getAllTrucks = async () => {
          const response = await getPaginationTrucks(undefined,searchTruck)
          response? setTrucks(response.data as Truck[]):        
          setTrucks([])    
      }
      getAllTrucks()
    }, [searchTruck])




useEffect(() => {
      const getThisRelayPointById = async (id: number) => {
        const response = await getRelayPointById(id)
        response? setRelayPoint(response as RelayPoint):
            setRelayPoint({} as RelayPoint)      
      }
      getThisRelayPointById(currentUser?.rp_id!)
    }, [values.rp_src_id])

 


    useEffect(() => {
      const getAllParcels = async () => {
          const response = await getTransferParcels()
          response?setParcels(response as Parcel[]):
           setParcels([])        
      }
  
      getAllParcels()
    }, [])



  const InputOption = 
  ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }: any) => {
    const [isActive, setIsActive] = useState(false)
    const onMouseDown = () => setIsActive(true)
    const onMouseUp = () => setIsActive(false)
    const onMouseLeave = () => setIsActive(false)

    let bg = 'transparent'
    if (isFocused) bg = '#eee'
    if (isActive) bg = '#B2D4FF'

    const style = {
      alignItems: 'center',
      backgroundColor: bg,
      color: 'inherit',
      display: 'flex ',
    }
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    }
    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type='checkbox' checked={isSelected} />
        {children}
      </components.Option>
    )
  }

  const navigate = useNavigate()
  const goTolist = () => {navigate('/movement/list')}
  useEffect(() => {
    if(values.type==='d'){
      formik.setValues({
        ...formik.values,
        truck_id: trucks[0]?.id!,
        driver_id: drivers[0]?.id!,
        rp_src_id: relayPoint?.id,
        rp_dest_id: relayPoint?.id!,
        type:'d',
        agent_id: currentUser?.id!
      })}
  }, [values.type, trucks, drivers, relayPoint])


  const formik = useFormik({
    initialValues: movementInitial,
    validationSchema: MovementSchema,

    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      if(currentUser?.type==='agent' && values.type ==='d') {
        if (values && values.parcel_id && values.parcel_id.length > 0) {
          await createMovement({...values,  agent_id: currentUser?.id!})
            .then((res) => {
            setShow(true)

            setMessage(intl.formatMessage({id: 'MESSAGES.MOVEMENT_CREATED'}))
            return  goTolist()
            })
            .catch((error) => {
              setShow(true)

              setMessage(error.response.data.message)
              
            })
        } else {
          setShow(true)
          setMessage(intl.formatMessage({id: 'MESSAGES.REQUIRED_FIELDS_MISSING'}))
        }
   }
   else {
      await createMovement({...values,  agent_id: currentUser?.id!, rp_src_id: relayPoint?.id!})     
        .then(() => {
        setShow(true)
          setMessage(intl.formatMessage({id: 'MESSAGES.MOVEMENT_CREATED'}))
        return  goTolist()
        })
        .catch((error) => {
          setShow(true)
          console.log(values)
          setMessage(error.response.data.message)
        })
    
  }
    },
  })



  useEffect(() => {
    const options: Options = []
    if (Array.isArray(parcels)) {
      parcels.map((parcel) =>
        options.push({
          value: parcel.id!,
          label: parcel?.relay===null?parcel.tracking_number +' - '+ parcel.sender?.firstname:parcel.tracking_number +' - '+ parcel.sender?.firstname+' - '+ parcel?.relay ,
        })
      )
      setOptions(options)
    }
  }, [parcels])
  const intl = useIntl()


  const Options = () => {

    useEffect(() => {
      if ((currentUser?.roles.length===0) && (currentUser?.type==='agent')  && values.type !=='d' ) {
        setValues({ ...values, type: 'd' })
      }
    }, [values])
  
    
    return (
    ( (currentUser?.roles[0]?.name=='depot_manager') || currentUser?.type === 'controle' || currentUser?.type === 'admin'   )  ? 


      <div className='radio-inline' id='radioSelect'>          
        <div className='form-check form-check-inline'>
          <input
            type='radio'
            className='form-check-input'
            id='it'
            onChange={(e) => {
              setValues({...values, type: e.target.value as MovementType})
            }}
            name='type'
            value='it'
            checked={values.type === 'it'}
          />
     
          <label htmlFor='it' className='form-check-label'>
            {intl.formatMessage({id: 'MOVEMENT.INTERNAL_TRANSFER'})}
          </label>
        </div>  
        
        <div className='form-check form-check-inline'>
        <input
  type='radio'
  className='form-check-input'
  id='d'
  onChange={(e) => {
    setValues({...values, type: e.target.value as MovementType});
  }}
  name='type'
  value='d'
  checked={values.type === 'd'}
/>
           
          <label htmlFor='d' className='form-check-label'>
            {intl.formatMessage({id: 'MOVEMENT.DELIVERY'})}
          </label>
        </div>
        <div className='form-check form-check-inline'>

          <input
            type='radio'
            className='form-check-input'
            id='p'
            onChange={(e) => setValues({...values, type: e.target.value as MovementType})}
            name='type'
            value='p'
            checked={values.type === 'p'}
          />
         
          <label htmlFor='p' className='form-check-label'>
            {intl.formatMessage({id: 'MOVEMENT.PICKUP'})}
          </label>
        </div>
       
      </div>
      
      : <div className='radio-inline' id='radioSelect'> 
      <div className='form-check form-check-inline'>
        <input
          type='radio'
          className='form-check-input'
          id='d'
          name='type'
          value='d'
          checked={values.type === 'd'}
          readOnly 
        />
        <label htmlFor='d' className='form-check-label'>
          {intl.formatMessage({id: 'MOVEMENT.DELIVERY'})}
        </label>
      </div>
    </div>
    )     

  }



  return (
    <div className='card mb-5 mb-xl-10'>
      <AlertModal message={message} setMessage={setMessage} show={show} setShow={setShow} />
      <div className='card-header'>
        <h3 className='card-title'>{intl.formatMessage({id: 'MENU.CREATE_PARCEL_MOVEMENT'})}</h3>
      </div>
      <div className='card-body'>
      <form
        id='kt_modal_add_address_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
          <div className='row m-4'>
            <div className='col-lg-12'>
              <div className='text-center mb-2 border rounded p-3'>
                <label htmlFor='radioSelect' className='fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'MOVEMENT.SELECT_MOVEMENT_TYPE'})}
                </label>  
                <Options/>
              </div>
            </div>
          </div>
          <div className='row m-4'>
            <div className='col-lg-12'>
              <div className='form-group'>
                <label className='required fw-bold fs-6'>
                  {intl.formatMessage({id: 'MOVEMENT.SELECT_PARCEL'})}
                </label>
                {values.type === 'it' ? (
                  <Select
                    className='form-control'
                    placeholder={intl.formatMessage({id: 'MOVEMENT.SELECT_PARCEL'})}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: 'none',
                        backgroundColor: 'transparent',
                      }),
                      multiValueRemove: (provided) => ({
                        ...provided,
                        ':hover': {
                          backgroundColor: '#0D6EFD',
                          color: 'white',
                        },
                      }),
                    }}
                    defaultValue={[]}
                    options={options}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    name='parcel_id'
                    components={{
                      Option: InputOption,
                    }}
                    onChange={(e) => {
                      const parcelIds: number[] = []
                      e?.map((parcel: {value: number}) => parcelIds.push(parcel.value.valueOf()))
                      setValues({...values, parcel_id: parcelIds})
                      formik.setValues({
                        ...formik.values,
                        parcel_id: parcelIds
                      })
                    }}
                  />
                ) : (
                  <Select
                  className='form-select'
                  name='parcel_id'
                  options={parcels.map((parcel) => ({ value: parcel.id, label: parcel.tracking_number }))}
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption ? selectedOption.value : undefined;
                    setValues({
                      ...values,
                      parcel_id: selectedValue !== undefined ? [selectedValue] : [],
                    });
                    const selectedParcel = parcels.find((parcel) => parcel.id === selectedValue);
                    formik.setValues({
                      ...formik.values,
                      parcel_id: selectedValue !== undefined ? [selectedValue] : [],
                    });
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: 'none',
                      backgroundColor: 'transparent',
                    }),
                    multiValueRemove: (provided) => ({
                      ...provided,
                      ':hover': {
                        backgroundColor: '#0D6EFD',
                        color: 'white',
                      },
                    }),
                  }}
                  placeholder={intl.formatMessage({ id: 'MOVEMENT.SELECT_PARCEL' })}
                />
                

                )}
              </div>
              {values && values.parcel_id && !values.parcel_id.length && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>
                      {intl.formatMessage({id: 'MESSAGES.REQUIRED_FIELDS_MISSING'})}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {
           values.type !== 'd' && (
          <div className='row m-4'> 
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='required fw-bold fs-6'>
                  {intl.formatMessage({id: 'MOVEMENT.SELECT_TRUCK'})}
                </label>

                <Select
                    className='form-control'
                    placeholder={intl.formatMessage({id: 'MOVEMENT.SELECT_TRUCK'})}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: 'none',
                        backgroundColor: 'transparent',
                      }),
                      multiValueRemove: (provided) => ({
                        ...provided,
                        ':hover': {
                          backgroundColor: '#0D6EFD',
                          color: 'white',
                        },
                      }),
                    }}
                    options={trucks.map((truck) => ({ value: truck.id, label: truck.truck_number }))}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option: InputOption,
                    }}
                    onInputChange={(ChangedValue) => {
                      setSearchTruck(ChangedValue)
                     
                      }}

                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption ? selectedOption.value : undefined
                      setValues({ ...values, truck_id: selectedValue ! })
                      formik.setValues({
                        ...formik.values,
                        truck_id: selectedValue,
                      })
                      }}
                 
                     
                  />

             <div className='text-danger mt-2'>
                  {formik?.touched?.truck_id &&
                    formik.errors.truck_id &&
                    GetTranslate(formik.errors.truck_id)}
                </div>


              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='required fw-bold fs-6'>
                  {intl.formatMessage({id: 'MOVEMENT.SELECT_DRIVER'})}
                </label>

                <Select
                    className='form-control'
                    placeholder={intl.formatMessage({id: 'MOVEMENT.SELECT_DRIVER'})}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: 'none',
                        backgroundColor: 'transparent',
                      }),
                      multiValueRemove: (provided) => ({
                        ...provided,
                        ':hover': {
                          backgroundColor: '#0D6EFD',
                          color: 'white',
                        },
                      }),
                    }}
                    options={drivers.map((driver) => ({ value: driver.id, label: driver.firstname + ' ' + driver.lastname }))}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option: InputOption,
                    }}
                    onInputChange={(ChangedValue) => {
                      setSearchDriver(ChangedValue)
                      
                      }}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption ? selectedOption.value : undefined
                      setValues({ ...values, driver_id: selectedValue ! })
                      formik.setValues({
                        ...formik.values,
                        driver_id: selectedValue
                      })
                      }}
                      
                  />

               <div className='text-danger mt-2'>
                  {formik?.touched?.driver_id &&
                    formik.errors.driver_id &&
                    GetTranslate(formik.errors.driver_id)}
                </div>
              </div>
            </div>
          </div>
          )}
        
          <div className='row m-4'>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='required fw-bold fs-6'>
                  {intl.formatMessage({id: 'MOVEMENT.SELECT_SOURCE_ADDRESS'})}
                </label>
                {relayPoint && currentUser?.type !== 'admin'  ? (
                  <input type='text' className='form-control p-5' value={relayPoint.name} disabled />
                ) 
                : (
                
                  <Select
                  className='form-control'
                  placeholder={intl.formatMessage({id: 'MOVEMENT.SELECT_SOURCE_ADDRESS'})}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: 'none',
                      backgroundColor: 'transparent',
                    }),
                    multiValueRemove: (provided) => ({
                      ...provided,
                      ':hover': {
                        backgroundColor: '#0D6EFD',
                        color: 'white',
                      },
                    }),
                  }} 
                  options={relayPoints.map((relayPoint) => ({ value: relayPoint.id, label: relayPoint.name }))}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option: InputOption,
                  }}
                  onInputChange={(ChangedValue) => {
                    setSearchRelaySr(ChangedValue)
                   
                    }}
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption ? selectedOption.value : undefined
                    setValues({ ...values, rp_src_id: selectedValue ! })
                    }}
                />
                )}
              </div>
            </div>
            {values.type === 'it' ? (
              <div className='col-lg-6'>
                <div className='form-group'>
                  <label className='required fw-bold fs-6'>
                    {intl.formatMessage({id: 'MOVEMENT.SELECT_DESTINATION_RELAY_POINT'})}
                  </label>
                  <Select
                  className='form-control'
                  placeholder={intl.formatMessage({id: 'MOVEMENT.SELECT_DESTINATION_RELAY_POINT'})}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: 'none',
                      backgroundColor: 'transparent',
                    }),
                    multiValueRemove: (provided) => ({
                      ...provided,
                      ':hover': {
                        backgroundColor: '#0D6EFD',
                        color: 'white',
                      },
                    }),
                  }}
                  options={destRelayPoints.map((relayPoint) => ({ value: relayPoint.id, label: relayPoint.name + ' - ' + relayPoint.label}))}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option: InputOption,
                  }}
                  onInputChange={(ChangedValue) => {
                    setSearchRelayDest(ChangedValue)                   
                   }}
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption ? selectedOption.value : undefined
                    formik.setValues({
                      ...formik.values,
                      rp_dest_id: selectedValue
                    })
                    }}
                  
                />

              <div className='text-danger mt-2'>
                  {formik?.touched?.rp_dest_id &&
                    formik.errors.rp_dest_id &&
                    GetTranslate(formik.errors.rp_dest_id)}
                </div>
                </div>
              </div>
            ) : values.type === 'p' ? (
              <div className='col-lg-6'>
                <div className='form-group'>
                  <label className=' fw-bold fs-6'>
                    {intl.formatMessage({id: 'MOVEMENT.SENDER_NAME'})}
                  </label>
                  <input
                    type='text'
                    className='form-control p-5'
                    value={
                      values.parcel_id.length > 0
                        ? parcels?.find((parcel) => parcel.id === values.parcel_id[0])?.sender
                            ?.firstname +
                          ' ' +
                          parcels?.find((parcel) => parcel.id === values.parcel_id[0])?.sender
                            ?.lastname +
                          ' - ' +
                          parcels?.find((parcel) => parcel.id === values.parcel_id[0])?.sender
                            ?.adresse
                        : ''
                    }
                    
                    disabled
                  />
                </div>
              </div>
            ) : (
              values.type === 'd' && (
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className=' fw-bold fs-6'>
                      {intl.formatMessage({id: 'MOVEMENT.RECEIVER_NAME'})}
                    </label>
                    <input
                      type='text'
                      className='form-control p-5'
                      value={
                        values.parcel_id.length > 0
                          ? parcels?.find((parcel) => parcel.id === values.parcel_id[0])?.receiver
                              ?.firstname +
                            ' ' +
                            parcels?.find((parcel) => parcel.id === values.parcel_id[0])?.receiver
                              ?.lastname
                          : ''
                      }
                      disabled
                    />
                  </div>
                </div>
              )
            )}
          </div>         
          <div className='d-flex justify-content-end m-8'>
            <button type='submit' className='btn btn-primary' >
           {intl.formatMessage({id: 'GENERAL.SUBMIT'})} 
            </button>
          </div>
        </form>
      </div>
    </div>
   )
  })
  
  const MovementAdd = () => {
    const componentRef = useRef()
  
    return (
      <div>
        <MovementForm ref={componentRef} />
    
      </div>
    )
  }
  
  export default MovementAdd


