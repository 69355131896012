import { Column } from 'react-table'
import { useIntl } from 'react-intl'
import { Truck } from '../core/_models'
import { TruckSelectionHeader } from './TruckSelectionHeader'
import { TruckSelectionCell } from './TruckSelectionCell'
import { TruckCustomHeader } from './TruckCustomHeader'
import { TruckActionsCell } from './TruckActionsCell'
import {useAuth} from '../../../modules/auth'


const Users=['provider', 'admin']

const trucksColumns: ReadonlyArray<Column<Truck>> = [
    {
        Header: (props) => Users.includes(String(useAuth().currentUser?.type))?<TruckSelectionHeader tableProps={props} />:<div></div>,
        id: 'selection',
        Cell: ({...props}) => Users.includes(String(useAuth().currentUser?.type))?<TruckSelectionCell id={props.data[props.row.index].id} />:<div></div> ,
    },
    {
        Header: (props) => (
            <TruckCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "TRUCK.TYPE" })} className='min-w-125px' />
        ),
        accessor: 'type_truck',
        id: 'type_truck',
    },
    {
        Header: (props) => (
            <TruckCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "TRUCK.CODE" })} className='min-w-125px' />
        ),
        accessor: 'truck_code',
        id: 'truck_code',
    },
    {
        Header: (props) => (
            <TruckCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "TRUCK.NUMBER" })} className='min-w-125px' />
        ),
        accessor: 'truck_number',
        id: 'truck_number',
    },
    {
        Header: (props) =>Users.includes(String(useAuth().currentUser?.type))?
        (
            <TruckCustomHeader tableProps={props} title={'actes'} className='text-end min-w-100px ' />
        )
        :<div></div> ,
        id: 'actions',
        Cell: ({...props}) =>Users.includes(String(useAuth().currentUser?.type))?<TruckActionsCell id={props.data[props.row.index].id} />: <div></div>
    },
]

export { trucksColumns }