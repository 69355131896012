import axios, {AxiosResponse} from 'axios'
import {PositionResponse, PositionRequest} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const TRACKING_URL = `${API_URL}/parcel/number`

const getTracking = (trackingNumber: PositionRequest): Promise<PositionResponse> => {
  return axios
    .get(TRACKING_URL, {
      params: {
        tracking_number: trackingNumber.trackingNumber,
      },
    })
    .then((response: AxiosResponse<PositionResponse>) => response.data)
}


export {getTracking}
