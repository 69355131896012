/* eslint-disable jsx-a11y/anchor-is-valid */
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import Select from 'react-select'

import React, { FC, useState, useRef, useEffect, SetStateAction } from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { ColumnInstance, Row, useTable, useSortBy } from 'react-table'

import { KTCard, KTCardBody, KTSVG, toAbsoluteUrl } from '../../../helpers'
import { MovementsColumns, MovementsColumnsDeleveryProvider } from '../../../../app/modules/apps/mouvement/columns/_columns'
import { DeleveryColumn, MovementColumn } from '../../../../app/modules/apps/mouvement/core/_models'
import { DeleveryCustomRow, MovementCustomRow } from '../../../../app/modules/apps/mouvement/columns/CustomRow'
import { DeleveryCustomHeaderColumn } from '../../../../app/modules/apps/mouvement/columns/CustomHeaderColumn'
import { MovementListViewProvider } from '../../../../app/modules/apps/mouvement/core/ListViewProvider'



import { MovementQueryResponseProvider, MovementUseQueryResponseData, MovementUseQueryResponseLoading, MovementUseQueryResponsePagination }
  from '../../../../app/modules/apps/mouvement/core/QueryResponseDeleveryProvider'
import { QueryRequestProvider, useQueryRequest } from '../../../../app/modules/apps/mouvement/core/MovementQueryRequestProvider'
import { useAuth } from '../../../../app/modules/auth'
import { SimpleParcelListHeader } from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListHeader'
import { ListPagination } from '../../../helpers/components/pagination/ListPagination'
import { getCurrentDateInput } from '../../../../app/pages/Parcel/ParcelList/ParcelTable'
import { ListLoading } from '../../../helpers/components/loading/ListLoading'
import { getExcelMovementsDelevery, getExcelMovementsDeleveryProvider } from '../../../../app/modules/apps/mouvement/core/_requests'
import { User } from '../../../../app/modules/apps/user-management/users-list/core/_models'
import { getUsers } from '../../../../app/modules/apps/user-management/users-list/core/_requests'



type Props = {
  className: string 
}

type ChildComponentProps = {
  sendSearch: (data: string) => void
  sendStartDate: (data: string) => void
  sendEndDate: (data: string) => void
  sendProvider: (data: number) => void
}

interface ProviderOption {
  value: number;
  label: string;
}


const DashboardTable: FC<ChildComponentProps> = ({sendSearch, sendStartDate, sendEndDate, sendProvider}) => {

 const [searchTerm, setSearchTerm] = useState<string>(' ')

 const {currentUser} = useAuth()
 const intl = useIntl()
 const isLoading = MovementUseQueryResponseLoading()

const transfers = MovementUseQueryResponseData()


  const columns = useMemo(() => MovementsColumnsDeleveryProvider, [])

 let data =useMemo(
  () =>
    transfers.map(
      (transfer ) =>
        ({
          id: transfer.id,
          received_at: transfer.received_at.split(' ')[0]?.replace('Z', '').replace('.000000', ''),
          agent_fullname: transfer.agent?.firstname,
          register : transfer.register,
          pick_up_at:  transfer.parcels[0].pick_up_at?.replace('T', ' ').replace('Z', '').replace('.000000', ''),
          num_provider :transfer.parcels[0].reference,
          prix: transfer.parcels[0].price,
          trucking_number: transfer.parcels[0].tracking_number,
          client_fullname: transfer.parcels[0].receiver?.firstname+" "+transfer.parcels[0].receiver?.lastname,
          phone_number: transfer.parcels[0].receiver?.mobile,
          reception_at: transfer.parcels[0]?.receptions[transfer.parcels[0]?.receptions.length - 1]?.received_at?.split(' ')[0]?.replace('Z', '').replace('.000000', ''),
          delevery_price: transfer.parcels[0]?.delivery_price,

        } as DeleveryColumn)
        ),
    [transfers]
  )


  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)

  }
    const currentDate = new Date() 

    const tableRef = useRef(null)
    const pdfRef = useRef(null) 
  function generatePDF() {

    const pdf = new jsPDF()
      const input = document.getElementById('div-to-export')
      const doc = document.getElementById("pdf-content")
      if(doc !== null ) doc.style.display = "block" 
    if (pdfRef.current !== null ) {
     
       html2canvas(pdfRef.current)
         .then((canvas) => {
           
           const imgData = canvas.toDataURL('image/png')
           const pdf = new jsPDF('p', 'pt', 'a4', false)
           pdf.addImage(imgData,'PNG', 0, 0, 600, 0,undefined)
           pdf.save("FeuilleDeCaisse"+startDate+" au "+endDate+".pdf") 
          })
        }
        if(doc !== null ) doc.style.display = "none" 
      }
  
     
 
  /** Export Excel  */

  
  const [totalAmount, setTotalAmount] = useState(0)
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const [startDate, setStartDate] = useState(getCurrentDateInput(new Date(currentYear, currentMonth, 1)))
  const [endDate, setEndDate] = useState(getCurrentDateInput(new Date()))
  const [providers, setProviders] = useState<User[]>([])
  const [searchProvider, setSearchProvider] = useState<ProviderOption | undefined>()
  
 

  const handleStartDateChange = (event: { target: { value: SetStateAction<string> } }) => {
    setStartDate(event.target.value)
  }

  const handleEndDateChange = (event: { target: { value: SetStateAction<string> } }) => {
    setEndDate(event.target.value)
  }

  useEffect(() => {
    const getAllProviders = async () => {
        const response = await getUsers(undefined, searchProvider?.label)
        response? setProviders(response.data as User[]):        
        setProviders([])    
    }
    getAllProviders()
  }, [searchProvider])

  
  const handleSendData = () => {
    sendSearch(searchTerm)
    sendStartDate(startDate)
    sendEndDate(endDate)
    sendProvider?.(searchProvider?.value!)
  }
  



  /**Fin Export Excel **/

  const { headers, rows, prepareRow } = useTable({
    columns ,
    data , 
  })

  return (
    <KTCardBody className='p-4'>
           
     
          <div className='d-flex align-items-center position-relative my-1'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              className='form-control form-control-solid w-250px ps-14'
              onChange={handleSearchChange}
              value={searchTerm}
              
            />
            
            <input
            type="date"
            id="startDate"
            className='form-control form-control-solid w-250px ps-14 m-4'
            value={startDate}
            onChange={handleStartDateChange}
          />
          <input
            type="date"
            id="endDate"
            className='form-control form-control-solid w-250px ps-14 m-4'
            value={endDate}
            onChange={handleEndDateChange}
            />
            <Select
          className="w-250px m-4"
          placeholder="Choisir un provider"
          onChange={(selectedOption) => {
          const selectedValue = selectedOption as ProviderOption | undefined;
         setSearchProvider(selectedValue);
         }}
          options={providers.map((provider) => ({ value: provider.id, label: provider.firstname }))}
         isClearable={true}
         backspaceRemovesValue={true}
    />
            <button type="button" className ="btn btn-primary" onClick={handleSendData}>Rechercher</button>
            <div>
            {/* {(currentUser?.type === 'admin' || currentUser?.type=== 'provider' || currentUser?.modelName === 'controle' ) && (
              <DownloadTableExcel
              filename="Colis_Livre" 
              sheet="Colis"
                currentTableRef={tableRef.current}
                >
    
                <button type='submit' className='btn btn-primary float-end' ><i className="fa-solid fa-file-export"></i> Export excel </button>
    
              </DownloadTableExcel>
    )}  */}
     {(currentUser?.type === 'agent'   ) && (
    <button onClick={generatePDF} className='btn btn-primary float-end' ><i className="fa-solid fa-file-pdf"></i>Export PDF</button>
     )}  
            </div>
            </div>
       
          {/* end::Search */}
          <div  ref={pdfRef} id="div-to-export" className='table-responsive p-2'>
            <div  id="pdf-content" style={{ display: "none" }}>
              <div className='row'>
                 <div className='col-4 p-4 justify-content-end'>
              <img src={toAbsoluteUrl('/media/logo.png')} alt='logoLogiteam' height={50} />
              </div>
              <div className='col'><p>
                 Date du {startDate} au {endDate} <br />
                 Date  d'impression {currentDate.toLocaleDateString()} <br />
                 Total Caisse : {totalAmount} <br />
                 ETB : SODIG <br />
                 </p>
                 </div>
            
            </div></div>
           
            <table ref={tableRef}
              className='table table-striped align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            >
              <thead >
                <tr className='text-start fw-bolder fs-7 text-uppercase gs-0'>
                  {headers.map((column: ColumnInstance<DeleveryColumn>) => (
                    <DeleveryCustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
    
             <tbody className=' fs-5 fw-bold' >
                {rows.length > 0 ? (
                  rows
                    .map((row: Row<DeleveryColumn>, i) => {
                      prepareRow(row)
                      return <DeleveryCustomRow row={row} key={`row-${i}-${row.id}`} />
                    })
                ) 
                
                
                : (
                  <tr>
                    <td colSpan={20}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {intl.formatMessage({id: 'TABLE.NO_ITEMS_FOUND'})}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            {/* <tfoot className='text-white  text-uppercase fw-bolder bg-primary'>
              <tr>
                <td colSpan={20} className='text-start fs-7 gs-0'>Total:</td>
                <td>{ totalAmount}</td>
              </tr>
            </tfoot> */}
          </table>

          <ListPagination
        useQueryResponseLoading={MovementUseQueryResponseLoading}
        useQueryResponsePagination={MovementUseQueryResponsePagination}
        useQueryRequest={useQueryRequest}
        item='parcels'
        />
           {isLoading && <ListLoading />}    
          </div>
    
        </KTCardBody>
  )
}

const TablesWidgetMovementEtatProvider: FC<Props> = ({ className }) => {
  const [Status, setStatus] = useState("")
  const [Search, setSearch] = useState("")
  const [Provider, setProvider] = useState<number>()
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const [startDate, setStartDate] = useState(getCurrentDateInput(new Date(currentYear, currentMonth, 1)))
  const [endDate, setEndDate] = useState(getCurrentDateInput(new Date()))
  const processReceivedData = (data: string) => {
    setStatus(data)
  }
  const processSearch = (data: string) => {
    setSearch(data)
  }
  const processStartDate = (data: string) => {
    setStartDate(data)
  }
  const processEndDate = (data: string) => {
    setEndDate(data)
  }
  const processProvider = (data: number) => {
    setProvider(data)
  }
  return (
    <div>
      <QueryRequestProvider>
        <MovementQueryResponseProvider Status={Status} Search={Search} StartDate={startDate} EndDate={endDate} Provider={Provider}>
          <MovementListViewProvider>
          <KTCard>
            <SimpleParcelListHeader sendEExcel={()=>getExcelMovementsDeleveryProvider(Status, Search, startDate, endDate, Provider)}  isExport={true} Status={Status} Search={Search} StartDate={startDate} EndDate={endDate}/>
            <DashboardTable sendSearch={processSearch} sendStartDate={processStartDate} sendEndDate={processEndDate} sendProvider={processProvider}/>
          </KTCard>
          </MovementListViewProvider>
        </MovementQueryResponseProvider>
      </QueryRequestProvider>

    </div>
  )
}

export { TablesWidgetMovementEtatProvider}
