import {Dispatch, FC, SetStateAction} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import ParcelPrint from '../../../../app/modules/wizards/components/ParcelPrintInfo'
import {Parcel} from '../../../../app/modules/wizards/core/_models'

type Props = {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  Parcel: Parcel
}

const PrintModal: FC<Props> = ({show, setShow, Parcel}) => {
  const handleClose = () => setShow(false)
  const intl = useIntl()


  return (
    <Modal dialogClassName='printModal' className='modal-xl' show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <ParcelPrint parcel={Parcel}/>
        <Button className='ms-2 ' variant='danger' onClick={handleClose}>
          {intl.formatMessage({id: 'GENERAL.CLOSE'})}
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export {PrintModal}
