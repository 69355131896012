import {Response} from '../../../../_metronic/helpers'
import { Reception } from '../../apps/mouvement/core/_models'

export interface ParcelPersonModel {
  id?: number
  firstname: string
  lastname: string
  governorate: string
  adresse: string
  postal_code: string
  city: string
  supplement?: string
  phone_number?: string
  mobile: string
  email?: string
  email_verified_at?: string
}

export interface RelayModel {
  id?: number
  name: string
  label: string
  type: string
  governorate: string
  address: string
  postal_code: string
  city: string
  supplement: string
  phone_number?: string
  mobile: string
  email?: string
  active: number
  created_at: string
  updated_a: string
}

export interface PivotModel {
  parcel_id?: number
  reception_id?:number
}

export interface RelayPointDestModel {
  id?: number
  name?: string
}

export interface RelayPointSrcModel {
  id?: number
  name?: string
}

export interface DeliveryPrice {
  dimension: string
  weight: number
  quantity: number
}

export interface deliveryPriceResponse {
  price: number
}
export interface ParcelProvider{
  id?: number
  quantity: number
  weight: number
  dimension: string
  reference: string
  price: number
  status?: 'pending' | 'processing' | 'completed' | 'canceled' | 'refunded'
  content: string
  reason?: string
  tracking_number?: any
  pick_up_at?: string
  user_id?: number
  updated_at?: string
  created_at?: string
  delivery_price: number
  sender?: ParcelPersonModel
  receiver?: ParcelPersonModel
  destination?: string
  departure?: string
  relay?: string
  parcels: Array<{
    id: number
    tracking_number: string
    quantity:number
    relay?: string
    dimension: number
    weight:number
    reference: string
    price: number
    status?: 'pending' | 'processing' | 'completed' | 'canceled' | 'refunded'
    content: string
    reason?: string
    pick_up_at?: string
    user_id?: number
    updated_at?: string
    created_at?: string
    delivery_price: number
    sender?: ParcelPersonModel
    receiver?: ParcelPersonModel
    destination?: string
    departure?: string
  }>
}

export type Parcel = {
  id?: number
  quantity: number
  weight: number
  dimension: string
  reference: string
  price: number
  status?: 'pending' | 'processing' | 'completed' | 'canceled' | 'refunded'
  content: string
  phone_number?: string
  reason?: string
  tracking_number?: any
  pick_up_at?: string
  user_id?: number
  updated_at?: string
  created_at?: string
  title?: string
  delivery_price: number
  sender?: ParcelPersonModel
  receiver?: ParcelPersonModel
  destination?: string
  client_name?: string
  departure?: string
  relay?: string
  pdf_path?: string
  movements?: Array<{
  created_at: string,
  type: string
  relay_point_dest: RelayPointDestModel
  relay_point_src: RelayPointSrcModel
  driver: {
    lastname?: string
    firstname?: string
  }
  }>
  receptions?: Array<{
    created_at: string
    relay?: RelayModel
    type: string
    }>
  first_reception?: string
  last_reception?: string
  first_movement?: string
  last_movement?: string,
  message?: string,
  data?: {
    error?: string
  }
}



export type movement = {
  id?: number
  quantity: number
  weight: number
  dimension: string
  reference: string
  price: number
  status?: 'pending' | 'processing' | 'completed' | 'canceled' | 'refunded'
  content: string
  reason?: string
  tracking_number?: any
  pick_up_at?: string
  user_id?: number
  updated_at?: string
  created_at?: string
  delivery_price: number
  sender?: ParcelPersonModel
  receiver?: ParcelPersonModel
  destination?: string
  departure?: string
}

export const parcelInits: Parcel = {
  id: undefined,
  quantity: 1,
  weight: 1,
  dimension: '1',
  reference: '',
  price: 0,
  status: 'pending',
  content: '',
  reason: '',
  tracking_number: 'anything',
  pick_up_at: '',
  user_id: undefined,
  delivery_price: 0,
 
}


export type product = {
  id?: number
  ref: string
  des: string
  category: string
  created_at?: string
  updated_at?: string
  price: string
  cat_logiteam: string
}
export type ParcelsAllQueryResponse = Array<Parcel>

export type ParcelsQueryResponse = Response<Array<Parcel>>
