import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  initialListView,
  NoUpdateListViewContextProps,
  groupingOnSelectAll,
  WithChildren,
  noUpdateInits,
} from '../../../../../_metronic/helpers'
import {
  MovementUseQueryResponse,
  MovementUseQueryResponseData,
  ReceptionUseQueryResponse,
  ReceptionUseQueryResponseData,
} from './QueryResponseProvider'

const ListViewContext = createContext<NoUpdateListViewContextProps>(noUpdateInits)

const MovementListViewProvider: FC<WithChildren> = ({children}) => {
  const [detailsItemId, setDetailsItemId] = useState<ID | null>(null)
  const [detailsItemType, setDetailsItemType] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showA, setShowA] = useState<boolean>(false)
  const [messageA, setMessageA] = useState<string>('')
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const {isLoading} = MovementUseQueryResponse()
  const data = MovementUseQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  return (
    <ListViewContext.Provider
      value={{
        detailsItemId,
        setDetailsItemId,
        detailsItemType,
        setDetailsItemType,
        searchTerm,
        setSearchTerm,
        selected,
        disabled,
        isAllSelected,
        showA,
        setShowA,
        messageA,
        setMessageA,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const MovementUseListView = () => useContext(ListViewContext)

const ReceptionListViewProvider: FC<WithChildren> = ({children}) => {
  const [detailsItemId, setDetailsItemId] = useState<ID | null>(null)
  const [detailsItemType, setDetailsItemType] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showA, setShowA] = useState<boolean>(false)
  const [messageA, setMessageA] = useState<string>('')
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const {isLoading} = ReceptionUseQueryResponse()
  const data = ReceptionUseQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  return (
    <ListViewContext.Provider
      value={{
        detailsItemId,
        setDetailsItemId,
        detailsItemType,
        setDetailsItemType,
        searchTerm,
        setSearchTerm,
        selected,
        disabled,
        isAllSelected,
        showA,
        setShowA,
        messageA,
        setMessageA,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const ReceptionUseListView = () => useContext(ListViewContext)

export {
  MovementListViewProvider,
  MovementUseListView,
  ReceptionListViewProvider,
  ReceptionUseListView,
}
