/* eslint-disable jsx-a11y/anchor-is-valid */
import {AxiosError} from 'axios'
import {useFormik} from 'formik'
import {FC, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {isNotEmpty, KTSVG, QUERIES} from '../../../_metronic/helpers'
import {AlertModal} from '../../../_metronic/layout/components/others/AlertModal'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ListViewProvider, useListView} from '../Truck/core/ListViewProvider'
import {QueryRequestProvider} from '../Truck/core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse} from '../Truck/core/QueryResponseProvider'
import {addTruckSchema, initialTruck, Truck} from '../Truck/core/_models'
import {createTruck, getTruckById, updateTruck} from '../Truck/core/_requests'
import {getProcessingParcels, updateProcessingParcel} from '../../modules/wizards/core/_requests'
import { Parcel } from '../../modules/wizards/core/_models'
import { object, string } from 'yup'
import { useNavigate } from 'react-router-dom'



const ParcelReturnAddPage=() => {

    const [parcel, setParcel] = useState<Parcel | undefined>();
    const intl = useIntl()
    const parcels = ParcelProcessingUseQueryResponseData()
    const data = useMemo(() => parcels, [parcels])
    
    const navigate = useNavigate();
    const goTolist = () => {
      navigate('/Parcel/Return/List')
  }

  
    const handleSubmit = async () => { 
         if (parcel) {
             await updateProcessingParcel(parcel)
             .then(() => {
              return goTolist()
            })
         } 
        }

        const formik = useFormik({
            initialValues: {
              parcel_reason: parcel?.reason || '',
            },
            validationSchema: object().shape({
              parcel_reason: string().required('La raison est obligatoire'),
            }),
            onSubmit: handleSubmit,
          });
          
        console.log(parcel)
  return (
    <>
   <form id='kt_add_truck_form' className='form' onSubmit={formik.handleSubmit}>
        <div className=' mx-5 mx-xl-15 my-7'>
          <div >
            <div className="mb-10">
              <label className='required fs-6 fw-bold mb-2'>
                {intl.formatMessage({id: 'COLUMN.TRACKING_NUMBER'})}
              </label>       
            
              <Select
         className='form-select form-select-solid'
          aria-label='Default select example'
          styles={{
            control: (provided: any) => ({
              ...provided,
              border: 0,
              boxShadow: 'none',
              backgroundColor: 'transparent',
            }),
          }}
          onChange={(e) => {
            if(e?.value!==undefined){
                setParcel({...e?.value, reason: parcel?.reason})}}}
          
         options={[
           { value: data[0], label: intl.formatMessage({ id: 'MOVEMENT.SELECT_ADDRESS' }) },
           ...(data ?? []).map((item) => ({ value: item, label: item.tracking_number + " - " + item.receiver?.firstname })),
           ]}
         />
             </div>
            </div>
            <div>
          <label className='required fs-6 fw-bold mb-2'>
            {intl.formatMessage({ id: 'PARCEL.REASON' })}
          </label>
          <textarea
        className='form-control form-control-solid'
        name='parcel_reason'
         value={formik.values.parcel_reason}
        onChange={(e) => {
      const value = e?.target.value;
         formik.handleChange(e);
       if(parcel!==undefined){
        setParcel({ ...parcel, reason: value })};
  }}
  onBlur={formik.handleBlur}
/>

          {formik.touched.parcel_reason && formik.errors.parcel_reason && (
            <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.parcel_reason}</div>
          </div>
          )}
        </div>
          </div>
       
        <div className='text-end'>
          <button type='submit' className='btn btn-primary mt-3 m-14'>
            
              <span className='indicator-label'>
                {intl.formatMessage({id: 'GENERAL.SUBMIT'})}
              </span>
           
            <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0' />
          </button>
        </div>
      </form>
    </>
  )
}

const ParcelProcessingUseQueryResponseData = () => {
    const [receptionsList, setReceptinos] = useState<Parcel[]>([])
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await getProcessingParcels();
          setReceptinos(response || []);
        } catch (error) {
        }
      };
      fetchData();
    }, []);
    const data = [] as Array<Parcel> 
    return (receptionsList || [])
  }

const ParcelReturnFormHeader: FC = () => {
  const intl = useIntl()

  const TruckBreadcrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'MENU.PARCEL_MANAGEMENT'}),
      path: '/truck/list',
      isActive: true,
      isSeparator: false,
    },
    {
      title: '',
      path: '',
      isActive: true,
      isSeparator: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={TruckBreadcrumbs}>{intl.formatMessage({id: 'BUTTON.ADD_PARCEL'})}</PageTitle>

      <h2 className='p-4'>{intl.formatMessage({id: 'BUTTON.ADD_PARCEL'})}</h2>
      <div className='separator separator-dashed '></div>
    </>
  )
}

const ParcelReturnAddComplete: FC = () => {
  const {messageA, setMessageA, showA, setShowA} = useListView()

  return (
    <>
      <AlertModal message={messageA} setMessage={setMessageA} show={showA} setShow={setShowA} />
      <div className='modal-dialog modal-dialog-centered modal-xl'>
        <div className='modal-content'>
          <ParcelReturnFormHeader />
          <div >
            <ParcelReturnAddPage  />
          </div>
        </div>
      </div>
    </>
  )
}

const ParcelReturnAddWrapper: FC = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <ParcelReturnAddComplete />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export {ParcelReturnAddWrapper, ParcelReturnAddPage}
