/* eslint-disable jsx-a11y/anchor-is-valid */
import { DownloadTableExcel } from 'react-export-table-to-excel'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


import React, { FC, useState, useRef, useEffect, SetStateAction } from 'react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { ColumnInstance, Row, useTable, useSortBy } from 'react-table'

import { KTCard, KTCardBody, KTSVG, toAbsoluteUrl } from '../../../helpers'
import { MovementsColumns, MovementsColumnsDelevery, MovementsColumnsDeleveryProvider } from '../../../../app/modules/apps/mouvement/columns/_columns';
import { DeleveryColumn, MovementColumn } from '../../../../app/modules/apps/mouvement/core/_models';
import { DeleveryCustomHeaderColumn } from '../../../../app/modules/apps/mouvement/columns/CustomHeaderColumn';
import { MovementListViewProvider } from '../../../../app/modules/apps/mouvement/core/ListViewProvider';
import {DeleveryCustomRow} from '../../../../app/modules/apps/user-management/users-list/table/columns/CustomRow'
import {SimpleParcelListHeader} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListHeader'


import { 
  ParcelUseQueryResponsePagination,
  ParcelUseQueryResponseLoading,
  MovementQueryResponseProvider,
  MovementUseQueryResponse,
  MovementUseQueryResponseData
}
  from '../../../../app/modules/apps/mouvement/core/QueryResponseDelevery';
import { QueryRequestProvider, useQueryRequest } from '../../../../app/modules/apps/mouvement/core/MovementQueryRequestProvider';
import Select from 'react-select'
import { ListLoading } from '../../../helpers/components/loading/ListLoading';
import { ListPagination } from '../../../helpers/components/pagination/ListPagination';
import { getCurrentDateInput } from '../../../../app/pages/Parcel/ParcelList/ParcelTable';
import { getExcelMovementsDelevery } from '../../../../app/modules/apps/mouvement/core/_requests';
import { ChildComponentProps } from '../../../../app/pages/Parcel/ParcelList/ParcelTableProvider';



type Props = {
  className: string , 
}

interface ArrayObjectSelectState {
  selectedStatut: Statut | null;
}
interface Statut {
  name:string
  }
  const status: Statut[] = [{name:'pending'},{name:'processing'},{name:'completed'},{name:'canceled'},{name:'refunded'},];

const DashboardTable: FC<ChildComponentProps> = ({sendStatus, sendSearch, sendStartDate, sendEndDate}) => {
  const getCurrentDateInput = (dateObj: Date = new Date()) => {
    // get the month in this format of 04, the same for months
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();
  
    const shortDate = `${year}-${month}-${day}`;
  
    return shortDate;
  };
  
  const [searchTerm, setSearchTerm] = useState<string>('')
  const transfers = MovementUseQueryResponseData();
  let data =useMemo(
    () =>
      transfers.map(
        (transfer ) =>
          ({
            id: transfer.id,
            received_at: transfer.received_at.split(' ')[0]?.replace('Z', '').replace('.000000', ''),
            register : transfer.register,
            agent_fullname: transfer.agent?.firstname,
            pick_up_at:  transfer.parcels[0]?.pick_up_at,
            trucking_number: transfer.parcels[0]?.tracking_number,
            phone_number: transfer.parcels[0]?.receiver?.phone_number===null? transfer.parcels[0]?.receiver?.mobile :
            transfer.parcels[0]?.receiver?.mobile  +   ' , ' + transfer.parcels[0]?.receiver?.phone_number,
            client_fullname: transfer.parcels[0]?.receiver?.firstname+" "+transfer.parcels[0]?.receiver?.lastname,
            reception_at: transfer.parcels[0]?.receptions[transfer.parcels[0]?.receptions.length - 1]?.received_at?.split(' ')[0]?.replace('Z', '').replace('.000000', '')
  
          } as DeleveryColumn)
          ),
      [transfers]
    )
  const columns = useMemo(() => MovementsColumnsDelevery, [])
  const isLoading = ParcelUseQueryResponseLoading()
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
   const currentMonth = currentDate.getMonth();
  const [startDate, setStartDate] = useState(getCurrentDateInput(new Date(currentYear, currentMonth, 1)));
  const [endDate, setEndDate] = useState(getCurrentDateInput(new Date()));
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const handleStartDateChange = (event: { target: { value: SetStateAction<string> } }) => {
    setStartDate(event.target.value);
  };
  const handleEndDateChange = (event: { target: { value: SetStateAction<string> } }) => {
    setEndDate(event.target.value);
  };


  const [state, setState] = useState<ArrayObjectSelectState>({
    selectedStatut: null,
  });
  const intl = useIntl()

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }
  const handleSendData = () => {
    sendStatus?.(state.selectedStatut?.name!);
    sendSearch?.(searchTerm)
    sendStartDate?.(startDate)
    sendEndDate?.(endDate)
  };
  

  return (
    <KTCardBody className='py-4'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          className='form-control form-control-solid w-250px ps-14'
          onChange={handleSearchChange}
          value={searchTerm}
        />
        
        <input
        type="date"
        id="startDate"
        className='form-control form-control-solid w-250px ps-14 m-4'
        value={startDate}
        onChange={handleStartDateChange}
      />
      <input
        type="date"
        id="endDate"
        className='form-control form-control-solid w-250px ps-14 m-4'
        value={endDate}
        onChange={handleEndDateChange}
        />
         {/* <Select
        className=' w-250px m-4'
        placeholder="Choisir un statut..."
        value={state.selectedStatut}
        onChange={(option: Statut | null) => {
          setState({ selectedStatut: option });
        }}
        options={status}
        getOptionLabel={(statut: Statut) => statut.name}
        getOptionValue={(statut: Statut) => statut.name}
        isClearable={true}
        backspaceRemovesValue={true}
      /> */}
        <button type="button" className ="btn btn-primary" onClick={handleSendData}>Rechercher</button>
      </div>
      {/* end::Search */}
      <div className='table-responsive'>
        <table
          id='kt_table_parcels'
          className='table table-striped align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start fw-bolder fs-7 text-uppercase gs-0'>
            {headers.map((column: ColumnInstance<DeleveryColumn>) => (
                <DeleveryCustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className=' fs-5 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows
                .map((row: Row<DeleveryColumn>, i) => {
                  prepareRow(row)
                  return <DeleveryCustomRow row={row} key={`row-${i}-${row.id}`} />
                })
            ) : (
              <tr>
                <td colSpan={20}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({id: 'TABLE.NO_ITEMS_FOUND'})}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ListPagination
        useQueryResponseLoading={ParcelUseQueryResponseLoading}
        useQueryResponsePagination={ParcelUseQueryResponsePagination}
        useQueryRequest={useQueryRequest}
        item='parcels'
      />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

const TablesWidgetMovementEtat: FC<Props> = () => {
  const [Status, setStatus] = useState("")
  const [Search, setSearch] = useState("")
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()
  const [startDate, setStartDate] = useState(getCurrentDateInput(new Date(currentYear, currentMonth, 1)))
  const [endDate, setEndDate] = useState(getCurrentDateInput(new Date()))
  const processReceivedData = (data: string) => {
    setStatus(data)
  }
  const processSearch = (data: string) => {
    setSearch(data)
  }
  const processStartDate = (data: string) => {
    setStartDate(data)
  }
  const processEndDate = (data: string) => {
    setEndDate(data)
  }
  return (
    <div>
      <QueryRequestProvider>
        <MovementQueryResponseProvider Status={Status} Search={Search} StartDate={startDate} EndDate={endDate}>
          <MovementListViewProvider>
          <KTCard>
            <SimpleParcelListHeader sendEExcel={()=>getExcelMovementsDelevery(Status, Search, startDate, endDate)} isExport={true}/>
            <DashboardTable sendStatus={processReceivedData} sendSearch={processSearch} sendStartDate={processStartDate} sendEndDate={processEndDate} />
          </KTCard>
          </MovementListViewProvider>
        </MovementQueryResponseProvider>
      </QueryRequestProvider>

    </div>
  )
}

export { TablesWidgetMovementEtat }
