/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo, useRef} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../_metronic/helpers'
import {getPaginationParcels} from './_requests'
import {Parcel} from './_models'
import {useQueryRequest} from './QueryRequestProvider'

const QueryResponseContext = createResponseContext<Parcel>(initialQueryResponse)

const ParcelQueryResponseProvider: FC<WithChildren> = ({children, Status, Search, StartDate, EndDate, Return}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

 
  
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)     
    }
    else{
      setQuery(' ')
    }
  }, [updatedQuery, Status, Search, StartDate, EndDate])

  
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.PARCELS_LIST}-${query}`,
    () => {   
  
      return getPaginationParcels(query, Status, Search, StartDate, EndDate )
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}



const ParcelUseQueryResponse = () => useContext(QueryResponseContext)

const ParcelQueryResponseData = () => {




  const {response} = ParcelUseQueryResponse()
  const parcels = [] as Array<Parcel>
  response &&
    response.data &&
    response.data.forEach((parcel: Parcel) => {
      if (Array.isArray(parcel.movements) && Array.isArray(parcel.receptions))
      {
    parcels.push({
      ...parcel,

      
      first_movement: parcel.movements.length > 0 && parcel.movements[parcel.movements.length - 1].type === 'd' ?
      ' livré ' + parcel.movements[parcel.movements.length - 1].created_at.substring(0,parcel.movements[parcel.movements.length - 1].created_at.lastIndexOf("T")) + ' à ' +  parcel.movements[parcel.movements.length - 1].relay_point_dest.name:
      parcel.movements.length > 0 && parcel.movements[parcel.movements.length - 1].type === 'it'?
      parcel.movements[parcel.movements.length - 1].created_at.substring(0,parcel.movements[parcel.movements.length - 1].created_at.lastIndexOf("T")) + ' de ' +  parcel.movements[parcel.movements.length - 1].relay_point_src.name + ' à ' + parcel.movements[parcel.movements.length - 1].relay_point_dest.name
      + ' avec chauffeur ' +   parcel.movements[parcel.movements.length - 1].driver?.firstname +' ' +  parcel.movements[parcel.movements.length - 1].driver?.lastname 
       : '',
      last_movement: parcel.movements.length > 0 && parcel.movements[0].type === 'd' ?
       ' livré ' + parcel.movements[0].created_at.substring(0,parcel.movements[0].created_at.lastIndexOf("T")) + ' à ' +  parcel.movements[0].relay_point_src.name:
       parcel.movements.length > 0 && parcel.movements[0].type === 'it'?
       parcel.movements[0].created_at.substring(0,parcel.movements[0].created_at.lastIndexOf("T")) + ' de ' +  parcel.movements[0].relay_point_src.name + ' à ' + parcel.movements[0].relay_point_dest.name
       + ' avec chauffeur ' +   parcel.movements[0].driver?.firstname +' ' +  parcel.movements[0].driver?.lastname 
       : '',
      first_reception: parcel.receptions.length > 0? parcel.receptions[parcel.receptions.length - 1].created_at.substring(0,parcel.receptions[parcel.receptions.length - 1].created_at.lastIndexOf("T"))  + " à " + parcel.receptions[parcel.receptions.length - 1].relay?.name : '',
      last_reception: parcel.receptions.length > 0? parcel.receptions[0].created_at.substring(0,parcel.receptions[0].created_at.lastIndexOf("T"))  + " à " + parcel.receptions[0].relay?.name : '',
      destination: parcel?.relay===null? parcel.receiver?.firstname+" "+parcel.receiver?.lastname+" , "+parcel.receiver?.adresse : parcel?.relay+"-  "+parcel.receiver?.firstname+" "+parcel.receiver?.lastname+" , "+parcel.receiver?.adresse,   
      departure: parcel.sender?.firstname +" "+parcel.sender?.lastname+" , "+parcel.sender?.adresse,
      phone_number:  parcel.sender?.phone_number + ', ' + parcel.sender?.mobile
    })
  }
  else{
    parcels.push({
      ...parcel,

      destination:  parcel?.relay===null? parcel.receiver?.firstname+" "+parcel.receiver?.lastname+" , "+parcel.receiver?.adresse : parcel?.relay+"-  "+parcel.receiver?.firstname+" "+parcel.receiver?.lastname+" , "+parcel.receiver?.adresse,
      departure: parcel.sender?.firstname +" "+parcel.sender?.lastname+" , "+parcel.sender?.adresse,
      phone_number:  parcel.sender?.phone_number + ', ' + parcel.sender?.mobile
    })
  }
  { 
    parcels.map( parcel => {
        console.log(parcel);
    })
}
    })
  return (parcels as Array<Parcel>) || []
}


const ParcelUseQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }
  const {response} = ParcelUseQueryResponse()
  if (!response) {
    return defaultPaginationState
  }
  const {current_page, links, per_page} = response
  return {current_page, links, per_page} as PaginationState
}

const ParcelUseQueryResponseLoading = (): boolean => {
  const {isLoading} = ParcelUseQueryResponse()
  return isLoading
}

export {
  ParcelQueryResponseProvider,
  ParcelUseQueryResponse,
  ParcelQueryResponseData,
  ParcelUseQueryResponsePagination,
  ParcelUseQueryResponseLoading,
}
