import {Column} from 'react-table'
import {useIntl} from 'react-intl'
import {DeleveryColumn, Movement, MovementColumn, ReceptionColumn} from '../core/_models'
import {MovementSelectionHeader, ReceptionSelectionHeader} from './MovementSelectionHeader'
import {MovementSelectionCell, ReceptionSelectionCell} from './MovementSelectionCell'
import {DeleveryCustomHeader, MovementCustomHeader, ReceptionCustomHeader} from './MovementCustomHeader'
import {MovementActionsCell, ReceptionActionsCell} from './MovementActionsCell'
import {useAuth} from '../../../../modules/auth'

const Users=['provider', 'admin']

const MovementsColumnsDelevery: ReadonlyArray<Column<DeleveryColumn>> = [
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.BL'})}
        className='min-w-125px'
      />
    ),
    accessor: 'register',
    id: 'register',
  },
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.TRACKING_NUMBERS'})}
        className='min-w-125px'
      />
    ),
    accessor: 'trucking_number',
    id: 'trucking_number',
  },
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.PICK_UP_AT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'pick_up_at',
    id: 'pick_up_at',
  },
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.RECEPTION_MAG'})}
        className='min-w-125px'
      />
    ),
    accessor: 'reception_at',
    id: 'reception_at',
  },
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.CLIENT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'client_fullname',
    id: 'client_fullname',
  },
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={'mobile client'}
        className='min-w-125px'
      />
    ),
    accessor: 'phone_number',
    id: 'phone_number',
  },
  
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.RELAY_POINT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'agent_fullname',
    id: 'agent_fullname',
  },
]


const MovementsColumnsDeleveryProvider: ReadonlyArray<Column<DeleveryColumn>> = [
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.BL'})}
        className='min-w-125px'
      />
    ),
    accessor: 'register',
    id: 'register',
  },
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.REFERENCE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'num_provider',
    id: 'num_provider',
  }
  ,
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.TRACKING_NUMBERS'})}
        className='min-w-125px'
      />
    ),
    accessor: 'trucking_number',
    id: 'trucking_number',
  },
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.PICK_UP_AT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'pick_up_at',
    id: 'pick_up_at',
  },
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.RECEPTION_MAG'})}
        className='min-w-125px'
      />
    ),
    accessor: 'reception_at',
    id: 'reception_at',
  },
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.CLIENT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'client_fullname',
    id: 'client_fullname',
  },
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={'mobile client'}
        className='min-w-125px'
      />
    ),
    accessor: 'phone_number',
    id: 'phone_number',
  },
  
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.RELAY_POINT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'agent_fullname',
    id: 'agent_fullname',
  },
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={'prix livraison'}
        className='min-w-125px'
      />
    ),
    accessor: 'delevery_price',
    id: 'delevery_price',
  },
  {
    Header: (props) => (
      <DeleveryCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.PRICE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'prix',
    id: 'prix',
  },

]

const MovementsColumns: ReadonlyArray<Column<MovementColumn>> = [
  {
    Header: (props) =>Users.includes(String(useAuth().currentUser?.type))?<MovementSelectionHeader tableProps={props} />: <div></div>,
    id: 'selection',
    Cell: ({...props}) =>Users.includes(String(useAuth().currentUser?.type))?<MovementSelectionCell id={props.data[props.row.index].id} />: <div></div> ,
  },
  {
    Header: (props) => (
      <MovementCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.PARCELS_NUMBER'})}
        className='min-w-125px'
      />
    ),
    accessor: 'parcels_count',
    id: 'parcels_count',
  },
  {
    Header: (props) => (
      <MovementCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.DRIVER'})}
        className='min-w-125px'
      />
    ),
    accessor: 'driver_fullname',
    id: 'driver_fullname',
  },
  {
    Header: (props) => (
      <MovementCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.TRUCK_NUMBER'})}
        className='min-w-125px'
      />
    ),
    accessor: 'truck_number',
    id: 'truck_number',
  },
  {
    Header: (props) => (
      <MovementCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.RELAY_POINT_SOURCE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'relay_point_src',
    id: 'relay_point_src',
  },
  {
    Header: (props) => (
      <MovementCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.RELAY_POINT_DESTINATION'})}
        className='min-w-125px'
      />
    ),
    accessor: 'relay_point_dest',
    id: 'relay_point_dest',
  },
  {
    Header: (props) => (
      <MovementCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.RECIEVED_AT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'received_at',
    id: 'received_at',
  },
  {
    Header: (props) => (
      <MovementCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.TYPE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'type',
    id: 'type',
  },
  {
    Header: (props) => (
      <MovementCustomHeader
        tableProps={props}
        title={'actes'}
        className='min-w-125px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <MovementActionsCell id={props.data[props.row.index].id} type={props.data[props.row.index].type} />,
  },
]




const ReceptionsColumns: ReadonlyArray<Column<ReceptionColumn>> = [
  {
    Header: (props) => Users.includes(String(useAuth().currentUser?.type))? <ReceptionSelectionHeader tableProps={props} />: <div></div>,
    id: 'selection',
    Cell: ({...props}) => Users.includes(String(useAuth().currentUser?.type))?<ReceptionSelectionCell id={props.data[props.row.index].id} />: <div></div>,
  },
  {
    Header: (props) => (
      <ReceptionCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.PARCELS_NUMBER'})}
        className='min-w-125px'
      />
    ),
    accessor: 'parcels_count',
    id: 'parcels_count',
  },
  {
    Header: (props) => (
      <ReceptionCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.RECIEVED_AT'})}
        className='min-w-125px'
      />
    ),
    accessor: 'received_at',
    id: 'received_at',
  },
  {
    Header: (props) => (
      <ReceptionCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.RELAY_POINT_SOURCE'})}
        className='min-w-125px'
      />
    ),
    accessor: 'rp_name',
    id: 'rp_name',
  },
  {
    Header: (props) => (
      <ReceptionCustomHeader
        tableProps={props}
        title={useIntl().formatMessage({id: 'COLUMN.USER'})}
        className='min-w-125px'
      />
    ),
    accessor: 'user',
    id: 'user',
  },
  {
    Header: (props) => Users.includes(String(useAuth().currentUser?.type))?
    (
      <ReceptionCustomHeader
         tableProps={props}
         title={'actes'}
         className='text-end min-w-100px'
       />
     )
     :<div></div> ,
    id: 'actions',
    Cell: ({...props}) =>Users.includes(String(useAuth().currentUser?.type))?<ReceptionActionsCell id={props.data[props.row.index].id} type={props.data[props.row.index].type} />: <div></div>,
  },
]

export {MovementsColumnsDelevery, MovementsColumnsDeleveryProvider,MovementsColumns, ReceptionsColumns}
