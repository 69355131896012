export const colisData = [
    {
        "ID": "11111111",
        "barCode": "",
        "category": "",
        "type": "",
        "priority": "",
        "status": "",
        "creationDate": "",
        "isChecked": false
    },
    {
        "ID": "22222222",
        "barCode": "",
        "category": "",
        "type": "",
        "priority": "",
        "status": "",
        "creationDate": "",
        "isChecked": false
    },
    {
        "ID": "33333333",
        "barCode": "",
        "category": "",
        "type": "",
        "priority": "",
        "status": "",
        "creationDate": "",
        "isChecked": false
    },
    {
        "ID": "44444444",
        "barCode": "",
        "category": "",
        "type": "",
        "priority": "",
        "status": "",
        "creationDate": "",
        "isChecked": false
    }
]
export const columns = [
    "ID",
    "Bar Code",
    "Category",
    "Type",
    "Priority",
    "Status",
    "Creation Date",
    "Actions"
]

export const claimingColumns = [
    "ID",
    "Name",
    "Taille",
    "Price",
    "Actions"
]

export const claimingItems = [
    {
        "ID": "111",
        "Name": "Badr",
        "Taille": "123",
        "Price": "333"
    },
    {
        "ID": "121",
        "Name": "Salma",
        "Taille": "100",
        "Price": "101"
    }
]

export const truckItems = [
    {
        "ID": "111",
        "serialNumber": "111",
        "code": "111",
        "type": "loco",
        "driver": "hamma",
    },
    {
        "ID": "222",
        "serialNumber": "222",
        "code": "222",
        "type": "bika",
        "driver": "hosni",
    },
    {
        "ID": "333",
        "serialNumber": "333",
        "code": "333",
        "type": "kali",
        "driver": "hamdi",
    }

]

export const truckColumns = [
    "ID",
    "serialNumber",
    "code",
    "type",
    "driver"
]

export const TrackingItems = [
    {
        "time": "12:00",
        "location": "Tunis",
    },
    {
        "time": "13:00",
        "location": "Sousse",
    },
    {
        "time": "14:00",
        "location": "Monastir",
    },
    {
        "time": "15:00",
        "location": "Sfax",
    },
    {
        "time": "16:00",
        "location": "Gabes",
    },
    {
        "time": "17:00",
        "location": "Tataouine",
    },
    {
        "time": "18:00",
        "location": "Tozeur",
    },
    {
        "time": "19:00",
        "location": "Kebili",
    },
    {
        "time": "20:00",
        "location": "Bizerte",
    }
]

export const ParcelColumns = [
    "quantity",
    "weight",
    "dimension",
    "reference",
    "price",
    "content",
    "tracking_number",
    "pick_up_at",
    "reason",
    "status",
    "Actions"
]

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const dimensionRegExp = /^\d*[*]\d*[*]\d*$/;
export const numberRegExp = /^([0-9]*)$/;

export const governorates = [
    {name:"Ariana", id:1},
    {name:"Béja", id:2},
    {name:"Ben Arous", id:3},
    {name:"Bizerte", id:4},
    {name:"Gabès", id:5},
    {name:"Gafsa", id:6},
    {name:"Jendouba", id:7},
    {name:"Kairouan", id:8},
    {name:"Kasserine", id:9},
    {name:"Kébili", id:10},
    {name:"Le Kef", id:11},
    {name:"Mahdia", id:12},
    {name:"La Manouba", id:13},
    {name:"Médenine", id:14},
    {name:"Monastir", id:15},
    {name:"Nabeul", id:16},
    {name:"Sfax", id:17},
    {name:"Sidi Bouzid", id:18},
    {name:"Siliana", id:19},
    {name:"Sousse", id:20},
    {name:"Tataouine", id:21},
    {name:"Tozeur", id:22},
    {name:"Tunis", id:23},
    {name:"Zaghouan", id:24},
    {name:"M'saken", id:24}
]
