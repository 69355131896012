/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {getAddressBookPaginated} from './_requests'
import { AddressBook, AddressBookColumn } from './_models'
import {AddressBookUseQueryRequest} from './QueryRequestProvider'

const AddressBookQueryResponseContext = createResponseContext<AddressBook>(initialQueryResponse)

const AddressBookQueryResponseProvider: FC<WithChildren> = ({children, Search}) => {
  const {state} = AddressBookUseQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)     
    }
    else{
      setQuery(' ')
    }
  }, [updatedQuery, Search])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.ADDRESS_BOOK_LIST}-${query}`,
    () => {
      return getAddressBookPaginated(query, Search)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <AddressBookQueryResponseContext.Provider
      value={{isLoading: isFetching, refetch, response, query}}
    >
      {children}
    </AddressBookQueryResponseContext.Provider>
  )
}

const AddressBookUseQueryResponse = () => useContext(AddressBookQueryResponseContext)

const AddressBookUseQueryResponseData = () => {
  const {response} = AddressBookUseQueryResponse()
  if (!response) {
    return []
  }
  const data = [] as Array<AddressBookColumn>
  response.data?.forEach((item: AddressBook) => {
    data.push({
      id: item.id,
      name: item.name,
      label: item.label,
      governorate: item.governorate,
      address: item.address,
      postal_code: item.postal_code,
      city: item.city,
      supplement: item.supplement,
      phone_number: item.phone_number,
      mobile: item.mobile,
      email: item.email,
      provider: item.provider?.firstname+' '+item.provider?.lastname
    } as AddressBookColumn)
  })
  return data || []
}

const AddressBookUseQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }
  const {response} = AddressBookUseQueryResponse()
  if (!response) {
    return defaultPaginationState
  }
  const {current_page, links, per_page} = response
  return {current_page, links, per_page} as PaginationState
}

const AddressBookUseQueryResponseLoading = () => {
  const {isLoading} = AddressBookUseQueryResponse()
  return isLoading
}

export {
  AddressBookQueryResponseProvider,
  AddressBookUseQueryResponse,
  AddressBookUseQueryResponseData,
  AddressBookUseQueryResponsePagination,
  AddressBookUseQueryResponseLoading,
}
